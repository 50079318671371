import { createFileRoute } from "@tanstack/react-router";
import { useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "src/app/hooks";
import { _createUserWithEmailAndPassword, signInWithGoogle } from "src/features/auth/authSlice";
import { Link } from "src/utils/router-link";

export const Route = createFileRoute("/auth/registrar")({
  component: () => <Page />,
  validateSearch: (search: Record<string, unknown>) => {
    const returnTo = search.returnTo as string | undefined;
    return { returnTo };
  },
});

interface Values {
  name: string;
  email: string;
  password: string;
  policy: boolean;
  submit: null;
}

const initialValues: Values = {
  name: "",
  email: "",
  password: "",
  policy: true,
  submit: null,
};

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Nome muito curto")
    .max(255, "Nome muito longo")
    .required("Nome é obrigatório"),
  email: Yup.string().email("Deve ser um email válido").max(255).required("Email é obrigatório"),
  password: Yup.string().min(7).max(255).required("Senha é obrigatória"),
  policy: Yup.boolean().oneOf([true], "Este campo deve ser marcado"),
});

const Page = () => {
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { returnTo } = Route.useSearch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(
          _createUserWithEmailAndPassword({
            email: values.email,
            name: values.name,
            password: values.password,
          }),
        );

        // returnTo || paths.dashboard.index
        router.navigate({
          from: "/auth/registrar",
          to: returnTo ?? "/auth/login",
          params: {
            username: values.email,
          },
          search: {
            returnTo: returnTo ?? "/",
          },
        });
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: (err as Error).message });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  const handleGoogleClick = useCallback(async (): Promise<void> => {
    try {
      await dispatch(signInWithGoogle());

      // returnTo || paths.dashboard.index
      router.navigate({
        from: "/auth/registrar",
        to: returnTo ?? "/auth/login",
        search: {
          returnTo: returnTo ?? "/",
        },
      });
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, router, returnTo]);

  usePageView();

  return (
    <>
      <Seo title="Registrar" />
      <div>
        <Card elevation={16}>
          <CardHeader
            subheader={
              <Typography color="text.secondary" variant="body2">
                Já tem uma conta? &nbsp;
                <Link
                  component={RouterLink}
                  to={"/auth/login"}
                  underline="hover"
                  variant="subtitle2"
                >
                  Entrar
                </Link>
              </Typography>
            }
            sx={{ pb: 0 }}
            title="Registrar"
          />
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <Button
                  fullWidth
                  onClick={handleGoogleClick}
                  size="large"
                  sx={{
                    backgroundColor: "common.white",
                    color: "common.black",
                    "&:hover": {
                      backgroundColor: "common.white",
                      color: "common.black",
                    },
                  }}
                  variant="contained"
                >
                  <Box
                    alt="Google"
                    component="img"
                    src="/assets/auth/logo-google.svg"
                    sx={{ mr: 1 }}
                  />
                  Google
                </Button>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    mt: 2,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Divider orientation="horizontal" />
                  </Box>
                  <Typography color="text.secondary" sx={{ m: 2 }} variant="body1">
                    OU
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    <Divider orientation="horizontal" />
                  </Box>
                </Box>
              </Box>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Nome Completo"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Endereço de Email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Senha"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  ml: -1,
                  mt: 1,
                }}
              >
                <Checkbox
                  checked={formik.values.policy}
                  name="policy"
                  onChange={formik.handleChange}
                />
                <Typography color="text.secondary" variant="body2">
                  Eu aceito os{" "}
                  <MuiLink component="a" href={paths.terms}>
                    Termos de Serviço
                  </MuiLink>{" "}
                  e li a{" "}
                  <MuiLink component="a" href={paths.privacy}>
                    Política de Privacidade
                  </MuiLink>
                </Typography>
              </Box>
              {!!(formik.touched.policy && formik.errors.policy) && (
                <FormHelperText error>{formik.errors.policy}</FormHelperText>
              )}
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit as string}
                </FormHelperText>
              )}
              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  disabled={!formik.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Criar conta
                </LoadingButton>
              </Box>
            </form>
          </CardContent>
        </Card>
        {/* <Box sx={{ mt: 3 }}>
          <AuthIssuer issuer={issuer} />
        </Box> */}
      </div>
    </>
  );
};

export default Page;
