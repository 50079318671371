import React from "react";
import Cookies from "js-cookie";
import { SettingsProvider } from "../contexts/settings";
import { Settings } from "../types/settings";
import { defaultSettings } from "./settings/settings-context";

const SETTINGS_STORAGE_KEY = "app.settings";

// Configurações padrão caso o cookie não seja encontrado

const resetSettings = (): void => {
  try {
    Cookies.remove(SETTINGS_STORAGE_KEY);
    window.location.reload(); // Recarrega a página após resetar as configurações
  } catch (err) {
    console.error(err);
  }
};

const updateSettings = (settings: Settings): void => {
  try {
    Cookies.set(SETTINGS_STORAGE_KEY, JSON.stringify(settings));
    window.location.reload(); // Recarrega a página após atualizar as configurações
  } catch (err) {
    console.error(err);
  }
};

const restoreSettings = (): Settings => {
  const cookieValue = Cookies.get(SETTINGS_STORAGE_KEY);
  if (!cookieValue) {
    // Retorna as configurações padrão se o cookie não for encontrado
    return defaultSettings;
  }

  try {
    return JSON.parse(cookieValue);
  } catch (err) {
    console.error("Failed to parse settings cookie", err);
    return defaultSettings; // Retorna as configurações padrão em caso de erro de parsing
  }
};

export function SettingsContextProvider({ children }: { children: React.ReactNode }) {
  const settings = restoreSettings(); // Garante que sempre teremos um valor de configurações

  return (
    <SettingsProvider onReset={resetSettings} onUpdate={updateSettings} settings={settings}>
      {children}
    </SettingsProvider>
  );
}
