export enum Mimetype {
  Image = "image",
  Video = "video",
  Pdf = "PDF",
  Unknown = "unknown",
}

export function getMimetype(str: string | null | undefined): Mimetype {
  if (!str) {
    return Mimetype.Unknown;
  }

  const sanaizedStr = str.toLowerCase();

  if (sanaizedStr.includes("video")) return Mimetype.Video;
  if (sanaizedStr.includes("image")) return Mimetype.Image;
  if (sanaizedStr.includes("pdf")) return Mimetype.Pdf;

  return Mimetype.Unknown;
}
