import type { FC } from "react";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { Scrollbar } from "src/components/scrollbar";
import { Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useSelector } from "react-redux";
import { authSlice, forgotPassword } from "src/features/auth/authSlice";
import { useAppDispatch } from "src/app/hooks";

export interface LoginEvent {
  id: string;
  createdAt: number;
  ip: string;
  type: string;
  userAgent: string;
}

type AccountSecuritySettingsProps = object;

export const AccountSecuritySettings: FC<AccountSecuritySettingsProps> = (props) => {
  const { api } = useThumbzApi();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const supabaseUser = useSelector(authSlice.selectors.selectSupabaseUser);
  const dispatch = useAppDispatch();

  const _forgotPassword = React.useCallback(
    (email: string) => {
      return dispatch(forgotPassword({ email }));
    },
    [dispatch],
  );

  const pagination = {
    page: 1,
    size: 10,
  };

  const loginQuery = useQuery({
    queryKey: queryKeys.audits.login(pagination).queryKey,
    queryFn: () => api.audits.auditControllerListLogin(pagination),
  });

  const loginEvents = loginQuery.data?.data ? loginQuery.data?.data : [];
  const arrLoginEvents: LoginEvent[] = loginEvents.map((e) => ({
    createdAt: Date.parse(e.lga_created_at),
    id: e.lga_id,
    ip: e.lga_ip_address,
    type: e.lga_type,
    userAgent: String(e.lga_client),
  }));

  const handleEdit = useCallback((): void => {
    if (supabaseUser?.email) {
      _forgotPassword(supabaseUser?.email)
        .then(() => {
          toast.success("Email de redefinição de senha enviado!");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else
      toast.error(
        "Email do usuário não encontrado. O email de redefinição de senha não pôde ser enviado. Tente sair e entrar novamente.",
      );
  }, [supabaseUser?.email, _forgotPassword]);

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={12}>
              <Stack alignItems="center" direction="row" spacing={3}>
                <Typography variant="h6">Alterar senha</Typography>
                <Typography
                  sx={{
                    flexGrow: 1,
                    ...(!isEditing && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderStyle: "dotted",
                      },
                    }),
                  }}
                >
                  Um email será enviado para você com um link para redefinir sua senha.
                </Typography>
                <Button onClick={handleEdit}>{"Enviar email de redefinição"}</Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Autenticação de dois fatores" />
        <CardContent sx={{ pt: 0 }}>
          <Grid container spacing={4}>
            <Grid xs={12} sm={6}>
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: "block",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        "&::before": {
                          backgroundColor: "error.main",
                          borderRadius: "50%",
                          content: '""',
                          display: "block",
                          height: 8,
                          left: 4,
                          position: "absolute",
                          top: 7,
                          width: 8,
                          zIndex: 1,
                        },
                      }}
                    >
                      <Typography color="error" sx={{ pl: 3 }} variant="body2">
                        Desligado
                      </Typography>
                    </Box>
                  </Box>
                  <Typography sx={{ mt: 1 }} variant="subtitle2">
                    Aplicativo Autenticador
                  </Typography>
                  <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
                    Use um aplicativo autenticador para gerar códigos de segurança únicos.
                  </Typography>
                  <Box sx={{ mt: 4 }}>
                    <Tooltip title={"Em breve"}>
                      <Button
                        endIcon={
                          <SvgIcon>
                            <ArrowRightIcon />
                          </SvgIcon>
                        }
                        disabled
                        variant="outlined"
                      >
                        Configurar
                      </Button>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid sm={6} xs={12}>
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardContent>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        "&::before": {
                          backgroundColor: "error.main",
                          borderRadius: "50%",
                          content: '""',
                          display: "block",
                          height: 8,
                          left: 4,
                          position: "absolute",
                          top: 7,
                          width: 8,
                          zIndex: 1,
                        },
                      }}
                    >
                      <Typography color="error" sx={{ pl: 3 }} variant="body2">
                        Desligado
                      </Typography>
                    </Box>
                  </Box>
                  <Typography sx={{ mt: 1 }} variant="subtitle2">
                    Mensagem de Texto
                  </Typography>
                  <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
                    Use seu celular para receber códigos de segurança via SMS.
                  </Typography>
                  <Box sx={{ mt: 4 }}>
                    <Tooltip title={"Em breve"}>
                      <Button
                        endIcon={
                          <SvgIcon>
                            <ArrowRightIcon />
                          </SvgIcon>
                        }
                        disabled
                        variant="outlined"
                      >
                        Configurar
                      </Button>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Histórico de login" subheader="Sua atividade recente de login" />
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell>Horário</TableCell>
                <TableCell>Endereço IP</TableCell>
                <TableCell>Cliente</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrLoginEvents.map((event) => {
                const createdAt = format(event.createdAt, "HH:mm a MM/dd/yyyy");

                return (
                  <TableRow
                    key={event.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography variant="body2" color="body2">
                        {createdAt}
                      </Typography>
                    </TableCell>
                    <TableCell>{event.ip}</TableCell>
                    <TableCell>{event.userAgent}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </Stack>
  );
};

AccountSecuritySettings.propTypes = {
  loginEvents: PropTypes.array.isRequired,
};
