import { MoreVertOutlined, Email, WhatsApp, Edit, MoreHorizOutlined } from "@mui/icons-material";
import {
  IconButton,
  Popover,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link01, Eye } from "@untitled-ui/icons-react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import React from "react";
import toast from "react-hot-toast";
import { IApprovalStageCustomer } from "src/services/thumbz-base-api";
import { getLinkToApprove } from "src/utils/get-link-to-approve";

interface ICustomerPopupMenuProps {
  approvalStageCustomer: IApprovalStageCustomer;
  wrk_slug: string;
}

export const CustomerPopupMenu: React.FC<ICustomerPopupMenuProps> = ({
  approvalStageCustomer,
  wrk_slug,
}) => {
  function handleCopyLink() {
    if (!approvalStageCustomer?.asc_id) {
      toast.error(
        "Não foi possível copiar o link | " + wrk_slug + " " + approvalStageCustomer?.asc_id,
      );
      return;
    }

    navigator.clipboard.writeText(
      getLinkToApprove({ asc_id: approvalStageCustomer.asc_id, wrk_slug }),
    );
    toast.success("Link copiado");
  }

  function handleResendEmail() {
    alert("Email reenviado");
  }

  function handleResendWhatsApp() {
    alert("WhatsApp reenviado");
  }

  return (
    <PopupState variant="popover" popupId="approval-customer-actions">
      {(popupState) => (
        <div>
          <IconButton
            {...bindTrigger(popupState)}
            sx={{
              p: 0,
            }}
          >
            <MoreHorizOutlined />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Menu
              anchorEl={popupState.anchorEl}
              open={Boolean(popupState.anchorEl)}
              onClose={() => popupState.close()}
            >
              <MenuItem onClick={handleCopyLink}>
                <ListItemIcon>
                  <Link01 />
                </ListItemIcon>
                <ListItemText>Copiar link</ListItemText>
              </MenuItem>

              <MenuItem disabled onClick={handleResendEmail}>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText>Reenviar Email</ListItemText>
              </MenuItem>

              <MenuItem disabled onClick={handleResendWhatsApp}>
                <ListItemIcon>
                  <WhatsApp />
                </ListItemIcon>
                <ListItemText>Reenviar WhatsApp</ListItemText>
              </MenuItem>
            </Menu>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
