import React from "react";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { FieldProps } from "formik";
import { FormControl } from "@mui/material";

interface PhoneNumberInputProps extends FieldProps {
  label: string;
  setCountryCode: (countryCode: MuiTelInputInfo) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  label,
  field,
  form,
  setCountryCode,
}) => {
  return (
    <FormControl margin="dense" fullWidth>
      <MuiTelInput
        label={label}
        value={field.value}
        onChange={(value, info) => {
          form.setFieldValue(field.name, value);
          if (setCountryCode && info) {
            setCountryCode(info);
          }
        }}
        error={Boolean(form.errors[field.name] && form.touched[field.name])}
        helperText={
          form.errors[field.name] && form.touched[field.name]
            ? String(form.errors[field.name])
            : undefined
        }
        defaultCountry="BR"
        fullWidth
      />
    </FormControl>
  );
};

export default PhoneNumberInput;
