export const getInitials = (name: string | undefined | null = ''): string => {
  const cleanedName = (name || '').replace(/\s+/, ' ');
  if (cleanedName.includes(' ')) {
    return cleanedName
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');
  } else {
    return cleanedName.slice(0, 2).toUpperCase();
  }
};
