import { Stack, Box } from "@mui/system";
import { createFileRoute } from "@tanstack/react-router";
import { PageContainer } from "src/components/page-container";
import { AccountUsage } from "src/components/usage/account-usage";
import { WorkspaceFormProvider } from "src/contexts/workspace/workspace-form";
import { WorkspaceGuard } from "src/guards/workspace-guard";
import { usePageView } from "src/hooks/use-page-view";
import { WorkspaceUpsertForm } from "src/sections/dashboard/workspace/workspace-upsert-form";

export const Route = createFileRoute("/_authenticated/workspaces/criar/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  return (
    <PageContainer title={"Criar novo workspace"}>
      <Stack spacing={2}>
        <AccountUsage
          type="workspaces"
          show_when={{
            error: true,
            warning: false,
            success: false,
            info: false,
          }}
        />

        <Box maxWidth={"sm"}>
          <WorkspaceFormProvider>
            <WorkspaceUpsertForm />
          </WorkspaceFormProvider>
        </Box>
      </Stack>
    </PageContainer>
  );
};
