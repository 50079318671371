import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const queryKeys = createQueryKeyStore({
  appupdate: null,
  audits: {
    api: ({ page, size }: { page: number; size: number }) => [page, size],
    activities: ({ page, size }: { page: number; size: number }) => [page, size],
    twilio: ({ page, size }: { page: number; size: number }) => [page, size],
    login: ({ page, size }: { page: number; size: number }) => [page, size],
  },
  auth: {
    login: ["auth", "login"],
    info: ["auth", "info"],
    fblogin: ["auth", "fblogin"],
    register: ["auth", "register"],
  },
  platform: {
    list: ["platform", "list"],
  },
  dashboard: {
    dashboardControllerGetDashboardReport: ({
      endDate,
      startDate,
      wrk_id,
      org_id,
    }: {
      startDate: string;
      endDate: string;
      wrk_id?: string;
      org_id: string;
    }) => [
      "dashboard",
      "dashboardControllerGetDashboardReport",
      org_id,
      startDate,
      endDate,
      wrk_id,
    ],
  },
  autocomplete: {
    roles: ["autocomplete", "roles"],
    platforms: ["autocomplete", "platforms"],
  },
  healthCheck: {
    liveness: ["healthCheck", "liveness"],
    readiness: ["healthCheck", "readiness"],
  },
  workspace: {
    get: ({ wrk_id }: { wrk_id: string }) => ["workspace", "get", wrk_id],
    usage: ({ wrk_id }: { wrk_id: string }) => ["workspace", "usage", wrk_id],
    list: ({
      wrk_id,
      org_id,
      with_relations,
    }: {
      wrk_id?: string;
      with_relations?: boolean;
      org_id: string;
    }) => {
      return [org_id, wrk_id, with_relations];
    },
    workspaceControllerGetWorkspaceFeatures: ({ wrk_id }: { wrk_id: string }) => [wrk_id],
    upsert: ["workspace", "upsert"],
    delete: ["workspace", "delete"],
    generateApiKey: ["workspace", "generateApiKey"],
    invite: ["workspace", "invite"],
    cancelInvite: ["workspace", "cancelInvite"],
    uninvite: ["workspace", "uninvite"],
  },
  approvalFlow: {
    approvalFlowControllerList: ({ wrk_id }: { wrk_id: string }) => [
      "approvalFlow",
      "approvalFlowControllerList",
      wrk_id,
    ],
    approvalFlowControllerFind: ({ flow_id }: { flow_id: string }) => [
      "approvalFlow",
      "approvalFlowControllerFind",
      flow_id,
    ],
  },
  approvalFlowStage: {
    approvalFlowStageControllerFind: ({ afs_id }: { afs_id: string }) => [
      "approvalFlowStage",
      "approvalFlowStageControllerFind",
      afs_id,
    ],
    approvalFlowStageControllerUpsert: ["approvalFlowStage", "approvalFlowStageControllerUpsert"],
    approvalFlowStageControllerDelete: ["approvalFlowStage", "approvalFlowStageControllerDelete"],
  },
  approval: {
    list: ({ wrk_id }: { wrk_id: string; page?: number; limit?: number }) => [
      "approval",
      "list",
      wrk_id,
    ],
    byId: ({ apv_id }: { apv_id: string }) => ["approval", "byId", apv_id],
    upsert: ["approval", "upsert"],
    enableOrDisable: ["approval", "enableOrDisable"],
    forceUpgradeEnableOrDisable: ["approval", "forceUpgradeEnableOrDisable"],
    delete: ["approval", "delete"],
    approvalControllerList: ({ wrk_id, org_id }: { wrk_id?: string; org_id: string }) => [
      "approval",
      "list",
      org_id,
      wrk_id,
    ],
    approvalControllerUpsert: ["approval", "upsert"],
    approvalControllerDelete: ["approval", "delete"],
    approvalControllerGet: (apv_id: string) => ["approval", "get", apv_id],
  },
  billing: {
    status: ({ org_id }: { org_id: string }) => ["billing", "status", org_id],
    plans: ["billing", "plans"],
    portal: ["billing", "portal"],
  },
  users: {
    userControllerGetMyAccountUsage: (usu_id: string) => [
      "users",
      "userControllerGetMyAccountUsage",
      usu_id,
    ],
    config: ["users", "config"],
    byId: ["users", "byId"],
    changePhoto: ["users", "changePhoto"],
    deleteUser: ["users", "deleteUser"],
    all: ["users", "all"],
  },
  publicApi: {
    plans: ["publicApi", "plans"],
  },
  organization: {
    list: ({ usu_id }: { usu_id: string }) => ["organizationControllerList", usu_id],
    collaborators: ({ org_id }: { org_id: string }) => ["organization", "collaborators", org_id],
    invitations: ({ org_id }: { org_id: string }) => ["organization", "invitations", org_id],
    getInvitationByToken: ({ token }: { token: string }) => [
      "organization",
      "invitations",
      "token",
      token,
    ],
    usage: ({ org_id }: { org_id: string }) => ["organization", "usage", org_id],
    features: ({ org_id }: { org_id: string }) => ["organization", "features", org_id],
    roles: ({ org_id, usu_id }: { org_id: string; usu_id: string }) => [
      "organization",
      "roles",
      org_id,
      usu_id,
    ],
  },
  customer: {
    customerControllerList: ({ wrk_id }: { wrk_id: string }) => ["customer", "list", wrk_id],
    customerControllerFind: (custId: string) => ["customer", "find", custId],
    customerControllerUpsert: ["customer", "upsert"],
    customerControllerDelete: ["customer", "delete"],
    customerControllerUpdatePhoto: ["customer", "updatePhoto"],
  },
  approvalStage: {
    approvalStageControllerList: ({ apv_id }: { apv_id: string }) => [
      "approvalStage",
      "approvalStageControllerList",
      apv_id,
    ],
    approvalStageControllerFind: (stgId: string) => [
      "approvalStage",
      "approvalStageControllerFind",
      stgId,
    ],
    approvalStageControllerUpsert: () => ["approvalStage", "approvalStageControllerUpsert"],
    approvalStageControllerDelete: ["approvalStage", "approvalStageControllerDelete"],
  },
  status: {
    statusControllerFindAll: () => ["status", "findAll"],
    statusControllerFindOne: ({ sts_id }: { sts_id: string }) => ["status", "findOne", sts_id],
    statusControllerRemove: ({ sts_id }: { sts_id: string }) => ["status", "remove", sts_id],
    statusControllerFindByWorkspace: ({ wrk_id }: { wrk_id: string }) => [
      "status",
      "findByWorkspace",
      wrk_id,
    ],
    statusControllerUpsert: () => ["status", "upsert"],
  },
  approvalTypes: {
    approvalTypeControllerList: ({ org_id }: { org_id: string }) => [org_id],
    approvalTypeControllerGetApprovalType: (apt_id: string) => [apt_id],
  },
});
