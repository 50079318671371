import { Tooltip, Button } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

interface IApprovalAddFileProps {
  onAddFile: (files: FileList | null) => void;
  disabled?: boolean;
}

export const ApprovalAddFile: React.FC<IApprovalAddFileProps> = ({ onAddFile, disabled }) => {
  const _handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onAddFile(event.target.files);
    }
  };

  return (
    <Tooltip title=".png, .jpg, .jpeg, .mp4, .pdf">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        component="span"
        style={{ position: "relative", overflow: "hidden" }}
        disabled={disabled}
      >
        Arquivos
        <input
          accept="image/*, video/*, application/pdf"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }}
          type="file"
          multiple
          onChange={_handleAddFile}
        />
      </Button>
    </Tooltip>
  );
};
