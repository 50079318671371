import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/conta/geral/")({
  component: () => <Page />,
});

import { usePageView } from "src/hooks/use-page-view";
import { AccountGeneralSettings } from "src/sections/dashboard/account/account-general-settings";
import { useSelector } from "react-redux";
import { authSlice } from "src/features/auth/authSlice";
import { PageContainer } from "src/components/page-container";

const Page = () => {
  const selectedUser = useSelector(authSlice.selectors.selectUser);

  usePageView();

  return (
    <PageContainer title={"Meu perfil"} subtitle="Gerencie suas informações de perfil">
      <AccountGeneralSettings
        email={selectedUser?.usu_email ?? null}
        name={selectedUser?.usu_name ?? null}
      />
    </PageContainer>
  );
};

export default Page;
