import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { queryKeys } from "../config/query-key";
import { useOrganization } from "../contexts/organization/OrganizationContext";
import { organizationSlice } from "../features/organization/organizationSlice";
import { thumbzApi } from "../services/thumbz-api";
import { IBillingStatusDto, IBillingStatusPlanSlugEnum } from "../services/thumbz-base-api";

interface IBillingData extends Omit<IBillingStatusDto, "billing"> {
  billing: IBillingStatusDto["billing"] | undefined;
}

interface UseBillingStatusHook {
  data: IBillingData;
  query: UseQueryResult<IBillingStatusDto, Error>;
}

export const useBillingStatus = (): UseBillingStatusHook => {
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useOrganization();

  const queryKey = queryKeys.billing.status({
    org_id: String(selectedOrganization?.org_id),
  }).queryKey;
  const enabled = !!selectedOrganization?.org_id;

  const billingStatus = useQuery({
    queryFn: () =>
      thumbzApi.billing.billingControllerGetBillingStatus({
        org_id: String(selectedOrganization?.org_id),
      }),
    queryKey,
    enabled,
    refetchInterval: 60 * 60 * 1000, // Changed to one hour
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
  });

  const { data, isLoading } = billingStatus;

  useEffect(() => {
    if (data) {
      dispatch(organizationSlice.actions.updateOrganizationBilling(billingStatus.data));
    }
  }, [billingStatus.data, data, dispatch]);

  return {
    data: {
      billing: data?.billing || undefined,
      color: data?.color || "info",
      message: data?.message || "Loading...",
      should_show_warning: data?.should_show_warning || false,
      title: data?.title || "Loading...",
      plan: data?.plan || {
        label: "Loading...",
        slug: IBillingStatusPlanSlugEnum.Basic,
      },
    },
    query: billingStatus,
  };
};
