import React, { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import {
  Box,
  Tooltip,
  IconButton,
  Stack,
  Popover,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { AgGridReactDefault } from "./table/custom-table";
import { useThumbzApi } from "src/services/thumbz-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { IApprovalFlow } from "src/services/thumbz-base-api";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { Add, Visibility, Delete, MoreVertOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { useRouter } from "src/hooks/use-router";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { ApprovalFlowUpsertForm } from "src/sections/approval-flow/approval-flow-upsert-form";

interface ApprovalFlowTableProps {
  wrk_id: string;
}

export const ApprovalFlowTable: React.FC<ApprovalFlowTableProps> = ({ wrk_id }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Estado para controlar o diálogo
  const router = useRouter();
  const confirm = useConfirm();
  const [rowData, setRowData] = useState<IApprovalFlow[]>([]);
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleAddFlow = (flowName: string, flowDescription: string) => {
    createApprovalFlowMutation.mutate({
      flow_name: flowName,
      flow_description: flowDescription,
      wrk_id,
    });
  };

  const [colDefs] = useState<ColDef<IApprovalFlow>[]>([
    { field: "flow_id", headerName: "ID", hide: true },
    {
      field: "flow_name",
      headerName: "Nome",
      hide: false,
      flex: 1,
      pinned: "left",
      filter: "agTextColumnFilter",
    },
    {
      field: "flow_description",
      headerName: "Descrição",
      flex: 1,
      valueFormatter: (params) => params.value || "Sem descrição",
      filter: "agTextColumnFilter",
    },
    {
      field: "stages",
      headerName: "Etapas",
      flex: 1,
      valueFormatter: (params) =>
        params.node?.data?.stages?.map((s, i) => `${i + 1}. ${s.afs_name}`).join(", ") ||
        "Sem etapas",
    },
    {
      headerName: "Ações",
      pinned: "right",
      width: 100,
      cellRenderer: (params: { data: IApprovalFlow }) => (
        <PopupState variant="popover" popupId={`popup-menu-${params.data.flow_id}`}>
          {(popupState) => (
            <div>
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlined />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Menu
                  anchorEl={popupState.anchorEl}
                  open={Boolean(popupState.anchorEl)}
                  onClose={(event, reason) => popupState.close()}
                >
                  <MenuItem onClick={() => handleViewSelectedFlow(params.data)}>
                    <ListItemIcon>
                      <Visibility fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Visualizar</ListItemText>
                  </MenuItem>

                  <MenuItem color="error" onClick={() => handleDelete(params.data.flow_id)}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Excluir</ListItemText>
                  </MenuItem>
                </Menu>
              </Popover>
            </div>
          )}
        </PopupState>
      ),
    },
  ]);

  function clearCache() {
    queryClient.invalidateQueries({
      queryKey: queryKeys.approvalFlow.approvalFlowControllerList({ wrk_id }).queryKey,
    });
  }

  const approvalFlowsQuery = useQuery({
    queryKey: queryKeys.approvalFlow.approvalFlowControllerList({ wrk_id }).queryKey,
    queryFn: () => api.approvalFlow.approvalFlowControllerList({ wrk_id }),
    enabled: !!wrk_id,
  });

  const createApprovalFlowMutation = useMutation({
    mutationFn: api.approvalFlow.approvalFlowControllerUpsert,
    onSuccess: ({ flow_id }) => {
      clearCache();
      toast.success("Fluxo de aprovação criado com sucesso");
      router.navigate({
        from: "/workspaces/$wrk_id",
        to: "/workspaces/$wrk_id/flow/$flow_id",
        params: { wrk_id, flow_id },
      });
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const deleteApprovalFlowMutation = useMutation({
    mutationFn: api.approvalFlow.approvalFlowControllerDelete,
    onSuccess: () => {
      clearCache();
      toast.success("Fluxo de aprovação deletado com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  useEffect(() => {
    if (approvalFlowsQuery.data) {
      setRowData(approvalFlowsQuery.data);
    }
  }, [approvalFlowsQuery.data]);

  const handleDelete = (flow_id: string) => {
    confirm({
      description: "Tem certeza que deseja excluir o fluxo de aprovação?",
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      deleteApprovalFlowMutation.mutate({ flow_id });
    });
  };

  const handleAdd = () => {
    const newRow: Partial<IApprovalFlow> = {
      flow_id: "",
      flow_name: "",
      flow_description: "",
      stages: [],
      workspace: undefined,
    };
    setRowData((prevRowData: IApprovalFlow[]) => [newRow, ...prevRowData] as IApprovalFlow[]);
  };

  function handleViewSelectedFlow(flow: IApprovalFlow): void {
    router.navigate({
      to: "/workspaces/$wrk_id/flow/$flow_id",
      params: { wrk_id, flow_id: flow.flow_id },
    });
  }

  return (
    <Stack spacing={2}>
      <Stack justifyContent="flex-end" flex={1} direction="row">
        <Tooltip title="Novo Fluxo">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDialogOpen} // Abre o diálogo ao clicar
            startIcon={<Add />}
          >
            Novo Fluxo
          </Button>
        </Tooltip>
      </Stack>

      <AgGridReactDefault<IApprovalFlow>
        loading={approvalFlowsQuery.isLoading}
        rowData={rowData}
        columnDefs={colDefs}
        rowSelection="single"
      />

      <ApprovalFlowUpsertForm
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleAddFlow} // Envia os dados do novo fluxo
      />
    </Stack>
  );
};
