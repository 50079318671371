import { alpha } from '@mui/system/colorManipulator';
import type { ColorRange, PaletteColor } from '@mui/material/styles/createPalette';

export enum ThemeColor {
  AmericanYellow = 'American Yellow',
  Gunmetal = 'Gunmetal',
  HalloweenOrange = 'Halloween Orange',
  RustyRed = 'Rusty Red',
  AntiFlashWhite = 'Anti-Flash White',
  Black = 'Black',
}

export const THEME_COLOR: Record<ThemeColor, string> = {
  [ThemeColor.AmericanYellow]: '#F7AC00',
  [ThemeColor.Gunmetal]: '#282C39',
  [ThemeColor.HalloweenOrange]: '#EB5D16',
  [ThemeColor.RustyRed]: '#D41F45',
  [ThemeColor.AntiFlashWhite]: '#f0f5ef',
  [ThemeColor.Black]: '#000000',
};

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  };
};

export const neutral: ColorRange = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#282C38',
  700: '#282C38',
  800: '#282C38',
  900: '#1A1C24',
};

export const americanYellow = withAlphas({
  lightest: '#FDEECC',
  light: '#FCDE99',
  main: THEME_COLOR[ThemeColor.AmericanYellow],
  dark: '#FACD66',
  darkest: '#F9BD33',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const gunmetal = withAlphas({
  main: THEME_COLOR.Gunmetal,
  lightest: '#F4F5F7',
  light: '#E9EBF0',
  dark: '#1E2029',
  darkest: '#0D0E12',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const halloweenOrange = withAlphas({
  main: THEME_COLOR[ThemeColor.HalloweenOrange],
  lightest: '#FEE9E2',
  light: '#FDD3C5',
  dark: '#E94F1A',
  darkest: '#D63A0F',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const rustyRed = withAlphas({
  main: THEME_COLOR[ThemeColor.RustyRed],
  lightest: '#FCE9E9',
  light: '#F9C9C9',
  dark: '#D41F45',
  darkest: '#B51A3E',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: THEME_COLOR[ThemeColor.AntiFlashWhite],
});
