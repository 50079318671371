import {
  IApprovalStageCustomer,
  IApprovalItemRevisionAirStatusEnum,
} from 'src/services/thumbz-base-api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip';

type ChipColorType = OverridableStringUnion<
  'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  ChipPropsColorOverrides
>;

interface IStatusChips {
  label: string;
  color: ChipColorType;
  icon: JSX.Element;
  count: number;
  tooltip: string;
}

export function getApprovalStatusChips(customers: IApprovalStageCustomer[]): IStatusChips[] {
  const statusCount = customers.reduce(
    (acc, customer) => {
      customer.revisions.forEach((revision) => {
        acc[revision.air_status] = (acc[revision.air_status] || 0) + 1;
      });
      return acc;
    },
    {
      [IApprovalItemRevisionAirStatusEnum.Pending]: 0,
      [IApprovalItemRevisionAirStatusEnum.Approved]: 0,
      [IApprovalItemRevisionAirStatusEnum.Requested]: 0,
    } as Record<IApprovalItemRevisionAirStatusEnum, number>
  );

  const statusChips: IStatusChips[] = [
    {
      label: `Pendente (${statusCount[IApprovalItemRevisionAirStatusEnum.Pending]})`,
      color: 'info',
      icon: <HourglassEmptyIcon />,
      count: statusCount[IApprovalItemRevisionAirStatusEnum.Pending],
      tooltip: 'Número de revisões que estão aguardando aprovação.',
    },
    {
      label: `Mudanças Solicitadas (${statusCount[IApprovalItemRevisionAirStatusEnum.Requested]})`,
      color: 'warning',
      icon: <ReplayIcon />,
      count: statusCount[IApprovalItemRevisionAirStatusEnum.Requested],
      tooltip: 'Número de revisões para as quais foram solicitadas mudanças.',
    },
    {
      label: `Aprovados (${statusCount[IApprovalItemRevisionAirStatusEnum.Approved]})`,
      color: 'success',
      icon: <CheckCircleIcon color="success" />,
      count: statusCount[IApprovalItemRevisionAirStatusEnum.Approved],
      tooltip: 'Número de revisões que foram aprovadas.',
    },
  ];

  return statusChips;
}
