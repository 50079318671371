import { useState, type ChangeEvent, type FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { IConfig } from "src/services/thumbz-base-api";
import toast from "react-hot-toast";
import { Skeleton } from "@mui/material";

export const AccountNotificationsSettings: FC = () => {
  const { api } = useThumbzApi();

  const notificationQuery = useQuery({
    queryKey: queryKeys.users.config.queryKey,
    queryFn: () => api.users.userControllerGetUserConfig(),
  });

  if (notificationQuery.isLoading) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Skeleton variant="rectangular" height={100} />
              <Skeleton variant="rectangular" height={100} />
              <Skeleton variant="rectangular" height={100} />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {notificationQuery.error && (
          <Typography color="error">{JSON.stringify(notificationQuery.error)}</Typography>
        )}
        {notificationQuery.data?.map(({ category, configs }) => (
          <>
            <Grid container spacing={3}>
              <Grid xs={12} md={4}>
                <Typography variant="h6">{category}</Typography>
              </Grid>
              <Grid xs={12} sm={12} md={8}>
                <Stack divider={<Divider />} spacing={3}>
                  {configs.map(({ config, value }) => (
                    <Stack
                      key={value}
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography variant="subtitle1">{config.conf_title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {config.conf_description}
                        </Typography>
                      </Stack>
                      <SwitchButton config={config} value={value} />
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
          </>
        ))}
      </CardContent>
    </Card>
  );
};

interface SwitchButton {
  config: IConfig;
  value: string;
}

const SwitchButton: FC<SwitchButton> = ({ config, value }) => {
  function convertUnknownToBoolean(value: string | boolean) {
    if (typeof value === "string") {
      return value === "true";
    }

    return value;
  }

  const [local, setLocal] = useState<boolean>(convertUnknownToBoolean(value));
  const { api } = useThumbzApi();

  const updateUserConfig = useMutation({
    mutationFn: api.users.userControllerUpdateUserConfig,
    onSuccess: () => toast.success("Config updated"),
    onError: () => {
      toast.error("Error updating config, try again.");
      setLocal(!local);
    },
  });

  function handleChange(_event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    setLocal(checked);
    updateUserConfig.mutate({
      conf_id: config.conf_id,
      new_value: checked ? "true" : "false",
    });
  }

  return <Switch onChange={handleChange} defaultChecked={convertUnknownToBoolean(value)} />;
};
