import { Box, IconButton, Stack, Typography } from "@mui/material";
import { RenderAvatar } from "./RenderAvatar";
import { ICustomer } from "src/services/thumbz-base-api";
import { IStatusIcon, RenderStatusIcon } from "./StatusIcon";

export const CustomerAvatarWithIcons = ({
  customer,
  showIcon,
  onToggleSelection,
  icon = "success",
  size = 75,
  showLegend = true,
}: {
  customer: ICustomer;
  showIcon: boolean;
  onToggleSelection?: () => void;
  icon?: IStatusIcon;
  size?: number;
  showLegend?: boolean;
}) => {
  return (
    <Box
      position="relative"
      display="inline-block"
      onClick={onToggleSelection}
      sx={{ cursor: "pointer" }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        {/* Avatar */}
        <Box position="relative" display="inline-block">
          <RenderAvatar
            key={customer.cust_id}
            src={customer.cust_photo?.ast_url}
            variant="circular"
            sx={{ width: size, height: size }}
          />

          {/* Check icon (bottom/right) */}
          {showIcon && (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              {/* <CheckCircle
                fontSize="medium"
                color="success"
              /> */}
              <RenderStatusIcon icon={icon} size={size} />
            </IconButton>
          )}
        </Box>

        {/* Customer name */}
        {showLegend && (
          <Typography variant="caption" align="center">
            {customer.cust_name}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default CustomerAvatarWithIcons;
