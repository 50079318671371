import { Done } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Box, darken, Stack, styled } from '@mui/system';
import React from 'react';
import { IStatusTypeStpTypeEnum } from 'src/services/thumbz-base-api';

// export enum IStatusTypeStpTypeEnum {
//   NOT_STARTED = 'NOT_STARTED',
//   ACTIVE = 'ACTIVE',
//   DONE = 'DONE',
//   ARCHIVED = 'ARCHIVED',
// }

interface StatusIconProps {
  statusType: IStatusTypeStpTypeEnum;
  color: string;
}

const DEFAULT_ICON_SIZE = 18;

export const StatusIcon: React.FC<StatusIconProps> = ({ statusType, color }) => {
  const isStatusInProgress = () => {
    return statusType === IStatusTypeStpTypeEnum.NOT_STARTED;
  };

  if (isStatusInProgress()) {
    return <StatusInProgress color={color} />;
  }

  return <StatusDone color={color} />;
};

interface IStatusComponent {
  color: string;
  size?: number;
}

const StatusDone: React.FC<IStatusComponent> = ({ color, size = DEFAULT_ICON_SIZE }) => {
  const hoverColor = darkenColor(color);
  return (
    <CustomICon
      hoverColor={hoverColor}
      bgcolor={color}
      width={size}
      height={size}
      borderRadius={50}
      alignItems="center"
      justifyContent="center"
    >
      <Done
        style={{
          color: 'white',
          fontSize: size / 1.5,
        }}
      />
    </CustomICon>
  );
};

const StatusInProgress: React.FC<IStatusComponent> = ({ color, size = DEFAULT_ICON_SIZE }) => {
  const { palette } = useTheme();
  const whiteSize = size / 1.15;
  const boxSize = whiteSize / 1.2;

  const hoverColor = darkenColor(color);

  return (
    <CustomICon
      bgcolor={color}
      width={size}
      height={size}
      borderRadius={50}
      alignItems="center"
      justifyContent="center"
      hoverColor={hoverColor}
    >
      <Stack
        width={whiteSize}
        height={whiteSize}
        borderRadius={50}
        bgcolor={palette.background.default}
        alignItems="center"
        justifyContent="center"
      >
        <CustomICon
          hoverColor={hoverColor}
          width={boxSize}
          height={boxSize}
          borderRadius={50}
          bgcolor={color}
        />
      </Stack>
    </CustomICon>
  );
};

const CustomICon = styled(Stack)<{ hoverColor: string }>`
  &:hover {
    background-color: ${(props) => props.hoverColor};
    cursor: pointer;
  }
`;

function darkenColor(color: string): string {
  return darken(color, 0.2);
}
