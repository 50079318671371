import { IStatusIcon } from "src/components/avatar/StatusIcon";
import { IApprovalItemRevisionAirStatusEnum } from "src/services/thumbz-base-api";

export const useIcon = ({
  air_status,
}: {
  air_status: IApprovalItemRevisionAirStatusEnum;
}): IStatusIcon => {
  let icon: IStatusIcon = "warning";

  if (air_status === IApprovalItemRevisionAirStatusEnum.Approved) {
    icon = "success";
  }

  if (air_status === IApprovalItemRevisionAirStatusEnum.Requested) {
    icon = "error";
  }

  return icon;
};
