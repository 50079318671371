import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../config/query-key";
import { useOrganization } from "../contexts/organization/OrganizationContext";
import { thumbzApi } from "../services/thumbz-api";

export const useWorkspaces = () => {
  const { selectedOrganization } = useOrganization();

  const params = {
    org_id: String(selectedOrganization?.org_id),
    with_relations: true,
  };
  const query = useQuery({
    queryKey: queryKeys.workspace.list(params).queryKey,
    queryFn: () => thumbzApi.workspace.workspaceControllerList(params),
    enabled: !!selectedOrganization?.org_id,
  });

  return query;
};
