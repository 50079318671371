import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { Scrollbar } from "src/components/scrollbar";
import { MobileNavItem } from "./mobile-nav-item";
import { SideNavItem } from "../vertical-layout/side-nav-item";

interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  params?: Record<string, string>;
}

const renderItems = ({
  depth = 0,
  items,
  pathname,
  onClose,
}: {
  depth?: number;
  items: Item[];
  pathname?: string | null;
  onClose: () => void;
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
        params: item.params ?? {},
        onClose,
      }),
    [],
  );

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  params,
  pathname,
  onClose,
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  params: Record<string, string>;
  pathname?: string | null;
  onClose: () => void;
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path!) : false;
  const exactMatch = checkPath ? pathname === item.path : false;

  if (item.items) {
    acc.push(
      <MobileNavItem
        active={partialMatch}
        onClose={onClose}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        params={params}
        label={item.label}
        open={partialMatch}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
            onClose,
          })}
        </Stack>
      </MobileNavItem>,
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
        params={item.params}
        onClose={onClose}
      />,
    );
  }

  return acc;
};

interface MobileNavSectionProps {
  items?: Item[];
  pathname?: string | null;
  subheader?: string;
  action?: ReactNode;
  onClose: () => void;
}

export const MobileNavSection: FC<MobileNavSectionProps> = (props) => {
  const { items = [], pathname, onClose, subheader = "", ...other } = props;

  return (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
      }}
    >
      <Stack
        component="ul"
        spacing={0.5}
        sx={{
          listStyle: "none",
          m: 0,
          p: 0,
        }}
        {...other}
      >
        {subheader && (
          <Box
            component="li"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "var(--nav-section-title-color)",
              fontSize: 12,
              fontWeight: 700,
              lineHeight: 1.66,
              mb: 1,
              ml: 1,
              textTransform: "uppercase",
            }}
          >
            <span>{subheader}</span>
            <span onClick={onClose}>{props.action}</span>
          </Box>
        )}
        {renderItems({ items, pathname, onClose })}
      </Stack>
    </Scrollbar>
  );
};

MobileNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};
