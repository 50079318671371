import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { thumbzApi } from "src/services/thumbz-api";
import { IOrganizationUsageResponse } from "src/services/thumbz-base-api";
import { useOrganization } from "src/contexts/organization/OrganizationContext";

type IOrganizationData = IOrganizationUsageResponse;

interface UseOrganizationUsageHook {
  data: IOrganizationData | undefined;
  query: UseQueryResult<IOrganizationUsageResponse, Error>;
}

export const useOrganizationUsage = (): UseOrganizationUsageHook => {
  const { selectedOrganization } = useOrganization();
  const org_id = selectedOrganization?.org_id;

  const queryKey = queryKeys.organization.usage({
    org_id: org_id || "none",
  }).queryKey;

  const organizationUsageQuery = useQuery({
    queryFn: () =>
      thumbzApi.organization.organizationControllerGetOrganizationUsage(String(org_id)),
    queryKey,
    refetchInterval: 60 * 1000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    enabled: !!org_id,
  });

  return {
    data: organizationUsageQuery.data,
    query: organizationUsageQuery,
  };
};
