import type { FC } from 'react';
import Box from '@mui/material/Box';

import { keyframes } from 'tss-react';
import { useTheme } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

const breatheAnimation = keyframes`
  0% { transform: scale(0.9); }
  50% { transform: scale(1.1); }
  100% { transform: scale(0.9); }
`;

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Adicionado para centralizar horizontalmente
      }}
    >
      <ThumbzAnimatedLogo />
    </Box>
  </Box>
);

export const ThumbzAnimatedLogo: FC = () => {
  const { palette } = useTheme();

  const lottieSrc =
    palette.mode === 'dark'
      ? '/assets/lottie/thumbz-dark.json'
      : '/assets/lottie/thumbz-light.json';

  return (
    <Player
      autoplay
      loop
      src={lottieSrc}
      style={{ height: 48 * 4, width: 48 * 4 }}
    >
      {/* <Controls
          visible={true}
          buttons={['play', 'repeat', 'frame', 'debug']}
        /> */}
    </Player>
  );
};
