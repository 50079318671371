import React from "react";
import { useSelector } from "react-redux";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";
import { InstagramCarouselReorder } from "./instagram-carousel-reorder";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { Drawer, IconButton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { Scrollbar } from "./scrollbar";
import toast from "react-hot-toast";
import { Add } from "@mui/icons-material";
import { ApprovalAddFile } from "src/sections/approvals/approval-add-file";
import { RichTextEditor } from "./form/RichTextEditor";
import { ApprovalTypeFields } from "./approval/approval-type-fields";
import { IApprovalTypeFieldAtfContextEnum } from "src/services/thumbz-base-api";

interface IInstagramCarouselEditProps {}

export const InstagramCarouselEdit: React.FC<IInstagramCarouselEditProps> = () => {
  const { approvalItems } = useAppSelector((state) => state.approvalCreate);
  const dispatch = useAppDispatch();
  const selectedApproval = useSelector(approvalCreateSlice.selectors.selectEditItem);
  const openEditModal = useSelector(approvalCreateSlice.selectors.showEdit);
  const approval = useAppSelector(approvalCreateSlice.selectors.approval);
  const item_id = selectedApproval?.item_id;

  function handleUnselectToEdit(): void {
    dispatch(approvalCreateSlice.actions.unselectApprovalItem());
  }

  function handleEditDescription(description: string): void {
    if (selectedApproval?.item_id) {
      dispatch(
        approvalCreateSlice.actions.changeDescription({
          item_id: selectedApproval.item_id,
          description,
        }),
      );
    } else {
      toast.error("Não foi possível alterar a descrição");
    }
  }

  if (!selectedApproval) return null;

  const handleReorderAssets = (sourceIndex: number, destinationIndex: number) => {
    dispatch(
      approvalCreateSlice.actions.reorderApprovalItemAssets({
        item_id: selectedApproval.item_id,
        sourceIndex,
        destinationIndex,
      }),
    );
  };

  function handleCloseReorderModal() {
    console.log("Close reorder modal");
  }

  const _handleAddFile = async (files: FileList | null) => {
    if (files) {
      dispatch(
        approvalCreateSlice.actions.handleAddFileToAsset({
          files: files,
          item_id: selectedApproval.item_id,
        }),
      );
    }
  };

  return (
    <Drawer
      disableScrollLock
      anchor="right"
      onClose={handleUnselectToEdit}
      open={openEditModal}
      ModalProps={{
        BackdropProps: {
          // invisible: true,
        },
        sx: { zIndex: 1400 },
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: "100%",
          width: 600,
        },
      }}
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            pt: 2,
          }}
        >
          <Typography variant="h6">Detalhes</Typography>
          <IconButton color="inherit" onClick={handleUnselectToEdit}>
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <Stack spacing={2} sx={{ p: 3 }}>
          <RichTextEditor
            value={selectedApproval.item_description ?? ""}
            onChange={handleEditDescription}
          />
          {item_id && (
            <ApprovalTypeFields
              approvalTypeFields={approval?.approvalType?.approvalTypeFields || []}
              responses={approvalItems.find((item) => item.item_id === item_id)?.responses || []}
              onFieldChange={(props) => {
                dispatch(approvalCreateSlice.actions.updateResponses(props));
              }}
            />
          )}
        </Stack>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2} justifyContent="space-between">
              <Typography color="text.secondary" variant="overline">
                Arquivos
              </Typography>
              <ApprovalAddFile onAddFile={_handleAddFile} />
            </Stack>
            <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
              <InstagramCarouselReorder
                apv_id={selectedApproval.apv_id}
                assets={selectedApproval.assets}
                onReorder={handleReorderAssets}
                onClose={handleCloseReorderModal}
              />
            </Stack>
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};
