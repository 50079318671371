import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { FC, ReactNode } from "react";
import { OnboardingGuard } from "src/guards/onboarding-guard";
import { BillingGuard } from "src/guards/billing-guard";
import { OrganizationGuard } from "src/contexts/organization/OrganizationContext";
import { AuthGuard } from "src/guards/auth-guard";
import { MasterLayout } from "src/sections/master-layout";

export const Route = createFileRoute("/_authenticated")({
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
  beforeLoad: async ({ context, location, ...others }) => {
    const {
      data: { session },
    } = await context.auth.getSession();

    if (!session) {
      throw redirect({
        to: "/auth/login",
        search: { returnTo: location.href },
      });
    } else {
      const urlParams = new URLSearchParams(location.search);
      const returnTo = urlParams.get("returnTo");

      if (returnTo) {
        throw redirect({
          to: returnTo,
        });
      }
    }
  },
});

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <AuthGuard>
      <OrganizationGuard>
        <BillingGuard>
          <OnboardingGuard>
            <MasterLayout>{children}</MasterLayout>
          </OnboardingGuard>
        </BillingGuard>
      </OrganizationGuard>
    </AuthGuard>
  );
};
