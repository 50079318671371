import type { FC } from "react";
import type { ApexOptions } from "apexcharts";
import {
  Badge,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chart } from "../chart";
import { IDashboardResponseDto } from "src/services/thumbz-base-api";
import { UseQueryResult } from "@tanstack/react-query";

interface IProps {
  query: UseQueryResult<IDashboardResponseDto, Error>;
}

export const COLORS_CHART = {
  NOT_STARTED: "#9E9E9E",
  PENDING: "#FFC107",
  REQUESTING_CHANGES: "#F44336",
  APPROVED: "#4CAF50",
};

export const DashboardTotalApprovals: FC<IProps> = ({ query }) => {
  const theme = useTheme();

  // Calculando o total de aprovações para determinar a porcentagem
  const totalApprovals = query?.data?.approvals.total.value || 0; // Evitar divisão por zero

  const chartOptions: ApexOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [
      COLORS_CHART.NOT_STARTED,
      COLORS_CHART.PENDING,
      COLORS_CHART.REQUESTING_CHANGES,
      COLORS_CHART.APPROVED,
    ],
    fill: {
      opacity: 1,
    },
    labels: [
      query?.data?.approvals.notStarted.title || "Não Iniciado",
      query?.data?.approvals.pending.title || "Pendente",
      query?.data?.approvals.requestingChanges.title || "Solicitando Alterações",
      query?.data?.approvals.approved.title || "Aprovado",
    ],
    plotOptions: {
      radialBar: {
        track: {
          background: theme.palette.background.default,
        },
        dataLabels: {
          total: {
            show: true,
            label: "Total",
            formatter: () => `${totalApprovals}`, // Exibir o total no centro do gráfico
          },
        },
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  const chartSeries = [
    parseFloat(
      (((query?.data?.approvals.notStarted.value ?? 0) / totalApprovals) * 100).toFixed(2),
    ),
    parseFloat((((query?.data?.approvals.pending.value ?? 0) / totalApprovals) * 100).toFixed(2)),
    parseFloat(
      (((query?.data?.approvals.requestingChanges.value ?? 0) / totalApprovals) * 100).toFixed(2),
    ),
    parseFloat((((query?.data?.approvals.approved.value ?? 0) / totalApprovals) * 100).toFixed(2)),
  ];

  if (query.isLoading) {
    return (
      <Card sx={{ height: "100%" }}>
        <CardHeader
          title={<Skeleton variant="text" width={100} />}
          subheader={<Skeleton variant="text" width={100} />}
        />
        <Divider />
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item md={6} xs={12}>
            <Card sx={{ height: 300 }} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography color="textSecondary" variant="body2">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Typography variant="h5">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Divider sx={{ mt: 1 }} />
            <List disablePadding>
              {[0, 1, 2, 3].map((index) => (
                <ListItem
                  disableGutters
                  divider={index + 1 < 4}
                  key={index}
                  sx={{ display: "flex" }}
                >
                  <Box
                    sx={{
                      border: 3,
                      borderColor: "transparent",
                      borderRadius: "50%",
                      height: 16,
                      mr: 1,
                      width: 16,
                    }}
                  />
                  <Typography color="textSecondary" variant="body2">
                    <Skeleton variant="text" width={100} />
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="subtitle2">
                    <Skeleton variant="text" width={100} />
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={query?.data?.approvals.title}
        subheader={query?.data?.approvals.description}
      />
      <Divider />
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item md={6} xs={12}>
          <Chart height={300} options={chartOptions} series={chartSeries} type="radialBar" />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography color="textSecondary" variant="body2">
            {query?.data?.approvals.total.title}
          </Typography>
          <Typography variant="h5">{query?.data?.approvals.total.value}</Typography>
          <Divider sx={{ mt: 1 }} />
          <List disablePadding>
            {[
              {
                color: "#4CAF50",
                label: query?.data?.approvals.approved.title,
                value: query?.data?.approvals.approved.value,
              },
              {
                color: "#F44336",
                label: query?.data?.approvals.requestingChanges.title,
                value: query?.data?.approvals.requestingChanges.value,
              },
              {
                color: "#FFC107",
                label: query?.data?.approvals.pending.title,
                value: query?.data?.approvals.pending.value,
              },
              {
                color: "#9E9E9E",
                label: query?.data?.approvals.notStarted.title,
                value: query?.data?.approvals.notStarted.value,
              },
            ].map((item, index) => (
              <ListItem
                disableGutters
                divider={index + 1 < 4}
                key={item.label}
                sx={{ display: "flex" }}
              >
                <Box
                  sx={{
                    border: 3,
                    borderColor: item.color,
                    borderRadius: "50%",
                    height: 16,
                    mr: 1,
                    width: 16,
                  }}
                />
                <Typography color="textSecondary" variant="body2">
                  {item.label}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="subtitle2">{item.value}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};
