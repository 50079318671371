import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

interface FormSectionProps {
  title: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  actions: React.ReactNode;
  showCard?: boolean;
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  children,
  actions,
  showCard = true,
}) => {
  return (
    <Card elevation={showCard ? 1 : 0}>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Typography variant="h6">{title}</Typography>
            {description && (
              <Typography
                mt={1}
                variant="body2"
                color="text.secondary"
              >
                {description}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Stack spacing={3}>
              <Stack
                alignItems="flex-start"
                direction="row"
                spacing={2}
              >
                <Stack
                  spacing={2}
                  sx={{ flexGrow: 1 }}
                >
                  {children}
                </Stack>
              </Stack>

              <Stack
                alignItems="flex-end"
                justifyContent={'flex-end'}
                direction="row"
              >
                {actions}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FormSection;
