import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';

interface ConfirmModalProps {
  onConfirm: () => Promise<void>;
  onSuccessful?: () => void;
  onError?: () => void;
  onCancel?: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  isPending?: boolean;
  setIsPending?: React.Dispatch<React.SetStateAction<boolean>>;
  typeToConfirm?: string;
}

export function ConfirmModal(props: ConfirmModalProps) {
  const [isConfirmButtonEnabled, setIsConfirmButtonEnabled] = React.useState<boolean>(
    props.typeToConfirm ? false : true
  );

  const handleClose = () => {
    props.setOpen(false);
    props.onCancel?.();
  };

  async function handleConfirm() {
    try {
      await props.onConfirm();
      props.onSuccessful ? props.onSuccessful() : toast.success('Success!'); // Optional chaining here
    } catch (error) {
      toast.error('Algo deu errado'); // Inform the user of the error
      props.onError?.(); // Optional chaining here as well
    }
  }

  React.useEffect(() => {
    props.setOpen(props.open || false);
  }, [props, props.open]);

  function handleTypeToConfirmChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === props.typeToConfirm) {
      setIsConfirmButtonEnabled(true);
    } else {
      setIsConfirmButtonEnabled(false);
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title || 'Confirmation'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description || 'Você tem certeza?'}
        </DialogContentText>

        {props.typeToConfirm && (
          <>
            <DialogContentText
              sx={{ mt: 2 }}
              id="alert-dialog-description"
            >
              Type {'"'}
              <strong>{props.typeToConfirm}</strong>
              {'"'} to confirm.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              onChange={handleTypeToConfirmChange}
              label="Type here"
              type="text"
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={props.isPending}
          color="inherit"
          onClick={handleClose}
        >
          {props.cancelText || 'Cancelar'}
        </LoadingButton>
        <LoadingButton
          onClick={handleConfirm}
          autoFocus
          variant="contained"
          disabled={!isConfirmButtonEnabled}
          loading={props.isPending}
        >
          {props.isPending ? 'Carregando...' : props.confirmText || 'Confirmar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
