import { useState, useEffect, useMemo } from 'react';
import { Api, IAuthRefreshTokenRequestDto, ISessionResponseDto } from './thumbz-base-api';
import axios, { AxiosInstance } from 'axios';

export const AUTH_TOKEN = '@AUTH_TOKEN_V1';
export const AUTH_REFRESH_TOKEN = '@AUTH_REFRESH_TOKEN_V1';

const configureInterceptors = (apiInstance: AxiosInstance): void => {
  apiInstance.interceptors.request.use(
    async (config) => {
      const token = typeof window !== 'undefined' ? window.localStorage.getItem(AUTH_TOKEN) : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken =
          typeof window !== 'undefined' ? window.localStorage.getItem(AUTH_REFRESH_TOKEN) : null;

        if (!refreshToken) {
          return Promise.reject(error);
        }

        try {
          const body: IAuthRefreshTokenRequestDto = {
            refresh_token: refreshToken,
          };
          const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/refresh-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          });

          if (!response.ok) {
            return Promise.reject(error);
          }

          const {
            access_token,
            refresh_token: newRefreshToken,
            provider_refresh_token,
          } = (await response.json()) as ISessionResponseDto;

          if (typeof window !== 'undefined') {
            window.localStorage.setItem(AUTH_TOKEN, access_token);
            window.localStorage.setItem(
              AUTH_REFRESH_TOKEN,
              provider_refresh_token || newRefreshToken
            );
          }

          return apiInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      return Promise.reject(error.response?.data || error);
    }
  );
};

export const useThumbzApi = () => {
  const bearerToken =
    typeof window !== 'undefined' ? window.localStorage.getItem(AUTH_TOKEN) : null;

  const defaultInit = useMemo(
    () => ({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
    }),
    [bearerToken]
  );

  const [api, setApi] = useState<Api<unknown>>(new Api(defaultInit));

  useEffect(() => {
    const initializedApi = new Api(defaultInit);
    configureInterceptors(initializedApi.instance);
    setApi(initializedApi);
  }, [bearerToken, defaultInit]);

  return {
    api,
    token: bearerToken,
  };
};

const api = new Api({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  securityWorker: async () => {
    const token = typeof window !== 'undefined' ? window.localStorage.getItem(AUTH_TOKEN) : null;
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {};
  },
});

configureInterceptors(api.instance);

export const thumbzApi = api;
