import { Avatar, Skeleton, SvgIcon } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React from "react";
import { ImageCrop } from "./image-crop";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useThumbzApi } from "src/services/thumbz-api";
import { User01 } from "@untitled-ui/icons-react";
import { IPersonalization, IUpsertPersonalizationDto } from "src/services/thumbz-base-api";
import { RenderAvatar } from "../avatar/RenderAvatar";

interface PhotoFormProps {
  default_photo_url: string | undefined;
  onFormSuccessSubmit: ({ base64 }: { base64: string | undefined }) => void;
  photoSize?: number;
  loading: boolean;
}

const PhotoForm: React.FC<PhotoFormProps> = ({
  default_photo_url,
  onFormSuccessSubmit,
  photoSize = 100,
  loading,
}) => {
  const formik = useFormik({
    initialValues: {
      image: `${default_photo_url}`,
    },
    onSubmit: async (values, helpers): Promise<void> => {
      onFormSuccessSubmit({ base64: values.image });
    },
  });

  const key = `${default_photo_url}-${formik.values.image}-${photoSize}`;

  return (
    <Stack display={"flex"} alignItems="center" direction="row" key={`stack-${key}`} spacing={2}>
      <RenderAvatar
        key={`render-avatar-${key}`}
        src={formik.values.image || ""}
        fallbackSize={photoSize / 1.5}
        sx={{
          height: photoSize,
          width: photoSize,
          display: loading ? "none" : "flex",
        }}
      />
      <Skeleton
        key={`skeleton-${key}`}
        variant="rounded"
        width={photoSize}
        height={photoSize}
        sx={{ display: loading ? "block" : "none" }}
      />
      <ImageCrop
        key={`image-crop-${key}`}
        loading={loading}
        onImageChange={(img) => {
          formik.setFieldValue("image", img);
          formik.submitForm();
        }}
      />
    </Stack>
  );
};

export default PhotoForm;
