import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slider,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

interface ImageCropProps {
  onImageChange?: (image: string) => void;
  loading: boolean;
}

export const ImageCrop: React.FC<ImageCropProps> = ({ onImageChange, loading }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [scale, setScale] = useState(1);
  const [open, setOpen] = useState(false);
  let editor: AvatarEditor | null;

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleScaleChange = (value: number | number[]) => {
    setScale(value as number);
  };

  const handleSave = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      if (onImageChange) {
        onImageChange(canvas.toDataURL());
      }
    }
    setOpen(false);
  };

  const handleClick = () => {
    inputFileRef.current?.click();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <div>
        <LoadingButton loading={loading} color="inherit" size="small" onClick={handleClick}>
          Alterar
        </LoadingButton>
        <input
          ref={inputFileRef}
          id="fileUpload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Cortar imagem</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          {image && (
            <div>
              <AvatarEditor
                ref={(ref) => {
                  editor = ref;
                }}
                image={image as string}
                width={250}
                height={250}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
              />
              <Slider
                name="scale"
                value={scale}
                onChange={(_, value) => handleScaleChange(value)}
                min={1}
                max={2}
                sx={{
                  height: 8,
                }}
                step={0.01}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            style={{ color: "grey", fontWeight: "normal" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button onClick={handleSave}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
