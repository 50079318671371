import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { organizationSlice } from '../features/organization/organizationSlice';
import OnboardingForm from '../sections/onboarding/OnboardingForm';

interface OnboardingGuardProps {
  children: ReactNode;
}

export const OnboardingGuard: FC<OnboardingGuardProps> = (props) => {
  const { children } = props;

  const isDevelopment = import.meta.env.NODE_ENV === 'development';
  const hasCompletedOnboarding = useSelector(organizationSlice.selectors.hasCompletedOnboarding);

  if (!hasCompletedOnboarding && !isDevelopment) {
    return <OnboardingForm />;
  }

  return <>{children}</>;
};

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};
