import React from "react";
import { useSettings } from "src/hooks/use-settings";
import { HorizontalLayout } from "src/layouts/dashboard/horizontal-layout";
import { VerticalLayout } from "src/layouts/dashboard/vertical-layout";

interface IMasterLayoutProps {
  children: React.ReactNode;
}

export const MasterLayout: React.FC<IMasterLayoutProps> = ({ children }) => {
  const settings = useSettings();

  if (settings.layout === "horizontal")
    return <HorizontalLayout navColor={settings.navColor}>{children}</HorizontalLayout>;

  return <VerticalLayout navColor={settings.navColor}>{children}</VerticalLayout>;
};
