import { Link as TanstackLink, LinkProps as TanstackLinkProps } from "@tanstack/react-router";
import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

// Omitindo propriedades conflitantes, como `target` e `children`
interface LinkProps
  extends Omit<MUILinkProps, "children" | "target">,
    Omit<TanstackLinkProps, "children" | "target"> {
  children: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}

// Criar um componente Link estilizado que usa MUI e Tanstack
const StyledLink = styled(MUILink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const Link: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  return (
    // @ts-expect-error: `component` não é uma propriedade válida para `Link`
    <StyledLink component={TanstackLink} to={to} {...rest}>
      {children}
    </StyledLink>
  );
};
