import { Event } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
  SvgIcon,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import toast from "react-hot-toast";
import { useAppDispatch } from "src/app/hooks";
import { PageContainer } from "src/components/page-container";
import { queryKeys } from "src/config/query-key";
import { organizationThunks, organizationSlice } from "src/features/organization/organizationSlice";
import { usePageView } from "src/hooks/use-page-view";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { useThumbzApi } from "src/services/thumbz-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";

interface IQueryParams {
  returnTo?: string;
}

export const Route = createFileRoute("/convite/$token")({
  component: () => <Page />,
  validateSearch: (search: Record<string, unknown>): IQueryParams => {
    const returnTo = search.returnTo as string | undefined;
    return { returnTo };
  },
});

const Page = () => {
  const { returnTo } = Route.useSearch();
  const { api } = useThumbzApi();
  const { token } = Route.useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = {
    token: token.toString(),
  };

  usePageView();

  const inviteQuery = useQuery({
    queryKey: queryKeys.organization.getInvitationByToken({
      token: params.token,
    }).queryKey,
    queryFn: () => api.organization.organizationControllerGetInvitationByToken(params.token),
    retry: false,
  });

  const organization = inviteQuery.data?.organization;

  const joinOrganizationMutation = useMutation({
    mutationFn: () =>
      api.organization.organizationControllerAcceptInvite({
        orgi_invitation_code: params.token,
      }),
    onSuccess: (data) => {
      dispatch(organizationThunks.fetchUserOrganizations());
      dispatch(organizationSlice.actions.setSelectedOrganization(data));
      navigate({ from: "/convite/$token", to: returnTo || "/" });
    },
    onError(error) {
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
  });

  const Loading = () => (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        flexGrow: 1,
        flex: 1,
        mt: 10,
      }}
    >
      <CircularProgress variant="indeterminate" />
    </Box>
  );

  const Error = () => (
    <EmptyPlaceholder
      overlineText={"Ocorreu um erro ao tentar aceitar o convite."}
      customSubtitle={inviteQuery.error?.message}
      showButton={false}
    />
  );

  const Component = () => (
    <Card elevation={1}>
      <CardContent sx={{ textAlign: "center" }}>
        <Stack spacing={3}>
          <Stack spacing={1} sx={{ mx: "auto" }}>
            <Typography color="text.secondary" variant="overline">
              Você está convidado!
            </Typography>
            <Typography variant="subtitle1">
              Faça parte da organização <strong>{organization?.org_name}</strong>
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mx: "auto", mt: 3 }}
          >
            <LoadingButton
              onClick={() => joinOrganizationMutation.mutate()}
              loading={joinOrganizationMutation.isPending}
              startIcon={
                <SvgIcon>
                  <Event />
                </SvgIcon>
              }
              variant="contained"
            >
              Entrar na organização
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <>
      <PageContainer
        title={"Você recebeu um convite"}
        subtitle="O convite é uma forma de você participar de uma organização."
      >
        <Box
          component="main"
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid
              xs={12}
              md={12}
              sx={{
                p: {
                  xs: 4,
                  sm: 6,
                  md: 8,
                },
              }}
            >
              <Stack spacing={3}>
                {inviteQuery.isLoading && <Loading />}
                {inviteQuery.isError && <Error />}
                {inviteQuery.isSuccess && <Component />}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </PageContainer>
    </>
  );
};
