import React, { useState } from "react";
import { Stack, Skeleton, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import toast from "react-hot-toast";
import { ApprovalAddFile } from "../approvals/approval-add-file";
import {
  approvalEditSlice,
  approvalEditThunks,
} from "src/features/approval-edit/approvalEditSlice";
import { ApprovalItemAssetReorder } from "./approval-item-asset-reorder";
import { v4 } from "uuid";
import {
  ISliceItemAssetUpsertDto,
  ISliceItemUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { getMimetype, Mimetype } from "src/utils/get-mimetype";

interface IApprovalEditUpload {}

export const ApprovalEditUpload: React.FC<IApprovalEditUpload> = () => {
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const selectedApprovalItem = useAppSelector(approvalEditSlice.selectors.selectedApprovalItem);
  const isLoading = useAppSelector((state) => state.approvalEdit.status === "loading");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isFileDraggingAndDrop, setIsFileDraggingAndDrop] = useState<boolean>(false);
  const approvalItems = approval?.approvalItems || [];

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // Evita re-renderização contínua
    if (!isFileDraggingAndDrop) {
      setIsFileDraggingAndDrop(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // Evita que o estado seja alterado constantemente
    if (isFileDraggingAndDrop) {
      setIsFileDraggingAndDrop(false);
    }
  };

  const fileArrayToFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const filesArray = Array.from(event.dataTransfer.files);
    const fileList = fileArrayToFileList(filesArray);
    // dispatch(approvalEditSlice.actions.handleAddFile({ files: fileList, apv_id: approval.apv_id }));
    setIsFileDraggingAndDrop(false);
    toast.success("Arquivos adicionados com sucesso");
  };

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    const { destination, source, combine } = result;

    if (!destination && !combine) return;

    if (destination) {
      const payload = {
        sourceIndex: source.index,
        destinationIndex: destination.index,
      };
      dispatch(
        approvalEditSlice.actions.reorderApprovalItemAsset({
          destinationIndex: payload.destinationIndex,
          sourceIndex: payload.sourceIndex,
        }),
      );
    }
  };

  const _handleAddFile = async (files: FileList | null) => {
    if (!selectedApprovalItem || !files || files.length === 0 || !approval?.apv_id) {
      toast.error("Erro ao adicionar arquivo");
      return;
    }

    const _files = Array.from(files);
    const _assets: ISliceItemAssetUpsertDto[] = [
      ...selectedApprovalItem.approvalItemAsset.map((asset) => ({
        aia_id: asset.aia_id,
        aia_order: asset.aia_order,
        file: null,
        already_uploaded_url: asset.asset.ast_url,
        ast_id: asset.asset.ast_id,
        props: {
          mimetype: asset.asset.ast_mimetype as Mimetype,
        },
      })),
      ..._files.map((file) => ({
        aia_id: v4(),
        ast_id: v4(),
        aia_order: selectedApprovalItem.approvalItemAsset.length + 1,
        file: file,
        already_uploaded_url: null,
        props: {
          mimetype: getMimetype(file.type) as Mimetype,
        },
      })),
    ];

    const arg: ISliceItemUpsertDto = {
      apv_id: approval.apv_id,
      assets: _assets,
      isSynced: false,
      item_id: selectedApprovalItem.item_id,
      responses:
        selectedApprovalItem.responses?.map((response) => ({
          ...response,
          apv_id: approval.apv_id,
          item_id: selectedApprovalItem.item_id,
          atf_id: response.approvalTypeField.atf_id,
        })) || [],
      item_order: selectedApprovalItem.item_order,
      item_description: selectedApprovalItem.item_description ?? "",
    };

    dispatch(approvalEditThunks.upsertApprovalItem(arg));
  };

  function handleUploadAll() {
    for (const approvalItem of approvalItems) {
      // dispatch(approvalThunks.upsertApprovalItems(approvalItem));
    }
  }

  if (!approvalItems) {
    return <div>Approval items not found</div>;
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Arquivos</Typography>
        <ApprovalAddFile onAddFile={_handleAddFile} disabled={isLoading} />
      </Stack>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave} // Adiciona o evento de saída
        style={{
          position: "relative",
          border: isFileDraggingAndDrop ? "2px dashed #ccc" : "none",
          padding: isFileDraggingAndDrop ? "20px" : "0",
          textAlign: isFileDraggingAndDrop ? "center" : "initial",
        }}
      >
        {isFileDraggingAndDrop && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              zIndex: 10,
              pointerEvents: "none",
            }}
          >
            <span>Soltar Imagens</span>
          </div>
        )}
        {approvalItems.length === 0 && (
          <EmptyPlaceholder
            overlineText="Arraste e solte arquivos aqui"
            customSubtitle='ou clique no botão "+ Arquivos" para fazer upload'
            showButton={false}
          />
        )}
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => setIsDragging(true)}>
          <Droppable
            droppableId={`approvalItemAsset-${selectedApprovalItem?.item_id}`}
            direction="vertical"
            isCombineEnabled={false}
          >
            {(provided) => (
              <Stack spacing={1} ref={provided.innerRef} {...provided.droppableProps}>
                {selectedApprovalItem?.approvalItemAsset.map((itemAsset, index) => {
                  return (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided, snapshot) => (
                        <Stack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging || snapshot.combineTargetFor ? 0.5 : 1,
                          }}
                        >
                          {!isLoading ? (
                            <ApprovalItemAssetReorder itemAsset={itemAsset} />
                          ) : (
                            <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
                          )}
                        </Stack>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Stack>
  );
};

{
  /* <ApprovalItemAssetEdit itemAsset={itemAsset} /> */
}
