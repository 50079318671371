import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface ILoadingButtonProps extends ButtonProps {
  loading: boolean;
}

export const LoadingButton: React.FC<ILoadingButtonProps> = ({ loading, ...props }) => {
  return <Button {...props} disabled={loading} />;
};
