import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { AuthClassicLayout } from "../layouts/auth/classic-layout";

interface IQueryParams {
  returnTo?: string;
}

export const Route = createFileRoute("/auth")({
  validateSearch: (search: Record<string, unknown>): IQueryParams => {
    const returnTo = search.returnTo as string | undefined;
    return { returnTo };
  },
  component: () => (
    <AuthClassicLayout>
      <Outlet />
    </AuthClassicLayout>
  ),
  beforeLoad: async ({ context, location }) => {
    const {
      data: { session },
    } = await context.auth.getSession();

    // Apenas redireciona se a sessão estiver confirmada
    if (session) {
      const searchParams = location.search as unknown as IQueryParams;
      const returnTo = searchParams.returnTo || "/";
      throw redirect({ to: returnTo });
    }
  },
});
