import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useAppDispatch } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { useThumbzApi } from "src/services/thumbz-api";
import { IOrganizationUpsertDto } from "src/services/thumbz-base-api";

interface CreateOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateOrganizationDialog: React.FC<CreateOrganizationDialogProps> = ({
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { api } = useThumbzApi();

  const createOrganizationMutation = useMutation({
    mutationFn: api.organization.organizationControllerUpsert,
    onSuccess(data) {
      dispatch(organizationSlice.actions.updateSelectedOrganization(data));
      toast.success("Organização criada com sucesso");

      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.some(
            (key) => typeof key === "string" && key.includes("organization"),
          );
        },
      });
      handleClose();
    },
    onError(error) {
      toast.error("Erro ao criar organização");
    },
  });

  function handleClose() {
    formik.resetForm();
    onClose();
  }

  const formik = useFormik<IOrganizationUpsertDto>({
    initialValues: {
      org_id: undefined,
      org_name: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Nome da organização é obrigatório"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createOrganizationMutation.mutateAsync(values);
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Criar Nova Organização</DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          Ao criar uma nova organização, você será redirecionado para a página de pagamento.
          <br />
          <br />
          Na Thumbz, cada organização é cobrada separadamente, caso você queira apenas criar um novo
          cliente, crie um workspace em uma organização já existente.
        </Alert>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label="Nome da Organização"
              name="org_name"
              value={formik.values.org_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.org_name && Boolean(formik.errors.org_name)}
              helperText={formik.touched.org_name && formik.errors.org_name}
              margin="dense"
            />
          </form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          onClick={formik.submitForm}
          loading={formik.isSubmitting || createOrganizationMutation.isPending}
          variant="contained"
          color="primary"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Criar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
