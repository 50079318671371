import React from "react";
import { Stack } from "@mui/system";
import { IApprovalTypeField, IUpsertApprovalResponseDto } from "src/services/thumbz-base-api";
import { FieldRenderer, IHandleChange } from "./field-renderer";

interface ApprovalTypeFieldsProps {
  approvalTypeFields: IApprovalTypeField[];
  responses: IUpsertApprovalResponseDto[];
  onFieldChange: (props: IHandleChange) => void;
  readOnly?: boolean;
}

export const ApprovalTypeFields: React.FC<ApprovalTypeFieldsProps> = ({
  approvalTypeFields,
  responses,
  onFieldChange,
  readOnly = false,
}) => {
  const handleChange = (props: IHandleChange) => {
    const { atf_id, apr_id } = props;
    const field = approvalTypeFields.find((f) => f.atf_id === atf_id);

    if (field) {
      onFieldChange(props);
    }
  };

  return (
    <Stack spacing={2}>
      {responses.map((response) => {
        const field = approvalTypeFields.find((f) => f.atf_id === response.atf_id);
        if (field) {
          return (
            <FieldRenderer
              key={response.apr_id}
              field={response}
              type={field}
              handleChange={handleChange}
              readOnly={readOnly}
            />
          );
        }
        return null;
      })}
    </Stack>
  );
};
