import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { getMimetype, Mimetype } from "../../utils/get-mimetype";
import { RootState } from "../../app/store";
import { thumbzApi } from "../../services/thumbz-api";
import {
  IApprovalItemAssetUpsertDto,
  IAsset,
  IApprovalItemUpsertDto,
  IApproval,
  ICustomer,
  IApprovalItemRevisionAirStatusEnum,
  IApprovalStage,
  IApprovalChangeStatusDto,
  IApprovalItem,
  IUpsertApprovalResponseDto,
  IApprovalTypeFieldAtfContextEnum,
} from "../../services/thumbz-base-api";
import { createFormData } from "../../utils/create-form-data";
import { IStatusIcon } from "src/components/avatar/StatusIcon";
import { IHandleChange } from "src/components/approval/field-renderer";

interface IHandleAddFile {
  files: FileList;
  apv_id: string;
}

export interface ISliceItemAssetUpsertDto extends Omit<IApprovalItemAssetUpsertDto, "file"> {
  props?: {
    mimetype: Mimetype;
  };
  file: File | null;
  ast_id: string;
  already_uploaded_url: string | null;
  uploaded_asset?: IAsset;
}

export interface ISliceItemUpsertDto extends Omit<IApprovalItemUpsertDto, "assets" | "item_order"> {
  responses: IUpsertApprovalResponseDto[];
  assets: ISliceItemAssetUpsertDto[];
  item_id: string;
  item_order: number;
  isSynced: boolean; // Propriedade para identificar se o item está sincronizado com o backend
}

interface IOperationUpsert {
  payload: ISliceItemUpsertDto;
}

interface IOperationDelete {
  item_id: string;
}

interface IOperationMoveAsset {
  payload: { old_item_id: string; new_item_id: string; asset_id: string };
}

interface IQueueSync<T> {
  payload: T;
}

export type IResponseObj = {
  [atf_id: string]: {
    value: string | number | string[] | null | boolean;
    apr_id: string | undefined;
  };
};

export interface ApprovalState {
  approval: IApproval | null;
  approvalItems: ISliceItemUpsertDto[];
  queue_sync: Array<
    IQueueSync<IOperationUpsert> | IQueueSync<IOperationDelete> | IQueueSync<IOperationMoveAsset>
  >;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  activeStep: number;
  edit: { item_id: string | null };
  validationErrors: { atf_id: string; message: string }[] | null;
}

const initialState: ApprovalState = {
  approval: null,
  approvalItems: [],
  activeStep: 0,
  queue_sync: [],
  status: "idle",
  error: null,
  edit: { item_id: null },
  validationErrors: null,
};

export enum StepValue {
  Approval = "approvals",
  Approvers = "approvers",
  Complements = "complements",
  Revision = "revision",
}

export interface IApprovalEditTable {
  item_id: string | null;
  media: string;
  media_name: string;
  status: {
    customer: ICustomer;
    statusLabel: string;
    statusIcon: IStatusIcon;
    statusEnum: IApprovalItemRevisionAirStatusEnum;
  }[];
  stages: {
    stage: IApprovalStage;
    statusLabel: string;
    statusColor: string;
  };
}

export const approvalModalSteps = [
  {
    label: "Arquivos",
    value: StepValue.Approval,
    description:
      "Adicione e organize os arquivos a serem aprovados, como posts, stories e carrosséis.",
  },
  {
    label: "Aprovadores",
    value: StepValue.Approvers,
    description: "Defina as pessoas responsáveis por revisar e aprovar o conteúdo.",
  },
  {
    label: "Complemetos",
    value: StepValue.Complements,
    description: "Adicione informações adicionais para complementar a aprovação.",
  },
  {
    label: "Revisão",
    value: StepValue.Revision,
    description: "Revise todas as informações antes de enviar para aprovação.",
  },
];

export const fetchApprovalItems = createAsyncThunk(
  "approvalItems/fetchApprovalItems",
  async ({ apv_id }: { apv_id: string }) => {
    return await thumbzApi.approvalItem.approvalItemControllerList({
      apv_id,
    });
  },
);

export const fetchApproval = createAsyncThunk(
  "approval/fetchApproval",
  async ({ apv_id }: { apv_id: string }) => {
    return await thumbzApi.approval.approvalControllerGet(apv_id);
  },
);

export const upsertApprovalItems = createAsyncThunk(
  "approvalItems/upsertApprovalItems",
  async (data: ISliceItemUpsertDto, { dispatch, rejectWithValue }) => {
    try {
      const formData = createFormData({
        apv_id: data.apv_id,
        assets: data.assets.map((asset) => ({
          aia_id: String(asset.aia_id),
          aia_order: asset.aia_order,
          file: asset.file || undefined,
          props: {
            mimetype: asset.props?.mimetype || getMimetype(asset.file?.type),
          },
        })),
        item_description: data.item_description,
        item_id: data.item_id,
        item_order: data.item_order,
      });
      const response = await thumbzApi.instance.post<IApprovalItemUpsertDto>(
        `/approval-item/upsert`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      // if response is not successful, reject with the error message
      if (response.status !== 200) {
        return rejectWithValue("Erro ao atualizar os itens no backend");
      }

      dispatch(approvalCreateSlice.actions.markItemAsSynced({ item_id: data.item_id }));
      return response;
    } catch (error) {
      return rejectWithValue("Erro ao atualizar os itens no backend");
    }
  },
);

export const upsertResponseItems = createAsyncThunk(
  "approvalItems/upsertResponseItems",
  async ({ item_id }: ISliceItemUpsertDto, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const approvalItem = state.approvalCreate.approvalItems.find(
        (item) => item.item_id === item_id,
      );
      if (!approvalItem) {
        return;
      }

      const promises = approvalItem.responses.map((response) =>
        thumbzApi.approvalResponse.approvalResponseControllerUpsertApprovalResponse(response),
      );

      await Promise.all(promises);
    } catch {
      return rejectWithValue("Erro ao atualizar as respostas no backend");
    }
  },
);

export const removeAssetFromApprovalItem = createAsyncThunk(
  "approvalItems/removeAssetFromApprovalItem",
  async (
    { apv_id, ast_index, isSynced }: { apv_id: string; ast_index: number; isSynced: boolean },
    { getState, rejectWithValue },
  ) => {
    if (!isSynced) {
      // Se o asset não estiver sincronizado, retorne os dados sem chamar o backend
      return { apv_id, ast_index };
    }

    try {
      const state = getState() as RootState;
      const approvalItem = state.approvalCreate.approvalItems.find(
        (item) => item.apv_id === apv_id,
      );
      const assetToDelete = approvalItem?.assets[ast_index];

      if (!assetToDelete || assetToDelete?.aia_id === undefined) {
        return rejectWithValue("Asset não encontrado para exclusão.");
      }

      // Realiza a exclusão no backend usando o ID do asset
      await thumbzApi.approvalItem.approvalItemControllerDeleteItemAsset({
        aia_id: assetToDelete.aia_id,
      });

      return { apv_id, ast_index }; // Retorna os dados necessários para atualizar o estado local
    } catch (error) {
      return rejectWithValue("Erro ao excluir o asset no backend");
    }
  },
);

export const changeApprovalStatus = createAsyncThunk(
  "approvalItems/changeApprovalStatus",
  async (data: IApprovalChangeStatusDto) => {
    return await thumbzApi.approval.approvalControllerChangeStatus(data);
  },
);

export const goToStep1 = createAsyncThunk(
  "approvalItems/goToStep1",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const approvalItems = state.approvalCreate.approvalItems;

      // Filter items that are not synced
      const unsyncedItems = approvalItems.filter((item) => !item.isSynced);

      // Create an array of promises for dispatching upsertApprovalItems
      const promises = unsyncedItems.map((item) => dispatch(upsertApprovalItems(item)));

      // Wait for the first promises to resolve
      await Promise.all(promises);

      const promises2 = approvalItems.map((item) => dispatch(upsertResponseItems(item)));

      // Wait for the second promises to resolve
      await Promise.all(promises2);

      return "success"; // Caso tudo funcione corretamente
    } catch (error) {
      // Captura qualquer erro ocorrido durante o processo e rejeita com uma mensagem de erro customizada
      return rejectWithValue("Ocorreu um erro durante o envio dos arquivos.");
    }
  },
);

export const goToStep2 = createAsyncThunk(
  "approvalItems/goToStep2",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const approvalItems = state.approvalCreate.approvalItems;

      // Filter items that are not synced
      const unsyncedItems = approvalItems.filter((item) => !item.isSynced);

      // Create an array of promises for dispatching upsertApprovalItems
      const promises = unsyncedItems.map((item) => dispatch(upsertApprovalItems(item)));

      // Wait for all promises to resolve
      await Promise.all(promises);

      return "success"; // Caso tudo funcione corretamente
    } catch (error) {
      // Captura qualquer erro ocorrido durante o processo e rejeita com uma mensagem de erro customizada
      return rejectWithValue("Ocorreu um erro durante o envio dos arquivos.");
    }
  },
);

export const goToStep3 = createAsyncThunk(
  "approvalItems/goToStep3",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { approval } = (getState() as RootState).approvalCreate;
      if (!approval) {
        return rejectWithValue("Aprovação não encontrada.");
      }

      const promises = approval.responses.map((response) => {
        return thumbzApi.approvalResponse.approvalResponseControllerUpsertApprovalResponse({
          apr_value: response.apr_value,
          apv_id: approval.apv_id,
          atf_id: response.approvalTypeField.atf_id,
          apr_id: response.apr_id,
          item_id: undefined,
        });
      });

      return await Promise.all(promises);
    } catch (error) {
      // Captura qualquer erro ocorrido durante o processo e rejeita com uma mensagem de erro customizada
      return rejectWithValue("Ocorreu um erro durante o envio dos arquivos.");
    }
  },
);

export const goToStep4 = createAsyncThunk(
  "approvalItems/goToStep4",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const approvalItems = state.approvalCreate.approvalItems;

      await fetchApprovalItems({ apv_id: approvalItems[0].apv_id });

      return "success"; // Caso tudo funcione corretamente
    } catch (error) {
      // Captura qualquer erro ocorrido durante o processo e rejeita com uma mensagem de erro customizada
      return rejectWithValue("Ocorreu um erro durante o envio dos arquivos.");
    }
  },
);

export const deleteApprovalItem = createAsyncThunk(
  "approvalItems/deleteApprovalItem",
  async ({ item_id }: { item_id: string }, { rejectWithValue, getState }) => {
    try {
      const { approvalCreate } = getState() as RootState;
      const itemTobeDeleted = approvalCreate.approvalItems.find((item) => item.item_id === item_id);

      if (itemTobeDeleted?.isSynced) {
        await thumbzApi.approvalItem.approvalItemControllerDelete({ item_id });
      }

      return item_id;
    } catch (error) {
      return rejectWithValue("Erro ao excluir o item no backend");
    }
  },
);

export const approvalCreateSlice = createSlice({
  name: "approvalCreate",
  initialState,
  reducers: {
    updateResponses: (state, action: PayloadAction<IHandleChange>) => {
      const { atf_id, apr_value, apr_id, apv_id, item_id } = action.payload;

      if (item_id) {
        state.approvalItems = state.approvalItems.map((item) => {
          if (item.item_id === item_id) {
            const response = item.responses.find((response) => response.atf_id === atf_id);
            if (response) {
              response.apr_value = apr_value;
              response.apr_id = apr_id;
            } else {
              item.responses.push({
                apr_value,
                atf_id,
                apr_id,
                item_id,
                apv_id,
              });
            }
          }
          return item;
        }, state.approvalItems);
      } else {
        state.approval?.responses?.forEach((response) => {
          if (response.approvalTypeField.atf_id === atf_id) {
            response.apr_value = apr_value;
          }
        });
      }

      state.validationErrors = null;
    },
    setApproval: (state, action: PayloadAction<IApproval>) => {
      state.approval = action.payload;
    },
    // Assets
    reorderAssetsInsideApprovalItem: (
      state,
      action: PayloadAction<{
        approvalItemIndex: number;
        sourceIndex: number;
        destinationIndex: number;
      }>,
    ) => {
      const { approvalItemIndex, sourceIndex, destinationIndex } = action.payload;

      const approvalItem = state.approvalItems[approvalItemIndex];
      const [movedAssets] = approvalItem.assets.splice(sourceIndex, 1);

      approvalItem.assets.splice(destinationIndex, 0, movedAssets);
    },
    handleAddFile: (state, action: PayloadAction<IHandleAddFile>) => {
      const { files } = action.payload;
      const approval = state.approval;

      const newApprovalItems: ISliceItemUpsertDto[] = Array.from(files).map((file, i) => {
        const item_id = uuidv4();
        const _response =
          approval?.approvalType.approvalTypeFields
            ?.filter(
              ({ atf_context }) => atf_context === IApprovalTypeFieldAtfContextEnum.APPROVAL_ITEM,
            )
            .map((field) => {
              const response: IUpsertApprovalResponseDto = {
                apr_value: null,
                apv_id: approval.apv_id,
                atf_id: field.atf_id,
                apr_id: uuidv4(),
                item_id: item_id,
              };
              return response;
            }) || [];

        return {
          apv_id: action.payload.apv_id,
          item_order: state.approvalItems.length + i,
          isSynced: false,
          assets: [
            {
              aia_id: uuidv4(),
              ast_id: uuidv4(),
              aia_order: i,
              file,
              already_uploaded_url: null,
              props: {
                mimetype: getMimetype(file.type),
              },
            },
          ],
          item_description: undefined,
          item_id: item_id,
          responses: _response,
        };
      });

      state.approvalItems.push(...newApprovalItems);
    },

    handleAddFileToAsset: (state, action: PayloadAction<{ item_id: string; files: FileList }>) => {
      const { item_id, files } = action.payload;
      const approvalItem = state.approvalItems.find((item) => item.item_id === item_id);
      if (approvalItem) {
        const newAssets = Array.from(files).map((file, i) => ({
          aia_id: uuidv4(),
          ast_id: uuidv4(),
          aia_order: i,
          file,
          already_uploaded_url: null,
          props: {
            mimetype: getMimetype(file.type),
          },
        }));
        approvalItem.assets.push(...newAssets);
        approvalItem.isSynced = false;
      }
    },

    // Approval Items
    reorderApprovalItems: (
      state,
      action: PayloadAction<{
        sourceIndex: number;
        destinationIndex: number;
      }>,
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;

      const [movedApprovalItem] = state.approvalItems.splice(sourceIndex, 1);
      state.approvalItems.splice(destinationIndex, 0, movedApprovalItem);

      for (let i = 0; i < state.approvalItems.length; i++) {
        state.approvalItems[i].item_order = i + 1;
        state.approvalItems[i].isSynced = false;
      }
    },
    reorderApprovalItemAssets: (
      state,
      action: PayloadAction<{
        item_id: string;
        sourceIndex: number;
        destinationIndex: number;
      }>,
    ) => {
      const { item_id, sourceIndex, destinationIndex } = action.payload;

      const approvalItemIndex = state.approvalItems.findIndex((item) => item.item_id === item_id);
      const approvalItem = state.approvalItems[approvalItemIndex];

      const [movedAsset] = approvalItem.assets.splice(sourceIndex, 1);
      approvalItem.assets.splice(destinationIndex, 0, movedAsset);
      for (let i = 0; i < approvalItem.assets.length; i++) {
        approvalItem.assets[i].aia_order = i;
      }
      approvalItem.isSynced = false;
    },
    mergeApprovalItems: (
      state,
      action: PayloadAction<{
        sourceIndex: number;
        combineIndex: number;
        destinationIndex: number;
      }>,
    ) => {
      const { sourceIndex, combineIndex, destinationIndex } = action.payload;

      if (sourceIndex === combineIndex) {
        return state; // No operation if sourceIndex is the same as combineIndex
      }

      const sourceApprovalItem = state.approvalItems[sourceIndex];
      const approvalItemToBeCombined = state.approvalItems[combineIndex];

      // Garantir que a descrição não seja 'undefined' e substituí-la por string vazia caso esteja ausente
      const sourceDescription = sourceApprovalItem.item_description || "";
      const combineDescription = approvalItemToBeCombined.item_description || "";

      const combinedAssets = [...sourceApprovalItem.assets, ...approvalItemToBeCombined.assets];
      const combinedItem_description = `${sourceDescription} ${combineDescription}`.trim(); // Usa trim para remover espaços desnecessários

      const combinedApprovalItem: ISliceItemUpsertDto = {
        apv_id: sourceApprovalItem.apv_id,
        item_id: sourceApprovalItem.item_id,
        assets: combinedAssets,
        item_order: sourceApprovalItem.item_order,
        item_description: combinedItem_description || "", // Adiciona uma descrição padrão se continuar vazio
        isSynced: sourceApprovalItem.isSynced && approvalItemToBeCombined.isSynced, // Se qualquer item não estiver sincronizado, o combinado também não estará
        responses: [],
      };

      // Remove os itens combinados da lista
      state.approvalItems.splice(sourceIndex, 1);
      state.approvalItems.splice(combineIndex > sourceIndex ? combineIndex - 1 : combineIndex, 1);

      // Adiciona o item combinado na posição de destino
      state.approvalItems.splice(destinationIndex, 0, combinedApprovalItem);

      // Adicionar na queue_sync, se necessário
    },
    unmergeAllFromSingleApprovalItem: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const approvalItemToUnmerge = state.approvalItems[index];
      const splitApprovalItems: ISliceItemUpsertDto[] = approvalItemToUnmerge.assets.map(
        (assets, index) => {
          const item_id = index === 0 ? approvalItemToUnmerge.item_id : uuidv4();
          const item_order = index === 0 ? approvalItemToUnmerge.item_order : index + 1;
          const isSynced = index === 0 ? approvalItemToUnmerge.isSynced : false;

          return {
            assets: [assets],
            item_id: item_id,
            item_order: item_order,
            isSynced: isSynced,
            apv_id: approvalItemToUnmerge.apv_id,
            item_description: approvalItemToUnmerge.item_description,
            responses: [],
          };
        },
      );
      state.approvalItems.splice(index, 1, ...splitApprovalItems);
    },
    unmergeSingleAssetsFromApprovalItem: (
      state,
      action: PayloadAction<{ approvalItemIndex: number; assetsIndex: number }>,
    ) => {
      const { approvalItemIndex, assetsIndex } = action.payload;
      const approvalItemToUnmerge: ISliceItemUpsertDto = state.approvalItems[approvalItemIndex];
      const singleAssetsApprovalItem: ISliceItemUpsertDto = {
        apv_id: approvalItemToUnmerge.apv_id,
        item_id: approvalItemToUnmerge.item_id || uuidv4(),
        item_order: approvalItemToUnmerge.item_order,
        assets: [approvalItemToUnmerge.assets[assetsIndex]],
        item_description: approvalItemToUnmerge.item_description,
        isSynced: approvalItemToUnmerge.isSynced, // Herda o status de sincronização do item original
        responses: [],
      };
      approvalItemToUnmerge.assets.splice(assetsIndex, 1);
      if (approvalItemToUnmerge.assets.length === 0) {
        state.approvalItems.splice(approvalItemIndex, 1, singleAssetsApprovalItem);
      } else {
        state.approvalItems.splice(approvalItemIndex + 1, 0, singleAssetsApprovalItem);
      }
    },
    changeDescription: (state, action: PayloadAction<{ item_id: string; description: string }>) => {
      const { item_id, description } = action.payload;
      const approvalItem = state.approvalItems.find((item) => item.item_id === item_id);
      if (approvalItem) {
        approvalItem.item_description = description;
        approvalItem.isSynced = false;
      }
    },
    removeApprovalItem: (state, action: PayloadAction<{ item_id: string }>) => {
      const { item_id } = action.payload;
      const index = state.approvalItems.findIndex((item) => item.item_id === item_id);

      if (index !== -1) {
        const item = state.approvalItems[index];
        if (item.isSynced) {
          // Adiciona o ID na fila de exclusão
          state.queue_sync.push({ payload: { item_id } });
        } else {
          // Remove localmente se o item não estiver sincronizado com o backend
          state.approvalItems.splice(index, 1);
        }
      }
    },
    markItemAsSynced: (state, action: PayloadAction<{ item_id: string }>) => {
      const { item_id } = action.payload;
      const item = state.approvalItems.find((item) => item.item_id === item_id);
      if (item) {
        item.isSynced = true;
      }
    },
    selectApprovalItem: (state, action: PayloadAction<{ item_id: string }>) => {
      const { item_id } = action.payload;

      state.edit.item_id = item_id;
    },
    unselectApprovalItem: (state) => {
      state.edit.item_id = null;
    },

    // Page Step control
    nextStep: (state) => {
      if (state.activeStep === approvalModalSteps.length - 1) {
        return;
      }

      const nextStep = state.activeStep + 1;
      state.activeStep = nextStep;
    },
    backStep: (state) => {
      if (state.activeStep === 0) {
        return;
      }
      state.activeStep -= 1;
    },

    reset: (state) => {
      state.approvalItems = [];
      state.activeStep = 0;
      state.queue_sync = [];
      state.status = "idle";
      state.error = null;
      state.edit.item_id = null;
    },

    changeStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeAssetFromApprovalItem.fulfilled, (state, action) => {
        const { apv_id, ast_index } = action.payload;
        const approvalItem = state.approvalItems.find((item) => item.apv_id === apv_id);
        if (approvalItem) {
          approvalItem.assets.splice(ast_index, 1); // Remove o asset localmente após a exclusão bem-sucedida
        }
      })
      .addCase(removeAssetFromApprovalItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });

    builder
      // Exclusão do item no backend e localmente
      .addCase(deleteApprovalItem.fulfilled, (state, action) => {
        const item_id = action.payload;
        const index = state.approvalItems.findIndex((item) => item.item_id === item_id);
        if (index !== -1) {
          state.approvalItems.splice(index, 1); // Remove o item localmente após a exclusão bem-sucedida
        }
      })
      .addCase(deleteApprovalItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });

    builder
      .addCase(fetchApprovalItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApprovalItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        const converted = bulkConvertApprovalItemUpsertDtoToSliceItemUpsertDto(action.payload);
        state.approvalItems = converted;
      })
      .addCase(fetchApprovalItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      .addCase(upsertApprovalItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(upsertApprovalItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        // const upsertedApprovalItem = convertApprovalItemUpsertDtoToSliceItemUpsertDto(
        //   action.payload
        // );
        // const index = state.approvalItems.findIndex(
        //   (item) => item.tmp_id === upsertedApprovalItem.tmp_id
        // );
        // state.approvalItems.splice(index, 1, upsertedApprovalItem);
      })
      .addCase(upsertApprovalItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      // change status
      .addCase(changeApprovalStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeApprovalStatus.fulfilled, (state, payload) => {
        state.status = "succeeded";
      })
      .addCase(changeApprovalStatus.rejected, (state, action) => {
        state.status = "failed";
        toast.error(String(action.error.message));
        state.error = action.error.message || null;
      })

      .addCase(goToStep1.pending, (state) => {
        state.status = "loading";
      })
      .addCase(goToStep1.fulfilled, (state) => {
        state.status = "succeeded";
        state.activeStep = 1;
      })
      .addCase(goToStep1.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      .addCase(goToStep2.pending, (state) => {
        state.status = "loading";
      })
      .addCase(goToStep2.fulfilled, (state) => {
        state.status = "succeeded";
        state.activeStep = 2;
      })
      .addCase(goToStep2.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      .addCase(goToStep3.pending, (state) => {
        state.status = "loading";
      })
      .addCase(goToStep3.fulfilled, (state) => {
        state.status = "succeeded";
        state.activeStep = 3;
      })
      .addCase(goToStep3.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      .addCase(goToStep4.pending, (state) => {
        state.status = "loading";
      })
      .addCase(goToStep4.fulfilled, (state) => {
        state.status = "succeeded";
        state.activeStep = 4;
      })
      .addCase(goToStep4.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })

      // fetchApproval
      .addCase(fetchApproval.pending, (state) => {
        state.status = "loading";
      })

      .addCase(fetchApproval.fulfilled, (state, action) => {
        // reset state
        state.status = "succeeded";
        state.approval = action.payload;
      })

      .addCase(fetchApproval.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
  selectors: {
    selectApprovalItems: (state) => state.approvalItems,
    selectAllSteps: (state) => approvalModalSteps,
    selectActiveStep: (state) => state.activeStep,
    selectIsBackActiveEnabled: (state) => state.activeStep > 0,
    selectIsNextActiveEnabled: (state) => state.activeStep < approvalModalSteps.length - 1,
    approval: (state) => state.approval,

    // Edit
    showEdit: (state) => !!state.edit.item_id,
    selectEditItem: (state) =>
      state.approvalItems.find((item) => item.item_id === state.edit.item_id),
  },
});

export const approvalThunks = {
  fetchApprovalItems,
  upsertApprovalItems,
  changeApprovalStatus,
  fetchApproval,
  deleteApprovalItem,
  removeAssetFromApprovalItem,
  saveAllResponses: goToStep3,
};

export const approvalCreateReducer = approvalCreateSlice.reducer;

function convertApprovalItemUpsertDtoToSliceItemUpsertDto(
  approvalItem: IApprovalItem,
): ISliceItemUpsertDto {
  const _responses =
    approvalItem?.responses
      ?.filter(
        ({ approvalTypeField }) =>
          approvalTypeField.atf_context === IApprovalTypeFieldAtfContextEnum.APPROVAL_ITEM,
      )
      .map((response) => ({
        apr_value: response.apr_value,
        apv_id: approvalItem.approval.apv_id,
        atf_id: response.approvalTypeField.atf_id,
        apr_id: response.apr_id,
        item_id: approvalItem.item_id,
      })) || [];

  return {
    item_description: approvalItem.item_description || undefined,
    item_id: approvalItem.item_id || uuidv4(),
    item_order: approvalItem.item_order,
    apv_id: approvalItem.approval.apv_id,
    isSynced: true,
    responses: _responses,
    assets: approvalItem.approvalItemAsset
      .sort((a, b) => a.aia_order - b.aia_order)
      .map((itemAsset) => ({
        ...itemAsset,
        file: null,
        ast_id: uuidv4(),
        props: {
          mimetype: getMimetype(itemAsset.asset.ast_mimetype),
        },
        already_uploaded_url: itemAsset.asset.ast_url,
        uploaded_asset: itemAsset.asset,
      })),
  };
}

function bulkConvertApprovalItemUpsertDtoToSliceItemUpsertDto(
  approvalItems: IApprovalItem[],
): ISliceItemUpsertDto[] {
  return approvalItems.map((approvalItem) =>
    convertApprovalItemUpsertDtoToSliceItemUpsertDto(approvalItem),
  );
}
