import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "../theme";
import { RTL } from "../components/rtl";
import React from "react";
import { Settings } from "src/types/settings";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/plus-jakarta-sans/600.css";
import "@fontsource/plus-jakarta-sans/700.css";

interface ThemeWrapperProps {
  children: React.ReactNode;
  settings: Settings;
}

export function ThemeWrapper({ children, settings }: ThemeWrapperProps) {
  const theme = createTheme({
    colorPreset: settings.colorPreset,
    contrast: settings.contrast,
    direction: settings.direction,
    paletteMode: settings.paletteMode,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        {children}
      </RTL>
    </ThemeProvider>
  );
}
