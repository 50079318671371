import { createFileRoute } from "@tanstack/react-router";
import { PageContainer } from "src/components/page-container";
import { AccountSecuritySettings } from "src/sections/dashboard/account/account-security-settings";

export const Route = createFileRoute("/_authenticated/conta/seguranca/")({
  component: () => <Page />,
});

const Page = () => {
  return (
    <PageContainer title={"Meu perfil"} subtitle="Gerencie suas informações de perfil">
      <AccountSecuritySettings />
    </PageContainer>
  );
};
