import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { thumbzApi } from "../../services/thumbz-api";
import {
  IOrganization,
  IPersonalization,
  IBillingStatusDto,
  IBillingBlgStripeSubscriptionStatusEnum,
} from "../../services/thumbz-base-api";

interface OrganizationState {
  selectedOrganization: IOrganization | null;
  userOrganizations: IOrganization[];
}

const initialState: OrganizationState = {
  selectedOrganization: null,
  userOrganizations: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchOrganization = createAsyncThunk(
  "organization/fetchOrganization",
  async ({ org_id }: { org_id: string }) => {
    return await thumbzApi.organization.organizationControllerGet(org_id);
  },
);

export const fetchUserOrganizations = createAsyncThunk(
  "organizations/fetchUserOrganizations",
  async () => {
    const res = await thumbzApi.organization.organizationControllerList();
    console.log("🚀 ~ res:", res);
    return res;
  },
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setSelectedOrganization: (state, action: PayloadAction<IOrganization | null>) => {
      console.table({ state, action });
      state.selectedOrganization = action.payload;
    },
    clearSelectedOrganization: (state) => {
      state.selectedOrganization = null;
    },
    setUserOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
      state.userOrganizations = action.payload;
    },
    resetState: (state) => {
      state.selectedOrganization = null;
      state.userOrganizations = [];
    },
    finishOnboarding: (state, action: PayloadAction<IOrganization>) => {
      state.selectedOrganization = action.payload;
    },
    updateSelectedOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.selectedOrganization = action.payload;
    },
    updateSelectedOrganizationPersonalization: (state, action: PayloadAction<IPersonalization>) => {
      state.selectedOrganization!.personalization = action.payload;
    },
    updateOrganizationBilling: (state, action: PayloadAction<IBillingStatusDto>) => {
      if (state.selectedOrganization) state.selectedOrganization.billing = action.payload.billing;
      // TODO: Implement this
      else fetchOrganization({ org_id: "your_org_id" });
    },
  },
  selectors: {
    hasCompletedOnboarding: (state) => {
      const metadata = state.selectedOrganization?.org_onboarding_metadata;
      return metadata !== null && metadata !== undefined && Object.keys(metadata).length > 0;
    },
    shouldShowBilling: (state) => {
      if (!state.selectedOrganization) {
        return true;
      }
      if (!state.selectedOrganization?.billing?.blg_stripe_subscription_status) {
        return true;
      }

      const allowedArr = [
        IBillingBlgStripeSubscriptionStatusEnum.Trialing,
        IBillingBlgStripeSubscriptionStatusEnum.Active,
        IBillingBlgStripeSubscriptionStatusEnum.PastDue,
      ];

      const res = allowedArr.includes(
        state.selectedOrganization?.billing?.blg_stripe_subscription_status,
      );

      if (res) return false;
      else return true;
    },
    canChangeOrganization: (state) => {
      return state.userOrganizations.length > 1;
    },
    selectedOrganization: (state) => {
      return state.selectedOrganization!;
    },
    organizationPhoto: (state) => {
      return `${state.selectedOrganization?.org_photo?.ast_url}?${state.selectedOrganization?.org_photo?.ast_updated_at}`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganization.fulfilled, (state, action) => {
      state.selectedOrganization = action.payload;
    });
    builder.addCase(fetchUserOrganizations.fulfilled, (state, action) => {
      console.log("🚀 ~ builder.addCase ~ fulfilled ~ state, action:", state, action);
      state.userOrganizations = action.payload;
    });
    builder.addCase(fetchUserOrganizations.rejected, (state) => {
      console.log("🚀 ~ builder.addCase ~ rejected ~ state:", state);
      alert("Erro ao buscar organizações");
      state.userOrganizations = [];
    });
  },
});

export const { setSelectedOrganization, setUserOrganizations, clearSelectedOrganization } =
  organizationSlice.actions;

export const organizationThunks = {
  fetchOrganization,
  fetchUserOrganizations,
};
