import { Typography, Breadcrumbs, IconButton } from "@mui/material";
import { Stack, SxProps } from "@mui/system";
import Link from "@mui/material/Link";
import React from "react";
import { BreadcrumbsSeparator } from "./breadcrumbs-separator";
import { Seo, SeoProps } from "./seo";
import { RouterLink } from "src/components/router-link";
import { useRouter } from "src/hooks/use-router";
import { ArrowBack } from "@mui/icons-material";
import { RouterPaths } from "src/main";

export interface IPageContainerProps {
  children: React.ReactNode;
  seo?: SeoProps;
  title?: string;
  subtitle?: string;
  breadcrumbs?: { href: RouterPaths | string; label: string }[];
  sx?: SxProps;
  leftNode?: React.ReactNode;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
}

const DEFAULT_SX: SxProps = {
  flexGrow: 1,
  px: 4,
  pt: 2,
  pb: 8,
};

export const PageContainer: React.FC<IPageContainerProps> = ({
  breadcrumbs,
  children,
  seo,
  subtitle,
  title,
  sx = DEFAULT_SX,
  leftNode,
  isLoading = false, // Default to false if not provided
  loadingComponent = <Typography>Loading...</Typography>, // Default loading component
}) => {
  const router = useRouter();

  const handleBack = () => {
    if (breadcrumbs && breadcrumbs.length > 1) {
      router.history.back();
    }
  };

  return (
    <>
      <Seo title={title} {...seo} />
      <Stack component="main" sx={sx}>
        <Stack direction="row" justifyContent="space-between" spacing={4} mb={4}>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {breadcrumbs && breadcrumbs.length > 1 && (
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              )}
              {title && <Typography variant="h5">{title}</Typography>}
            </Stack>
            {subtitle && (
              <Typography variant="subtitle2" color="textSecondary">
                {subtitle}
              </Typography>
            )}
            <Breadcrumbs separator={<BreadcrumbsSeparator />}>
              {(breadcrumbs ?? []).map((breadcrumb, index) => {
                const isLast =
                  index === (breadcrumbs && breadcrumbs.length ? breadcrumbs.length : 0) - 1;
                return isLast ? (
                  <Typography key={index} color="text.secondary" variant="subtitle2">
                    {breadcrumb.label}
                  </Typography>
                ) : (
                  <Link
                    key={index}
                    color="text.primary"
                    component={RouterLink}
                    href={breadcrumb.href}
                    variant="subtitle2"
                  >
                    {breadcrumb.label}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </Stack>
          {leftNode && (
            <Stack alignItems="center" direction="row" spacing={3}>
              {leftNode}
            </Stack>
          )}
        </Stack>

        {/* Condicional para exibir loadingComponent ou conteúdo */}
        {isLoading ? loadingComponent : children}
      </Stack>
    </>
  );
};
