import { createFileRoute } from "@tanstack/react-router";
import { PageContainer } from "src/components/page-container";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import { CompanyTeam } from "src/sections/dashboard/workspace/company-team";

export const Route = createFileRoute("/_authenticated/organizacao/colaboradores/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  return (
    <PageContainer
      title={"Colaboradores"}
      subtitle="Gerencie os colaboradores da sua organização"
      breadcrumbs={[
        {
          href: paths.dashboard.index,
          label: "Dashboard",
        },
        {
          href: paths.dashboard.organization.index,
          label: "Organização",
        },
      ]}
    >
      <CompanyTeam />
    </PageContainer>
  );
};
