import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IApprovalStage } from 'src/services/thumbz-base-api';

interface ApprovalStageUpsertFormProps {
  open: boolean;
  stage: Partial<IApprovalStage>;
  initialValues: IFormValues | undefined;
  onClose: () => void;
  onSave: (stage: IFormSubmitValuesApprovalStageUpsertForm) => void;
}

interface IFormValues {
  stg_name: string | undefined;
  stg_description: string | undefined;
  stg_order: number | undefined;
}

export interface IFormSubmitValuesApprovalStageUpsertForm extends Omit<IFormValues, 'stg_name'> {
  stg_id: string | undefined;
  stg_name: string;
}

const validationSchema = Yup.object<IFormValues>({
  stg_name: Yup.string().required('Nome da etapa é obrigatório'),
  stg_description: Yup.string().optional().nullable(),
  stg_order: Yup.number().required('Ordem é obrigatória').min(1, 'Ordem deve ser maior que 0'),
});

export const ApprovalStageUpsertForm: React.FC<ApprovalStageUpsertFormProps> = ({
  open,
  stage,
  initialValues,
  onClose,
  onSave,
}) => {
  const _initialvalues: IFormValues = useMemo(
    () => convertInitialValuesToFormValues(initialValues),
    [initialValues]
  );

  const formik = useFormik<IFormValues>({
    initialValues: _initialvalues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave({
        stg_id: stage.stg_id,
        stg_name: values.stg_name ?? 'Etapa sem nome',
        stg_description: values.stg_description,
        stg_order: values.stg_order,
      });
    },
  });

  useEffect(() => {
    // Only update form values if they are different from the current stage values
    // This check prevents unnecessary updates and avoids the infinite loop
    if (JSON.stringify(formik.values) !== JSON.stringify(initialValues)) {
      formik.setValues(convertInitialValuesToFormValues(initialValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]); // Only re-run this effect if `stage` changes

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{stage.stg_id ? 'Editar Etapa' : 'Nova Etapa'}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome da Etapa"
            type="text"
            fullWidth
            {...formik.getFieldProps('stg_name')}
            error={formik.touched.stg_name && Boolean(formik.errors.stg_name)}
            helperText={formik.touched.stg_name && formik.errors.stg_name}
          />
          <TextField
            margin="dense"
            label="Descrição"
            type="text"
            rows={4}
            fullWidth
            {...formik.getFieldProps('stg_description')}
            error={formik.touched.stg_description && Boolean(formik.errors.stg_description)}
            helperText={formik.touched.stg_description && formik.errors.stg_description}
          />
          <TextField
            margin="dense"
            label="Ordem"
            type="number"
            fullWidth
            style={{ display: 'none' }}
            {...formik.getFieldProps('stg_order')}
            error={formik.touched.stg_order && Boolean(formik.errors.stg_order)}
            helperText={formik.touched.stg_order && formik.errors.stg_order}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

function convertInitialValuesToFormValues(initialValues: IFormValues | undefined): IFormValues {
  return {
    stg_name: initialValues?.stg_name ?? '',
    stg_description: initialValues?.stg_description ?? undefined,
    stg_order: initialValues?.stg_order ?? 999,
  };
}
