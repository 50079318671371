import { Link, Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { SettingsConsumer } from "../contexts/settings";
import { SupabaseAuthClient } from "@supabase/supabase-js/dist/module/lib/SupabaseAuthClient";
import { Provider } from "react-redux";
import { store, persistor } from "../app/store";
import { OrganizationProvider } from "../contexts/organization/OrganizationContext";
import { SplashScreen } from "../components/splash-screen";
import { PersistGate } from "redux-persist/integration/react";
import { AuthContext } from "src/contexts/auth.context";
import { QueryClientWrapper } from "src/contexts/QueryClientWrapper";
import { SettingsContextProvider } from "src/contexts/SettingsContextProvider";
import { ThemeWrapper } from "src/contexts/ThemeWrapper";
import { ComponentWrapper } from "src/contexts/ComponentLayout";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Box, Button, Container, Stack } from "@mui/material";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { StripePricingTable } from "src/components/stripe/strip-pricing-table";
import { TopNav } from "src/layouts/dashboard/marketing/top-nav";

interface MyRouterContext {
  auth: SupabaseAuthClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
  notFoundComponent: () => {
    return (
      <Box>
        <Box mb={"100px"}>{/* <TopNav /> */}</Box>
        <Container>
          <EmptyPlaceholder
            overlineText="Ooops!"
            customSubtitle="Parece que você tentou acessar uma página que não existe."
            showButton={false}
            showElevation={true}
          >
            <Button component={Link} to="/">
              Voltar para a página inicial
            </Button>
          </EmptyPlaceholder>
        </Container>
      </Box>
    );
  },
});

function RootComponent() {
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const { approvalCreate, approvalEdit, auth, layout, organization } = store.getState();

      [
        { name: "approvalCreate", state: approvalCreate },
        { name: "approvalEdit", state: approvalEdit },
        { name: "auth", state: auth },
        { name: "layout", state: layout },
        { name: "organization", state: organization },
      ].forEach(({ name, state }) => {
        Sentry.setExtra(`redux state -> ${name}`, JSON.stringify(state, null, 2));
      });
    });

    return () => {
      // Limpar o subscription ao desmontar o componente
      unsubscribe();
    };
  }, []);

  return (
    <PersistGate loading={<SplashScreen />} persistor={persistor}>
      <QueryClientWrapper>
        <AuthContext>
          <OrganizationProvider>
            <SettingsContextProvider>
              <SettingsConsumer>
                {(settings) => (
                  <ThemeWrapper settings={settings}>
                    <ComponentWrapper settings={settings}>
                      <Outlet />
                    </ComponentWrapper>
                  </ThemeWrapper>
                )}
              </SettingsConsumer>
            </SettingsContextProvider>
          </OrganizationProvider>
        </AuthContext>
      </QueryClientWrapper>
    </PersistGate>
  );
}
