import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/notificacoes/")({
  component: () => <Page />,
});

import { usePageView } from "src/hooks/use-page-view";
import { AccountNotificationsSettings } from "src/sections/dashboard/account/account-notifications-settings";
import { PageContainer } from "src/components/page-container";

const Page = () => {
  usePageView();

  return (
    <PageContainer title={"Notificações"} subtitle="Gerencie suas configurações de notificações.">
      <AccountNotificationsSettings />
    </PageContainer>
  );
};

export default Page;
