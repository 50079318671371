import type { FC } from "react";
import PropTypes from "prop-types";

export interface SeoProps {
  title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
  const { title } = props;

  const fullTitle = title ? title + " | Thumbz" : "Thumbz";

  return (
    <>
      <title>{fullTitle}</title>
    </>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
};
