import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { queryKeys } from 'src/config/query-key';
import { useThumbzApi } from 'src/services/thumbz-api';
import { IStatus, IStatusTypeStpTypeEnum } from 'src/services/thumbz-base-api';

interface IUseStatusProps {
  wrk_id: string;
}

interface IUseStatus {
  loading: boolean;
  [IStatusTypeStpTypeEnum.NOT_STARTED]: IStatus | undefined;
  [IStatusTypeStpTypeEnum.IN_REVIEW]: IStatus | undefined;
  [IStatusTypeStpTypeEnum.REQUEST_CHANGED]: IStatus | undefined;
  [IStatusTypeStpTypeEnum.DONE]: IStatus | undefined;
}

export const useStatus = ({ wrk_id }: IUseStatusProps): IUseStatus => {
  const { api } = useThumbzApi();

  const statusParams = { wrk_id };
  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindByWorkspace(statusParams).queryKey,
    queryFn: () => api.status.statusControllerFindByWorkspace(statusParams.wrk_id),
  });

  const NOT_STARTED = statusQuery.data?.find(
    ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.NOT_STARTED
  );
  const IN_REVIEW = statusQuery.data?.find(
    ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.IN_REVIEW
  );
  const REQUEST_CHANGED = statusQuery.data?.find(
    ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.REQUEST_CHANGED
  );
  const DONE = statusQuery.data?.find(
    ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.DONE
  );

  return {
    loading: statusQuery.isLoading,
    [IStatusTypeStpTypeEnum.NOT_STARTED]: NOT_STARTED,
    [IStatusTypeStpTypeEnum.IN_REVIEW]: IN_REVIEW,
    [IStatusTypeStpTypeEnum.REQUEST_CHANGED]: REQUEST_CHANGED,
    [IStatusTypeStpTypeEnum.DONE]: DONE,
  };
};
