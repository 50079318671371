import React, { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import {
  Box,
  Button,
  Tooltip,
  Stack,
  ButtonGroup,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { useThumbzApi } from "src/services/thumbz-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import {
  IApprovalFlowStage,
  IApprovalStageCustomer,
  ICustomer,
} from "src/services/thumbz-base-api";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { Add, Visibility, Delete, MoreVertOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { useRouter } from "src/hooks/use-router";
import { AgGridReactDefault } from "src/components/table/custom-table";
import PhotoForm from "src/components/upload/photo-form";
import { CustomerUpsertForm } from "../customers/customer-upsert-form";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

interface ApprovalFlowCustomersTableProps {
  stage: IApprovalFlowStage;
  wrk_id: string;
}

export const ApprovalFlowCustomersTable: React.FC<ApprovalFlowCustomersTableProps> = ({
  stage,
  wrk_id,
}) => {
  const [openCustomerTable, setOpenCustomerTable] = useState<boolean>(false);
  const confirm = useConfirm();
  const [rowData, setRowData] = useState<ICustomer[]>(bulkConvertToCustomer([]));
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  const [colDefs] = useState<ColDef<ICustomer>[]>([
    {
      pinned: "left",
      headerName: "Avatar",
      field: "cust_photo",
      flex: 1,
      cellRenderer: (params: {
        value: string | undefined;
        data: { cust_id: string | undefined | null };
      }) => (
        <Box display="flex" alignItems="center">
          {params?.data?.cust_id ? (
            <PhotoForm
              photoSize={25}
              key={`${params.value}`}
              default_photo_url={params.value}
              loading={changeCustomerPhotoMutation.isPending}
              onFormSuccessSubmit={({ base64 }) => {
                changeCustomerPhotoMutation.mutate({
                  base64: base64 || null,
                  cust_id: params.data.cust_id!,
                });
              }}
            />
          ) : null}
        </Box>
      ),
    },
    {
      pinned: "left",
      headerName: "Nome",
      field: "cust_name",
      flex: 1,
    },
    {
      headerName: "Email",
      field: "cust_email",
      flex: 1,
    },
    {
      headerName: "Telefone",
      field: "cust_mobile_phone",
    },
    {
      headerName: "Ações",
      pinned: "right",
      width: 100,
      cellRenderer: (params: { data: ICustomer }) => (
        <PopupState
          variant="popover"
          popupId={`approval-flow-stage-customers-table-actions-${params.data.cust_id}`}
        >
          {(popupState) => (
            <div>
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlined />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Menu
                  anchorEl={popupState.anchorEl}
                  open={Boolean(popupState.anchorEl)}
                  onClose={(event, reason) => popupState.close()}
                >
                  <MenuItem onClick={() => handleDelete(params.data)}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Remover</ListItemText>
                  </MenuItem>
                </Menu>
              </Popover>
            </div>
          )}
        </PopupState>
      ),
    },
  ]);

  const customersQueryKey = queryKeys.approvalFlowStage.approvalFlowStageControllerFind({
    afs_id: stage.afs_id,
  }).queryKey;

  const customersQuery = useQuery({
    queryFn: () => api.approvalFlowStage.approvalFlowStageControllerFind(stage.afs_id),
    enabled: !!stage.afs_id,
    queryKey: customersQueryKey,
  });

  const changeCustomerPhotoMutation = useMutation({
    mutationFn: api?.customer?.customerControllerUpdatePhoto,
    onSuccess: () => {
      clearCache();
      toast.success("Foto do aprovador alterada com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  useEffect(() => {
    if (customersQuery.isSuccess) {
      setRowData(bulkConvertToCustomer(customersQuery.data.approvalStageCustomers));
    }
  }, [customersQuery.data, customersQuery.isSuccess]);

  function clearCache() {
    queryClient.invalidateQueries({
      queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({
        flow_id: stage?.approvalFlow?.flow_id,
      }).queryKey,
    });

    queryClient.invalidateQueries({
      queryKey: queryKeys.approvalFlowStage.approvalFlowStageControllerFind({
        afs_id: stage.afs_id,
      }).queryKey,
    });
  }

  const upsertCustomerMutation = useMutation({
    mutationFn: api?.customer?.customerControllerUpsert,
    onSuccess: () => {
      clearCache();
      toast.success("Aprovador criado com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const linkCustomerToStageMutation = useMutation({
    mutationFn: api?.approvalFlowStage?.approvalFlowStageControllerLink,
    onSuccess: () => {
      clearCache();
      setOpenCustomerTable(false);
      toast.success("Aprovador vinculado com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const unlinkCustomerMutation = useMutation({
    mutationFn: api?.approvalFlowStage?.approvalFlowStageControllerUnlink,
    onSuccess: () => {
      clearCache();
      toast.success("Aprovador removido com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const handleDelete = (data: ICustomer) => {
    confirm({
      description: `Tem certeza que deseja remover o aprovador "${data.cust_name}"?`,
      confirmationText: "Sim, remover",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      unlinkCustomerMutation.mutate({
        afs_id: stage.afs_id,
        cust_id: data.cust_id,
      });
    });
  };

  const handleAdd = () => {
    setOpenCustomerTable(true);
  };

  const handleCustomerUpsertSuccess = (customer: ICustomer) => {
    linkCustomerToStageMutation.mutate({
      cust_id: customer.cust_id,
      afs_id: stage.afs_id,
    });
  };

  return (
    <>
      <AgGridReactDefault<ICustomer>
        loading={false}
        rowData={rowData}
        columnDefs={colDefs}
        rowSelection="single"
        editType="fullRow"
        actions={
          <Stack justifyContent="space-between" flex={1} direction="row">
            <Tooltip title="Novo Aprovador">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAdd}
                startIcon={<Add />}
              >
                Novo Aprovador
              </Button>
            </Tooltip>
          </Stack>
        }
      />
      <CustomerUpsertForm
        open={openCustomerTable}
        customer={null}
        wrk_id={wrk_id}
        onClose={() => setOpenCustomerTable(false)}
        onSuccess={handleCustomerUpsertSuccess}
      />
    </>
  );
};

function bulkConvertToCustomer(approval: IApprovalStageCustomer[]): ICustomer[] {
  return (approval ?? []).map(({ customer }) => customer);
}
