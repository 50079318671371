import type { FC } from "react";
import type { ApexOptions } from "apexcharts";
import { Card, CardHeader, Divider, Grid, Typography, Box, Skeleton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chart } from "../chart";
import { IDashboardResponseDto } from "src/services/thumbz-base-api";
import { UseQueryResult } from "@tanstack/react-query";

interface IProps {
  query: UseQueryResult<IDashboardResponseDto, Error>;
}

export const DashboardRevisionsStatus: FC<IProps> = ({ query }) => {
  const theme = useTheme();

  const totalRevisions = query?.data?.revisions.total.value || 1;

  const items = [
    {
      color: "#FF9800", // Orange - Pending
      label: query?.data?.revisions.pending.title,
      subtitle: query?.data?.revisions.pending.tooltip,
      value: query?.data?.revisions.pending.value,
      percentage:
        totalRevisions !== 0
          ? Math.round(((query?.data?.revisions.pending.value ?? 0) / totalRevisions) * 100)
          : 0,
    },
    {
      color: "#F44336", // Red - Needing Review
      label: query?.data?.revisions.needingReview.title,
      subtitle: query?.data?.revisions.needingReview.tooltip,
      value: query?.data?.revisions.needingReview.value,
      percentage:
        totalRevisions !== 0
          ? Math.round(((query?.data?.revisions.needingReview.value ?? 0) / totalRevisions) * 100)
          : 0,
    },
    {
      color: "#4CAF50", // Green - Completed
      label: query?.data?.revisions.completed.title,
      subtitle: query?.data?.revisions.completed.tooltip,
      value: query?.data?.revisions.completed.value,
      percentage:
        totalRevisions !== 0
          ? Math.round(((query?.data?.revisions.completed.value ?? 0) / totalRevisions) * 100)
          : 0,
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            color: theme.palette.text.primary,
            fontSize: "22px", // Ajuste do tamanho da fonte
            fontWeight: 600,
            offsetY: -5, // Ajuste do posicionamento
          },
          total: {
            show: false,
          },
        },
        hollow: {
          size: "70%",
        },
        track: {
          background: theme.palette.background.default,
        },
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  if (query.isLoading) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton variant="text" width={100} />}
          subheader={<Skeleton variant="text" width={100} />}
        />
        <Divider />
        <Grid container spacing={3} sx={{ p: 3 }}>
          {[1, 2, 3].map((item) => (
            <Grid item key={item} md={4} xs={12}>
              <Card
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  p: 2,
                  height: "100%",
                  textAlign: "center",
                }}
                variant="outlined"
              >
                <Stack sx={{ alignItems: "center", display: "flex", mb: 1, mt: 1 }}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="text" width={100} height={40} />
                </Stack>
                <Skeleton variant="text" width={100} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={query?.data?.revisions.title}
        subheader={query?.data?.revisions.description}
      />
      <Divider />
      <Grid container spacing={3} sx={{ p: 3 }}>
        {items.map((item) => (
          <Grid item key={item.label} md={4} xs={12}>
            <Card
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 2,
                height: "100%",
                textAlign: "center",
              }}
              variant="outlined"
            >
              <Typography
                sx={{
                  color: item.color,
                  fontSize: "16px",
                  fontWeight: 700,
                  mb: 1,
                }}
                variant="h6"
              >
                {item.label}
              </Typography>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Chart
                  height={240}
                  options={{
                    ...chartOptions,
                    colors: [item.color],
                  }}
                  series={[item.percentage]}
                  type="radialBar"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, fontSize: "24px" }} // Tamanho da fonte ajustado
                  >
                    {/* Invisible character */}
                    {"‎"}
                  </Typography>
                  <Typography variant="body2">{item.value}</Typography>
                </Box>
              </Box>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                {item.subtitle}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
