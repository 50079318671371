import React from "react";
import { Stack, Avatar, Typography, IconButton, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";

export const NewCustomerAvatar = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Avatar
        sx={{
          bgcolor: "transparent",
          width: 75,
          height: 75,
          border: `2px dashed ${theme.palette.action.active}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Add color={"action"} sx={{ fontSize: 32 }} />{" "}
      </Avatar>
      <Typography variant="caption" align="center" sx={{ color: theme.palette.action.active }}>
        Novo aprovador
      </Typography>
    </Stack>
  );
};

export default NewCustomerAvatar;
