import React, { useState } from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useRouter } from "src/hooks/use-router";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { IApproval, IApprovalStage } from "src/services/thumbz-base-api";
import {
  Button,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  Reorder,
  Lightbulb,
  MoreVert,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import toast from "react-hot-toast";
import { useConfirm } from "material-ui-confirm";
import { ApprovalReorderForm } from "./approval-reorder-form";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  ApprovalStageUpsertForm,
  IFormSubmitValuesApprovalStageUpsertForm,
} from "./approval-stage-upsert-form";
import { paths } from "src/paths";
import { ApprovalCustomersAvatar } from "./approval-customers-avatar";
import { GenericHint } from "src/components/hint/generic-hint";

interface IApprovalTabCustomers {
  open: boolean;
  approval: IApproval;
  onClose: () => void;
  onSave: (stage: Partial<IApprovalStage>) => void;
  wrk_id: string;
}

export const ApprovalTabCustomersCard: React.FC<IApprovalTabCustomers> = ({
  open,
  approval,
  onClose,
  onSave,
  wrk_id,
}) => {
  usePageView();
  const isMounted = useMounted();
  const [openReorder, setOpenReorder] = useState(false);
  const router = useRouter();
  const { api } = useThumbzApi();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [currentTab, setCurrentTab] = useState<string | null>(getTabName(approval.stages[0]));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogStage, setDialogStage] = useState<Partial<IApprovalStage>>({});
  const flow_id = approval.approvalFlow.flow_id;

  const workspaceQuery = useQuery({
    queryKey: queryKeys.workspace.get({ wrk_id: wrk_id }).queryKey,
    queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
    enabled: !!wrk_id && isMounted(),
  });

  const wrk_slug = workspaceQuery.data?.wrk_slug;

  const upsertStageMutation = useMutation({
    mutationKey: queryKeys.approvalStage.approvalStageControllerUpsert().queryKey,
    mutationFn: api.approvalStage.approvalStageControllerUpsert,
    onSuccess: (_, { stg_id }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.approval.approvalControllerGet(approval.apv_id).queryKey,
      });

      if (stg_id) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.approvalStage.approvalStageControllerFind(stg_id).queryKey,
        });
      }
      toast.success(stg_id ? "Etapa atualizada com sucesso" : "Etapa criada com sucesso");
      handleDialogClose();
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const deleteStageMutation = useMutation({
    mutationFn: api.approvalStage.approvalStageControllerDelete,
    onMutate: async ({ stg_id }) => {
      // Cancela queries relacionadas ao fluxo e estágios para evitar conflitos
      await queryClient.cancelQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });

      // Snapshot do cache atual para rollback se necessário
      const previousData = queryClient.getQueryData<IApproval>(
        queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      );

      // Atualiza o cache otimistamente removendo o estágio deletado
      if (previousData) {
        queryClient.setQueryData<IApproval>(
          queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
          (oldData) => ({
            ...oldData!,
            stages: oldData!.stages.filter((stage) => stage.stg_id !== stg_id),
          }),
        );
      }

      return { previousData };
    },
    onError: (error, _, context) => {
      // Rollback em caso de erro, verifica se `context` está presente
      if (context?.previousData) {
        queryClient.setQueryData(
          queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
          context.previousData,
        );
      }
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
    onSettled: () => {
      // Invalida queries ao final para garantir a sincronização
      queryClient.invalidateQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });

      // Invalida a query que carrega todos os estágios
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes("approvalStage") && query.queryKey.includes(flow_id),
      });

      // Invalida a query do approval para garantir a atualização do fluxo principal
      queryClient.invalidateQueries({
        queryKey: queryKeys.approval.approvalControllerGet(approval.apv_id).queryKey,
      });
    },
    onSuccess: () => {
      toast.success("Etapa excluída com sucesso");
    },
  });

  if (!flow_id) {
    return <EmptyPlaceholder overlineText="Nenhum fluxo selecionado" />;
  }

  const handleEditStage = ({ stage }: { stage: IApprovalStage }) => {
    const { stg_order } = getLastApprovalStageOrder(approval.stages);
    setDialogStage({
      ...stage,
      stg_order: stg_order,
    });
    setIsDialogOpen(true);
  };

  const handleDeleteStage = ({ stage }: { stage: IApprovalStage }) => {
    confirm({
      description: `Tem certeza que deseja excluir a etapa "${stage.stg_name}"?`,
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      if (!stage) {
        toast.error("Não foi possível excluir a etapa pois nenhuma etapa foi selecionada");
        return;
      }
      deleteStageMutation.mutate({
        stg_id: stage?.stg_id,
      });
    });
  };

  function handleAddStage() {
    const { stg_order_plus_one } = getLastApprovalStageOrder(approval.stages);
    setDialogStage({
      stg_order: stg_order_plus_one,
    });
    setIsDialogOpen(true);
  }

  function handleReorderStages() {
    setOpenReorder(true);
  }

  function getTabName(stage: IApprovalStage): string {
    return `stage-${stage.stg_id}`;
  }

  function handleChangeCurrentTabAfterReorder(stages: IApprovalStage[]) {
    setCurrentTab(getTabName(stages[0]));
  }

  function handleDeleteFlow() {
    confirm({
      description: "Tem certeza que deseja excluir este fluxo?",
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      api.approvalFlow.approvalFlowControllerDelete({ flow_id }).then(() => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.approvalFlow.approvalFlowControllerList({ wrk_id: "" }).queryKey,
        });
        toast.success("Fluxo deletado com sucesso");
        // router.push(paths.dashboard.index);
        router.navigate({ to: "/" });
      });
    });
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
    setDialogStage({});
  }

  const handleDialogSave = (data: IFormSubmitValuesApprovalStageUpsertForm) => {
    const { stg_order_plus_one } = getLastApprovalStageOrder(approval.stages);

    upsertStageMutation.mutate({
      stg_id: data.stg_id,
      apv_id: approval.apv_id,
      stg_name: data.stg_name,
      stg_order: data.stg_order ?? stg_order_plus_one,
    });
  };

  const OptionsSpecificStepButtonDialog = ({ stage }: { stage: IApprovalStage }) => (
    <PopupState variant="popover" popupId="options-popup">
      {(popupState) => (
        <div>
          <IconButton
            id="options-button-specific-step"
            {...bindTrigger(popupState)}
            aria-controls={open ? "options-button-specific-step-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
          >
            <MoreVert />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Menu
              anchorEl={popupState.anchorEl}
              open={Boolean(popupState.anchorEl)}
              onClose={() => popupState.close()}
            >
              <MenuItem disableRipple disableTouchRipple disabled>
                <Stack>
                  <ListItemText>Opções da etapa</ListItemText>
                </Stack>
              </MenuItem>
              <MenuItem color="error" onClick={() => handleEditStage({ stage })}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar etapa</ListItemText>
              </MenuItem>
              <MenuItem color="error" onClick={() => handleDeleteStage({ stage })}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>Excluir etapa</ListItemText>
              </MenuItem>
            </Menu>
          </Popover>
        </div>
      )}
    </PopupState>
  );

  const OptionsAllStepsButtonDialog = () => (
    <PopupState variant="popover" popupId="options-popup">
      {(popupState) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            {...bindTrigger(popupState)}
            aria-controls={open ? "options-all-steps-button-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<KeyboardArrowDown />}
          >
            Opções gerais
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Menu
              anchorEl={popupState.anchorEl}
              open={Boolean(popupState.anchorEl)}
              onClose={() => popupState.close()}
            >
              <MenuItem disableRipple disableTouchRipple disabled>
                <ListItemText>Opções gerais</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleAddStage}>
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText>Adicionar nova etapa</ListItemText>
              </MenuItem>

              <MenuItem onClick={handleReorderStages}>
                <ListItemIcon>
                  <Reorder fontSize="small" />
                </ListItemIcon>
                <ListItemText>Reordenar etapas</ListItemText>
              </MenuItem>
            </Menu>
          </Popover>
        </div>
      )}
    </PopupState>
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <GenericHint
          text={"Selecione quem deve aprovar em cada etapa"}
          IconAsset={Lightbulb} // Sem os parênteses
        />
        <OptionsAllStepsButtonDialog />
      </Stack>
      <Stack spacing={2}>
        {approval.stages.map((stage) => (
          <Card key={stage.stg_id}>
            <CardHeader
              title={`Quem deve aprovar na etapa: ${stage.stg_name}?`}
              action={<OptionsSpecificStepButtonDialog stage={stage} />}
              description={stage.stg_description}
            />
            <CardContent>
              {!workspaceQuery.isLoading && wrk_slug ? (
                <ApprovalCustomersAvatar stage={stage} wrk_id={wrk_id} wrk_slug={wrk_slug} />
              ) : (
                <Skeleton variant="rectangular" height={300} />
              )}
            </CardContent>
          </Card>
        ))}
      </Stack>
      <ApprovalStageUpsertForm
        open={isDialogOpen}
        stage={dialogStage}
        initialValues={{
          stg_name: dialogStage.stg_name,
          stg_description: dialogStage.stg_description ?? undefined,
          stg_order: dialogStage.stg_order,
        }}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
      />
      <ApprovalReorderForm
        open={openReorder}
        stages={approval.stages}
        apv_id={approval.apv_id}
        onClose={() => setOpenReorder(false)}
        onSuccess={() => {}}
      />
    </>
  );
};

export default ApprovalTabCustomersCard;

function getLastApprovalStageOrder(stages: IApprovalStage[]): {
  stg_order: number;
  stg_order_plus_one: number;
} {
  const last = stages.reduce(
    (acc: number, stage: IApprovalStage) => Math.max(acc, stage.stg_order ?? 0),
    0,
  );
  return { stg_order: last === 0 ? 999 : last, stg_order_plus_one: last === 0 ? 1000 : last + 1 };
}
