import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { ContactForm } from "../contact/contact-form";
import { TopNav } from "src/layouts/dashboard/marketing/top-nav";

interface OnboardingFormProps {}

const OnboardingForm: React.FC<OnboardingFormProps> = () => {
  return (
    <>
      <Box mb={"100px"}>
        <TopNav />
      </Box>
      <Box
        component="main"
        sx={{
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "neutral.800" : "neutral.50",
            pt: 2,
            pb: 8,
          }}
        >
          <Container maxWidth="md" sx={{ pl: { lg: 15 } }}>
            <Typography sx={{ mb: 3, mt: 3 }} variant="h1">
              Vamos nos conhecer
            </Typography>
            <Typography sx={{ mb: 3 }} variant="body1">
              Ajudamos empresas a criar produtos digitais incríveis. Vamos conversar sobre como
              podemos ajudar você.
            </Typography>
            {/* <Typography
              color="primary"
              sx={{ mb: 3 }}
              variant="h6"
            >
              Join 6,000+ forward-thinking companies:
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              flexWrap="wrap"
              gap={4}
              sx={{
                color: 'text.primary',
                '& > *': {
                  flex: '0 0 auto',
                },
              }}
            >
              <LogoSamsung />
              <LogoVisma />
              <LogoBolt />
              <LogoAws />
              <LogoAccenture />
              <LogoAtt />
            </Stack> */}
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "background.paper",
            px: 6,
            py: 5,
          }}
        >
          <Typography sx={{ mb: 5 }} variant="h6">
            Preencha o formulário abaixo
          </Typography>
          <Container maxWidth="md">
            <ContactForm />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default OnboardingForm;
