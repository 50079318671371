import { AuthChangeEvent, Session } from "@supabase/supabase-js";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { useAppDispatch } from "src/app/hooks";
import { authThunks } from "src/features/auth/authSlice";
import { AUTH_TOKEN } from "src/services/thumbz-api";
import { createClient } from "src/utils/supabase/client";

interface IAuthContextProps {
  children: React.ReactNode;
}

export const AuthContext: React.FC<IAuthContextProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const supabase = createClient();

  const signoutClean = useCallback(() => {
    queryClient.clear();
    window.localStorage.removeItem(AUTH_TOKEN);
  }, [queryClient]);

  const supabaseHandleAuthStateChanged = useCallback(
    async (event: AuthChangeEvent, session: Session | null) => {
      if (event === "SIGNED_IN" || event === "INITIAL_SESSION") {
        dispatch(authThunks.handleAuthToken({ event, session }));
      }

      if (event === "SIGNED_OUT") {
        signoutClean();
        dispatch(authThunks.handleAuthToken({ event, session }));
      }
    },
    [dispatch, signoutClean],
  );

  useEffect(() => {
    supabase.auth.onAuthStateChange(supabaseHandleAuthStateChanged);
  }, [supabase.auth, supabaseHandleAuthStateChanged]);

  return <>{children}</>;
};
