import { createFileRoute } from "@tanstack/react-router";

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { ThumbzAnimatedLogo } from "src/components/splash-screen";
import { queryKeys } from "src/config/query-key";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { thumbzApi } from "src/services/thumbz-api";
import { useRouter } from "src/hooks/use-router";
import { NextPage } from "next"; // Import NextPage type
import { paths } from "src/paths";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";

export const Route = createFileRoute("/verificar")({
  component: () => <Page />,
});

interface IPageProps {}

const ONE_SECOND = 1000;

const Page: NextPage<IPageProps> = () => {
  // Use NextPage type
  const shouldShowBilling = useAppSelector(organizationSlice.selectors.shouldShowBilling);
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useOrganization();
  const router = useRouter();

  const queryKey = queryKeys.billing.status({
    org_id: String(selectedOrganization?.org_id),
  }).queryKey;
  const enabled = !!selectedOrganization?.org_id;

  const billingStatus = useQuery({
    queryFn: () =>
      thumbzApi.billing.billingControllerGetBillingStatus({
        org_id: String(selectedOrganization?.org_id),
      }),
    queryKey,
    enabled,
    refetchInterval: ONE_SECOND,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    retry: 10,
    retryDelay(failureCount, error) {
      return failureCount * 100;
    },
  });

  useEffect(() => {
    if (billingStatus.data)
      dispatch(organizationSlice.actions.updateOrganizationBilling(billingStatus.data));

    if (shouldShowBilling === false) {
      router.navigate({
        from: "/verificar",
        to: "/",
        replace: true,
      });
    }
  }, [billingStatus.data, dispatch, router, shouldShowBilling]);

  useEffect(() => {
    if (!selectedOrganization) {
      router.navigate({
        from: "/verificar",
        to: "/",
        replace: true,
      });
    }
  }, [router, selectedOrganization]);

  if (billingStatus.error) {
    return (
      <EmptyPlaceholder
        overlineText="Ooops!"
        customSubtitle="Não conseguimos carregar as informações de faturamento. Por favor, tente novamente."
        showButton={false}
      >
        <LoadingButton
          onClick={() => billingStatus.refetch()}
          loading={billingStatus.isFetching}
          variant="contained"
          color="primary"
        >
          Tentar novamente
        </LoadingButton>
      </EmptyPlaceholder>
    );
  }

  return (
    <Stack
      sx={{ alignItems: "center", justifyContent: "center", height: "100%" }}
      flex={1}
      flexGrow={1}
    >
      <ThumbzAnimatedLogo />
    </Stack>
  );
};

export default Page;
