import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { ApprovalTypeFields } from "src/components/approval/approval-type-fields";
import { FieldRenderer } from "src/components/approval/field-renderer";
import { StatusIcon } from "src/components/status/StatusIcon";
import { StatusTitle } from "src/components/status/StatusTitle";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { IApproval, IApprovalTypeFieldAtfContextEnum } from "src/services/thumbz-base-api";
import { RevisionGenericWrapper } from "./revision-generic-wrapper";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";

interface IRevisionComplementProps {
  approval: IApproval;
}

export const RevisionComplement: React.FC<IRevisionComplementProps> = ({ approval }) => {
  const dispatch = useAppDispatch();
  const approvalState = useAppSelector(approvalCreateSlice.selectors.approval);

  const approvalTypeFields = approval?.approvalType?.approvalTypeFields || [];
  const hasApprovalTypeFields = approval.responses.length > 0;

  function _goToStep2() {
    dispatch(approvalCreateSlice.actions.changeStep(3));
  }

  return (
    <>
      <RevisionGenericWrapper
        title={"Complementos"}
        description={`Campos complementares a aprovação do tipo ${approval?.approvalType?.apt_name}`}
        action={
          <Button variant="text" color="primary" onClick={_goToStep2}>
            Editar
          </Button>
        }
      >
        {hasApprovalTypeFields ? (
          <ApprovalTypeFields
            approvalTypeFields={approval?.approvalType?.approvalTypeFields || []}
            onFieldChange={() => {}}
            responses={
              approvalState?.responses.map((r) => ({
                apr_value: r.apr_value,
                apv_id: approval.apv_id,
                atf_id: r.approvalTypeField.atf_id,
                apr_id: r.apr_id,
                item_id: undefined,
              })) || []
            }
          />
        ) : (
          <EmptyPlaceholder
            overlineText="Nada por aqui"
            showElevation={false}
            customSubtitle="Essa aprovação não possui campos complementares."
            showButton={false}
          />
        )}
      </RevisionGenericWrapper>
    </>
  );
};
