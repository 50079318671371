import type { FC, FormEvent } from "react";
import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { thumbzApi } from "src/services/thumbz-api";
import { IOnboardingQuestionsDtoTypeEnum } from "src/services/thumbz-base-api";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useAppDispatch } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";

export const ContactForm: FC = () => {
  const [answer, setAnswer] = useState<Record<string, string | string[]>>({});
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useOrganization();

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  }, []);

  const questionsQuery = useQuery({
    queryKey: ["contact-form"],
    queryFn: thumbzApi.organization.organizationControllerOnboardingQuestions,
  });

  const questionsMutation = useMutation({
    mutationFn: thumbzApi.organization.organizationControllerFillOnboardingQuestions,
    onSuccess: (response) => {
      setAnswer({});
      dispatch(organizationSlice.actions.finishOnboarding(response));
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  function handleChange(key: string, value: string[] | string): void {
    setAnswer((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  return (
    // prevent url redirect
    <form
      onSubmit={(event) => {
        event.preventDefault();
        questionsMutation.mutate({
          org_id: String(selectedOrganization?.org_id),
          questions: answer,
        });
      }}
    >
      <>
        <Grid container spacing={3}>
          {questionsQuery.data?.map((question) => {
            return (
              <Grid xs={12} sm={12} key={question.id} mb={2}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      color: "text.primary",
                      mb: 1,
                    }}
                  >
                    {question.question_title} {question.required && "*"}
                  </FormLabel>
                  {question.type === IOnboardingQuestionsDtoTypeEnum.Select ? (
                    <Select
                      required={question.required}
                      fullWidth
                      disabled={questionsMutation.isPending}
                      multiple
                      name={question.field_key}
                      onChange={(event) => {
                        handleChange(question.field_key, event.target.value as string[]);
                      }}
                      value={answer[question.field_key] || []}
                    >
                      {question?.options?.map((option) => (
                        <MenuItem
                          value={option}
                          disabled={questionsMutation.isPending}
                          key={option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <OutlinedInput
                      name={question.field_key}
                      disabled={questionsMutation.isPending}
                      required={question.required}
                      onChange={(event) => {
                        handleChange(question.field_key, event.target.value);
                      }}
                      inputProps={
                        question.type === IOnboardingQuestionsDtoTypeEnum.Number ? { min: 0 } : {}
                      }
                      type={
                        question.type === IOnboardingQuestionsDtoTypeEnum.Number ? "number" : "text"
                      }
                    />
                  )}
                  {/* has error */}
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            loading={questionsMutation.isPending}
          >
            Continuar
          </LoadingButton>
        </Box>
        {/* <Typography
          color="text.secondary"
          sx={{ mt: 3 }}
          variant="body2"
        >
          By submitting this, you agree to the{' '}
          <Link
            color="text.primary"
            href="#"
            underline="always"
            variant="subtitle2"
          >
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            color="text.primary"
            href="#"
            underline="always"
            variant="subtitle2"
          >
            Cookie Policy
          </Link>
          .
        </Typography> */}
      </>
    </form>
  );
};
