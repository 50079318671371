import { Box, Stack } from '@mui/system';
import React from 'react';
import { ChatBubble } from './chat-bubble';
import { Divider, Typography } from '@mui/material';
import { ChatMessage } from './chat-message';
import { useAppSelector } from 'src/app/hooks';
import { approvalEditSlice } from 'src/features/approval-edit/approvalEditSlice';

interface IChatBodyProps {}

export const ChatBody: React.FC<IChatBodyProps> = () => {
  const selectedChatCustomer = useAppSelector(
    approvalEditSlice.selectors.selectedApprovalStageCustomer
  );
  const comments = useAppSelector(approvalEditSlice.selectors.chatComments);
  console.log('🚀 ~ comments:', comments);
  const commentsCount = useAppSelector(
    approvalEditSlice.selectors.countCommentsFromSelectedCustomer
  );

  const ChatContent = () => {
    if (selectedChatCustomer === undefined) {
      return (
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'center',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign={'center'}
          >
            Selecione um aprovador para visualizar as observações
          </Typography>
        </Box>
      );
    }

    if (commentsCount === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'center',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign={'center'}
          >
            Nenhuma observação do {selectedChatCustomer?.customer.cust_name}
          </Typography>
        </Box>
      );
    }

    return (
      <Stack
        spacing={2}
        sx={{ flex: '1 1 auto', overflowY: 'auto', p: 3 }}
      >
        {comments.map((comment) => (
          <ChatBubble
            key={comment.aic_id}
            comment={comment}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      <ChatContent />
      <Box my={1}>
        <Divider />
      </Box>
      {selectedChatCustomer && (
        <Box p={1}>
          <ChatMessage />
        </Box>
      )}
    </Box>
  );
};
