import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { StatusTitle } from "src/components/status/StatusTitle";

interface IRevisionGenericWrapperProps {
  children?: React.ReactNode;
  title: string;
  description?: string;
  action?: React.ReactNode;
}

export const RevisionGenericWrapper: React.FC<IRevisionGenericWrapperProps> = ({
  children,
  title,
  description,
  action,
}) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <StatusTitle title={title} />
        {description && <Typography variant="body1">{description}</Typography>}
      </Stack>
      {children}

      {action && (
        <Stack direction="row" justifyContent="flex-end">
          {action}
        </Stack>
      )}
    </Stack>
  );
};
