import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export function formatPhoneNumber({
  phoneNumber,
  countryCode,
}: {
  phoneNumber: string;
  countryCode: string | null;
}): string {
  try {
    if (!countryCode)
      return phoneUtil.format(phoneUtil.parse(phoneNumber), PhoneNumberFormat.INTERNATIONAL);

    const number = phoneUtil.parse(phoneNumber, countryCode);
    return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
  } catch (error) {
    console.error("Erro ao formatar o número de telefone:", error);
    return phoneNumber;
  }
}
