import { useState, useEffect } from "react";
import ExpiredStorage from "expired-storage";

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  expire: number | boolean = 60 * 30,
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window !== "undefined") {
      try {
        const expiredStorage = new ExpiredStorage();
        const item = expiredStorage.getItem(key);
        const parsedInitialValue = JSON.stringify(initialValue);
        return item ? JSON.parse(item) : JSON.parse(parsedInitialValue);
      } catch (error) {
        console.error("Error when using useLocalStorage" + error);
        return initialValue;
      }
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        const expiredStorage = new ExpiredStorage();
        const valueToStore =
          storedValue instanceof Function ? storedValue(storedValue) : storedValue;
        if (expire === false) {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } else {
          expiredStorage.setItem(
            key,
            JSON.stringify(valueToStore),
            typeof expire === "number" ? expire : undefined,
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [expire, key, storedValue]);

  const setValue = (value: T): void => {
    setStoredValue(value);
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
