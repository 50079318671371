import type { FC } from "react";
import { useState } from "react";

import * as Yup from "yup";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { useFormik } from "formik";
import { useMounted } from "src/hooks/use-mounted";
import { IWorkspaceUpsertForm } from "src/contexts/workspace/workspace-form";
import PhotoForm from "src/components/upload/photo-form";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { LoadingButton } from "@mui/lab";
import { useWorkspaceForm } from "src/hooks/use-workspace-form";

interface WorkspaceOption {
  description: string;
  title: string;
  value: string;
}

interface JobWorkspaceStepProps {
  onNext?: () => void;
  onBack?: () => void;
}

interface Values {
  base64: string | undefined;
}

const getInitialValues = ({ photo }: Partial<IWorkspaceUpsertForm>): Values => {
  return {
    base64: photo?.base64 || undefined,
  };
};

const validationSchema = Yup.object({
  base64: Yup.string().optional(),
});

export const WorkspaceUpsertStep2Photo: FC<JobWorkspaceStepProps> = (props) => {
  const queryClient = useQueryClient();
  const [photoUpdatedAt, setPhotoUpdatedAt] = useState<Date | undefined>(new Date());
  const { api } = useThumbzApi();
  const [forceRender, setForceRender] = useState(false);
  const { state, dispatch } = useWorkspaceForm();

  const { onBack, onNext, ...other } = props;
  const isMounted = useMounted();

  const changeWorkspaceMutationPhoto = useMutation({
    mutationFn: api.workspace.workspaceControllerUpdatePhoto,
    onSuccess(data, variables, context) {
      toast.success("Avatar atualizado");

      setPhotoUpdatedAt(new Date());

      if (isMounted()) {
        dispatch({
          type: "SET_WORKSPACE_PHOTO",
          payload: {
            base64: data.wrk_photo?.ast_url || "",
          },
        });

        queryClient.refetchQueries({
          predicate: (query) => query.queryKey.includes("workspace"),
        });

        if (onNext) {
          onNext();
        }
      }
    },
    onError(error, variables, context) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(state.formState) || { base64: undefined },
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      if (!values.base64) {
        toast.error("Por favor, selecione um avatar");
        return;
      }

      if (!state.formState.details.wrk_id) {
        toast.error(
          "Ocorreu um erro ao tentar atualizar o avatar do workspace, atualize a página e tente novamente.",
        );
        return;
      }

      await changeWorkspaceMutationPhoto.mutateAsync({
        base64: values.base64,
        wrk_id: state.formState.details.wrk_id,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} {...other}>
        <div>
          <Typography variant="h6">Avatar do seu workspace</Typography>
          <Typography variant="caption">
            O avatar será exibido em vários lugares, principalmente para os aprovadores. Você pode
            alterar o avatar a qualquer momento.
          </Typography>
        </div>
        <PhotoForm
          key={`${state.formState.photo?.base64}?${photoUpdatedAt?.getTime()}`}
          default_photo_url={`${state.formState.photo?.base64}?${photoUpdatedAt?.getTime()}`}
          loading={changeWorkspaceMutationPhoto.isPending}
          onFormSuccessSubmit={function ({ base64 }: { base64: string | undefined }): void {
            formik.setFieldValue("base64", base64);
            formik.submitForm();
          }}
        />

        <Stack alignItems="center" direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={formik.isSubmitting}
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            variant="contained"
          >
            Próximo
          </LoadingButton>
          <Button color="inherit" onClick={onBack}>
            Voltar
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

WorkspaceUpsertStep2Photo.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
