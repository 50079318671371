import { createFileRoute } from "@tanstack/react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "src/app/hooks";
import { changePassword } from "src/features/auth/authSlice";
import { PageContainer } from "src/components/page-container";
import { CardActions, Stack } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";

export const Route = createFileRoute("/_authenticated/conta/geral/trocar-senha")({
  component: () => <Page />,
});

interface Values {
  password: string;
  submit: null;
}

const initialValues: Values = {
  password: "",
  submit: null,
};

const validationSchema = Yup.object({
  password: Yup.string().required("Senha é obrigatória"),
});

const Page = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(changePassword({ password: values.password }));

        router.navigate({
          from: "/conta/geral/trocar-senha",
          to: "/conta/geral",
          search: {
            username: values.password,
          },
        });
      } catch (err) {
        console.error(err);

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: (err as Error).message });
        helpers.setSubmitting(false);
      }
    },
  });

  usePageView();

  return (
    <PageContainer
      title={"Minha conta"}
      subtitle="Troque a sua senha"
      breadcrumbs={[
        {
          label: "Minha conta",
          href: "/conta/geral",
        },
        {
          label: "Trocar senha",
          href: "/conta/geral/trocar-senha",
        },
      ]}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <Card elevation={16}>
          <CardContent>
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Nova Senha"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              sx={{ mt: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.errors.submit && (
              <FormHelperText error sx={{ mt: 3 }}>
                {formik.errors.submit as string}
              </FormHelperText>
            )}
          </CardContent>
          <CardActions>
            <Stack direction="row" mt={-4} spacing={2} justifyContent="flex-end" flex={1} p={2}>
              <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                Trocar senha
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </PageContainer>
  );
};

export default Page;
