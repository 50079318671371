import { Avatar, AvatarProps } from "@mui/material";
import React, { useMemo } from "react";
import { getInitials } from "../../utils/get-initials";
import { isPhotoBase64 } from "src/utils/is-base-64";
import { Person } from "@mui/icons-material";

interface RenderAvatarProps {
  src: string | undefined | null;
  name?: string | undefined | null;
  variant?: AvatarProps["variant"];
  sx?: AvatarProps["sx"];
  alt?: string;
  updated_at?: Date | string;
  fallbackSize?: number;
}

export const RenderAvatar: React.FC<RenderAvatarProps> = ({
  src,
  name,
  variant = "rounded",
  updated_at,
  alt,
  sx,
  fallbackSize = 30,
}) => {
  const initials = useMemo(() => getInitials(name), [name]);
  const _updated_at = useMemo(() => {
    if (!updated_at) {
      return new Date().getTime();
    }

    return new Date(updated_at).getTime();
  }, [updated_at]);

  const srcAvatar = useMemo(() => {
    if (!src) {
      return undefined;
    }

    // if is base64
    if (isPhotoBase64(src)) {
      return src;
    }

    return `${src}${src.includes("?") ? "&" : "?"}updated_at=${_updated_at}`;
  }, [src, _updated_at]);

  return (
    <Avatar src={srcAvatar} variant={variant} {...(alt ? { alt } : {})} sx={sx}>
      {initials || <Person color="disabled" sx={{ width: fallbackSize, height: fallbackSize }} />}
    </Avatar>
  );
};
