import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Theme } from '@mui/material/styles/createTheme';
import { styled } from '@mui/material/styles';

interface CustomColorPillProps {
  children?: ReactNode;
  color?: string; // Hexadecimal color
  style?: object;
  sx?: SxProps<Theme>;
}

interface CustomColorPillRootProps {
  ownerState: {
    color: string;
  };
}

const CustomColorPillRoot = styled('span')<CustomColorPillRootProps>(({ theme, ownerState }) => {
  const backgroundColor = ownerState.color;
  const color =
    theme.palette.mode === 'dark'
      ? theme.palette.getContrastText(backgroundColor)
      : theme.palette.getContrastText(backgroundColor);

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: 12,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 2,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  };
});

export const CustomColorPill: FC<CustomColorPillProps> = (props) => {
  const { color = '#000000', children, ...other } = props;

  const ownerState = { color };

  return (
    <CustomColorPillRoot
      ownerState={ownerState}
      {...other}
    >
      {children}
    </CustomColorPillRoot>
  );
};

CustomColorPill.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string, // Hexadecimal color as string
};
