import { createFileRoute } from "@tanstack/react-router";
import type { ChangeEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import { queryKeys } from "src/config/query-key";
import { CompanyActivity } from "src/sections/dashboard/workspace/company-activity";
import { WorkspaceDetailsForm } from "src/sections/dashboard/workspace/workspace-details-form";
import { IWorkspace } from "src/services/thumbz-base-api";
import { useThumbzApi } from "src/services/thumbz-api";
import { WorkspaceCustomers } from "src/sections/dashboard/workspace/workspace-customers";
import { WorkspaceDashboard } from "src/sections/dashboard/workspace/workspace-dashboard";
import { ApprovalFlowTable } from "src/components/approval-flow-table";
import { PageContainer } from "src/components/page-container";
import { paths } from "src/paths";
import { ApprovalViewSelector } from "src/sections/approvals/approval-view-selector";
import useLocalStorage from "src/hooks/use-local-storage";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { layoutSlice, TabValue } from "src/features/layout/layoutSlice";
import { organizationSlice } from "src/features/organization/organizationSlice";

export const Route = createFileRoute("/_authenticated/workspaces/$wrk_id/")({
  component: () => <Page />,
});

const Page = () => {
  const isMounted = useMounted();
  const { org_id } = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const { wrk_id } = Route.useParams();
  const { api } = useThumbzApi();
  const [workspace, setWorkspace] = useState<IWorkspace | undefined>(undefined);
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(layoutSlice.selectors.selectedTab);
  const tabs = useAppSelector(layoutSlice.selectors.workspaceTabs);

  const props = {
    wrk_id: wrk_id,
    with_relations: true,
  };

  const workspaceQuery = useQuery({
    queryKey: queryKeys.workspace.get({ wrk_id: props.wrk_id }).queryKey,
    queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
    enabled: !!wrk_id && isMounted(),
  });

  useQuery({
    queryKey: queryKeys.approval.approvalControllerList({ wrk_id, org_id }).queryKey,
    queryFn: () => api.approval.approvalControllerList({ wrk_id, org_id }),
    enabled: !!wrk_id,
  });

  useQuery({
    queryKey: queryKeys.status.statusControllerFindAll().queryKey,
    queryFn: () => api.status.statusControllerFindAll(),
  });

  useEffect(() => {
    if (workspaceQuery.data) {
      setWorkspace(workspaceQuery.data);
    }
  }, [workspaceQuery.data]);

  usePageView();

  const handleTabsChange = useCallback(
    (_: ChangeEvent<unknown>, value: TabValue): void => {
      dispatch(layoutSlice.actions.selectTab(value));
    },
    [dispatch],
  );

  if (!wrk_id) {
    return <EmptyPlaceholder overlineText="Nenhum espaço de trabalho selecionado" />;
  }

  return (
    <>
      <PageContainer
        title={`Workspace: ${workspace?.wrk_name ?? ""}`}
        subtitle="Aqui você pode visualizar e gerenciar os detalhes do workspace selecionado."
        breadcrumbs={[
          {
            label: "Workspaces",
            href: "/workspaces",
          },
          {
            label: workspace?.wrk_name ?? "",
            href: paths.dashboard.workspaces.details({ wrk_id }),
          },
        ]}
      >
        <Stack spacing={2}>
          <Card elevation={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ px: 3, py: 0.5 }}
            >
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Stack>
          </Card>

          <Box>
            <RenderTab workspace={workspace} tab={currentTab} />
          </Box>
        </Stack>
      </PageContainer>
    </>
  );
};

const RenderTab: React.FC<{ tab: TabValue; workspace: IWorkspace | undefined }> = ({
  tab,
  workspace,
}) => {
  if (!workspace?.wrk_id) {
    return <Skeleton variant="rectangular" height={400} />;
  }

  switch (tab) {
    case TabValue.Dashboard:
      return <WorkspaceDashboard wrk_id={workspace.wrk_id} />;
    case TabValue.Approvals:
      return (
        <ApprovalViewSelector
          wrk_id={workspace.wrk_id}
          config={{
            show_apv_created_at: true,
            show_apv_name: true,
            show_stages: true,
            show_workspace: false,
          }}
        />
      );
    case TabValue.Flow:
      return <ApprovalFlowTable wrk_id={workspace.wrk_id} />;
    case TabValue.Details:
      return <WorkspaceDetailsForm workspace={workspace} />;
    case TabValue.Audit:
      return <CompanyActivity wrk_id={workspace.wrk_id} />;
    case TabValue.Approvers:
      return <WorkspaceCustomers wrk_id={workspace.wrk_id} />;
    default:
      return <EmptyPlaceholder overlineText="Nenhum espaço de trabalho selecionado" />;
  }
};

export default Page;
