import { useState, type FC } from "react";
import { format } from "date-fns";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import { getInitials } from "src/utils/get-initials";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { useMounted } from "src/hooks/use-mounted";
import {
  IAuditListActivitiesResponse,
  IAuditListActivitiesResponseDto,
} from "src/services/thumbz-base-api";
import { Skeleton } from "@mui/material";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import FormSection from "src/components/form/FormSection";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";

const renderContent = (activity: IAuditListActivitiesResponseDto): JSX.Element | null => {
  return (
    <Typography sx={{ mr: 0.5 }} variant="body2">
      <span style={{ fontWeight: "bold" }}>{activity.user.usu_name}</span>
      {` ${activity.act_message} em `}
      <span style={{ fontWeight: "bold" }}>{activity.workspace.wrk_name}</span>
    </Typography>
  );
};

interface CompanyActivityProps {
  wrk_id: string;
}

export const CompanyActivity: FC<CompanyActivityProps> = ({ wrk_id }) => {
  const [page, setPage] = useState(1);
  const isMounted = useMounted();
  const { api } = useThumbzApi();

  const activiesQuery = useInfiniteQuery<IAuditListActivitiesResponse>({
    queryKey: ["auditControllerListActivities", wrk_id],
    queryFn: ({ pageParam = 1 }) =>
      api.audits.auditControllerListActivities({
        wrk_id: wrk_id,
        page: Number(pageParam),
        size: 10,
      }),
    getNextPageParam: (lastPage) => {
      // Verifica se estamos na última página
      const currentPage = lastPage.page;
      const totalPages = lastPage.pages;

      if (currentPage < totalPages) {
        return (Number(currentPage) + 1) as number; // Retorna o número da próxima página
      }

      return undefined; // Não há mais páginas
    },
    initialData: undefined,
    initialPageParam: 1, // Adiciona a propriedade initialPageParam
    enabled: !!wrk_id && isMounted(),
  });

  const handleLoadMore = () => {
    if (activiesQuery.isFetching) return;
    activiesQuery.fetchNextPage();
  };

  if (activiesQuery.isError) {
    return <div>Erro: {activiesQuery.error.message}</div>;
  }

  return (
    <FormSection
      title="Histórico"
      description={
        <>
          Através do histórico, você pode visualizar todas as atividades realizadas no espaço de
          trabalho.
        </>
      }
      actions={undefined}
    >
      <Stack spacing={3} alignContent={"center"}>
        <Timeline
          sx={{
            p: 0,
            m: 0,
          }}
        >
          {activiesQuery?.data?.pages.flatMap((page) => page.data).length === 0 && (
            <EmptyPlaceholder
              overlineText="Não há atividades ainda"
              customSubtitle="As atividades serão mostradas aqui quando você ou os membros da sua equipe realizarem alguma ação."
              showElevation={false}
              showButton={false}
            />
          )}
          {activiesQuery?.isLoading &&
            [1, 2, 3, 4, 5].map((item) => (
              <TimelineItem
                key={item}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      border: 0,
                      p: 0,
                    }}
                  >
                    <Avatar />
                  </TimelineDot>
                  <TimelineConnector sx={{ minHeight: 30 }} />
                </TimelineSeparator>
                <TimelineContent>
                  {/* <Typography variant="body2">Carregando...</Typography> */}
                  <Skeleton variant="text" width="100%" height={50} />
                </TimelineContent>
              </TimelineItem>
            ))}
          {activiesQuery?.data &&
            activiesQuery?.data?.pages
              .flatMap((page) => page.data)
              .map((activity, index) => {
                const showConnector =
                  index !== activiesQuery?.data?.pages.flatMap((page) => page.data).length - 1;
                const createdAt = format(new Date(activity.act_created_at), "MMM dd, HH:mm a");

                return (
                  <TimelineItem
                    key={activity.act_id}
                    sx={{
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          border: 0,
                          p: 0,
                        }}
                      >
                        <RenderAvatar
                          name={activity.user.usu_name}
                          src={activity.user.usu_photo?.ast_url}
                        />
                      </TimelineDot>
                      {showConnector && <TimelineConnector sx={{ minHeight: 30 }} />}
                    </TimelineSeparator>
                    <TimelineContent>
                      {renderContent(activity)}
                      <Typography color="text.secondary" variant="caption" sx={{ mt: 1 }}>
                        {activity.user.usu_email} - {createdAt}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
        </Timeline>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button color="inherit" disabled={!activiesQuery?.hasNextPage} onClick={handleLoadMore}>
            {activiesQuery?.isFetchingNextPage ? "Carregando mais..." : "Carregar mais"}
          </Button>
        </Box>
      </Stack>
    </FormSection>
  );
};
