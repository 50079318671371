export function isPhotoBase64(str: string | undefined | null): boolean {
  try {
    if (!str) {
      return false;
    }
    return str.startsWith("data:image/");
  } catch (error) {
    return false;
  }
}
