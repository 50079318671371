interface AxiosError {
  response?: {
    data?: {
      message?: string[] | string;
    };
  };
  message?: string | string[];
}

export function getServerErrorMessage(err: AxiosError | Error | unknown): string {
  // Verifica se o erro possui uma mensagem no próprio nível superior
  if (typeof err === "object" && err !== null) {
    const axiosError = err as AxiosError;

    // Caso a mensagem esteja na raiz do erro
    if (Array.isArray(axiosError.message)) {
      return axiosError.message.join("\n");
    }
    if (typeof axiosError.message === "string") {
      return axiosError.message;
    }

    // Caso a mensagem esteja aninhada em response.data.message
    if (axiosError.response && axiosError.response.data) {
      const message = axiosError.response.data.message;

      if (Array.isArray(message)) {
        return message.join("\n");
      }

      if (typeof message === "string") {
        return message;
      }
    }
  }

  return "Ocorreu um erro, por favor tente novamente mais tarde.";
}

export function getServerErrorArrayMessage(err: AxiosError | Error | unknown): string[] {
  if (typeof err === "object" && err !== null) {
    const axiosError = err as AxiosError;

    if (Array.isArray(axiosError.message)) {
      return axiosError.message;
    }
    if (typeof axiosError.message === "string") {
      return [axiosError.message];
    }

    if (axiosError.response && axiosError.response.data) {
      const message = axiosError.response.data.message;

      if (Array.isArray(message)) {
        return message;
      }
      if (typeof message === "string") {
        return [message];
      }
    }
  }

  const isMsgPresentAndString =
    typeof err === "object" && err !== null && "message" in err && typeof err.message === "string";

  if (err instanceof Error && isMsgPresentAndString) {
    return [err.message];
  }

  if (isMsgPresentAndString) {
    return [String(err.message)];
  }

  return ["Ocorreu um erro, por favor tente novamente mais tarde."];
}
