import "./RichTextRenderer.css";

interface RichTextRendererProps {
  content: string;
}

export function RichTextRenderer({ content }: RichTextRendererProps) {
  return (
    <>
      <div
        className="rich-text-content"
        dangerouslySetInnerHTML={{ __html: content }}
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      />
    </>
  );
}
