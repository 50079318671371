import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
} from "@mui/material";

interface ApprovalFlowUpsertFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (flowName: string, flowDescription: string) => void;
}

export const ApprovalFlowUpsertForm: React.FC<ApprovalFlowUpsertFormProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [flowName, setFlowName] = useState("");
  const [flowDescription, setFlowDescription] = useState("");

  const handleSubmit = () => {
    onSubmit(flowName, flowDescription);
    setFlowName("");
    setFlowDescription("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Novo Fluxo de Aprovação</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Nome do Fluxo"
            variant="outlined"
            fullWidth
            value={flowName}
            onChange={(e) => setFlowName(e.target.value)}
          />
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={flowDescription}
            onChange={(e) => setFlowDescription(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
