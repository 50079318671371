import React, { useState } from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react"; // React Data Grid Component
import { ColDef } from "ag-grid-community";
import { Box, Card, CardActions, CardHeader, useTheme } from "@mui/material";
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
interface AgGridReactDefaultProps<T> extends AgGridReactProps<T> {
  actions?: React.ReactNode;
}

export const AgGridReactDefault = <T,>(props: AgGridReactDefaultProps<T>) => {
  const theme = useTheme();

  return (
    // wrapping container with theme & size
    <Card>
      {/* {props.header && <CardHeader title={props.header} />} */}
      <CardActions>{props.actions}</CardActions>
      <Box
        className={theme.palette.mode === "dark" ? "ag-theme-material-dark" : "ag-theme-material"}
        height={500}
      >
        <AgGridReact<T>
          {...props}
          loadingOverlayComponent={"customLoadingOverlay"}
          overlayLoadingTemplate='<span class="ag-overlay-loading-center">Carregando...</span>'
          localeText={AG_GRID_LOCALE_BR}
        />
      </Box>
    </Card>
  );
};
