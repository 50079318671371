import { Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { DashboardApprovalOverview } from "../../../components/dashboard/dashboard-approval-overview";
import { DashboardLastApprovals } from "../../../components/dashboard/dashboard-last-approvals";
import { DashboardRevisionsStatus } from "../../../components/dashboard/dashboard-revisions-status";
import { DashboardTotalApprovals } from "../../../components/dashboard/dashboard-total-approvals";
import { SplashScreen } from "../../../components/splash-screen";
import { queryKeys } from "../../../config/query-key";
import { organizationSlice } from "../../../features/organization/organizationSlice";
import { useThumbzApi } from "../../../services/thumbz-api";

interface WorkspaceDashboardProps {
  wrk_id: string | undefined;
}

export const WorkspaceDashboard: React.FC<WorkspaceDashboardProps> = ({ wrk_id }) => {
  const { api } = useThumbzApi();
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);

  const params = {
    startDate: "2021-10-01",
    endDate: "2025-10-31",
    wrk_id: wrk_id,
    org_id: selectedOrganization.org_id,
  };

  const query = useQuery({
    queryKey: queryKeys.dashboard.dashboardControllerGetDashboardReport(params).queryKey,
    queryFn: () => api.dashboard.dashboardControllerGetDashboardReport(params),
  });

  return (
    <Stack>
      <DashboardApprovalOverview query={query} />
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xl={6} xs={12}>
            <DashboardTotalApprovals query={query} />
          </Grid>
          <Grid item xl={6} xs={12}>
            <DashboardRevisionsStatus query={query} />
          </Grid>
          <Grid item xs={12}>
            <DashboardLastApprovals query={query} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
