import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { SplashScreen } from "src/components/splash-screen";
import { authSlice, authThunks } from "src/features/auth/authSlice";
import { useThumbzApi } from "src/services/thumbz-api";
import { createClient } from "src/utils/supabase/client";
import * as Sentry from "@sentry/react";

interface IAuthGuardProps {
  children: React.ReactNode;
}

export const AuthGuard: React.FC<IAuthGuardProps> = ({ children }) => {
  const { api } = useThumbzApi();
  const supabase = createClient();
  const loggeduser = useAppSelector(authSlice.selectors.selectUser);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const hydrateUser = useCallback(async () => {
    try {
      const { data } = await supabase.auth.getSession();
      if (!data.session) {
        // Não faça o signOut imediato; apenas pare aqui e defina o loading
        setLoading(false);
        return;
      }

      if (!loggeduser) {
        const loginResponse = await api.auth.authControllerLogin({
          firebase_idToken: data.session.user.id,
        });

        if (loginResponse?.user?.usu_name && loginResponse?.user?.usu_external_uid) {
          Sentry.setUser({
            email: data.session.user.email,
            id: loginResponse.user.usu_external_uid,
            username: loginResponse.user.usu_name,
          });
        }

        await dispatch(
          authThunks.handleAuthToken({
            event: "SIGNED_IN",
            session: data.session,
          }),
        );
        await dispatch(
          authThunks.handleAuthSignIn({
            user: loginResponse.user,
            supabaseUser: data.session.user,
            supabaseSession: data.session,
            favoriteOrganization: loginResponse.favorite_organization,
          }),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Defina o loading para falso, independentemente do resultado
      setLoading(false);
    }
  }, [api.auth, dispatch, loggeduser, supabase.auth]);

  useEffect(() => {
    hydrateUser();
  }, [hydrateUser]);

  // Mostre a SplashScreen enquanto está carregando
  if (loading) {
    return <SplashScreen />;
  }

  return <>{loggeduser ? <>{children}</> : <SplashScreen />}</>;
};
