import { DragIndicatorOutlined, MoreHoriz } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { Trash01 } from "@untitled-ui/icons-react";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useAppDispatch } from "src/app/hooks";
import { InstagramRenderImgPreview } from "src/components/instagram-render-img-preview";
import { approvalEditThunks } from "src/features/approval-edit/approvalEditSlice";
import { IApprovalItemAsset } from "src/services/thumbz-base-api";

interface ApprovalItemAssetReorderProps {
  itemAsset: IApprovalItemAsset;
}

export const ApprovalItemAssetReorder: React.FC<ApprovalItemAssetReorderProps> = ({
  itemAsset,
}) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDeleteAsset() {
    confirm({
      description: "Tem certeza que deseja excluir esse material?",
      confirmationText: "Sim",
      cancellationText: "Não",
    }).then(() =>
      dispatch(approvalEditThunks.deleteApprovalItemAsset({ aia_id: itemAsset.aia_id })),
    );
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? `item-asset-row-popover-${itemAsset.aia_id}` : undefined;

  const PopOverMenu = () => (
    <List dense>
      <ListItemButton onClick={handleDeleteAsset}>
        <ListItemIcon>
          <Trash01 />
        </ListItemIcon>
        <ListItemText primary="Excluir" />
      </ListItemButton>
    </List>
  );

  return (
    <Stack
      borderRadius={1}
      border={1}
      style={{
        borderColor: palette.divider,
      }}
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      flex={1}
    >
      <Box mr={2} />
      <DragIndicatorOutlined
        style={{
          color: palette.neutral[300],
        }}
      />

      <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1} flexGrow={1}>
        <Box height={40} width={40}>
          <InstagramRenderImgPreview
            file={null}
            mimetype={itemAsset.asset.ast_mimetype}
            already_uploaded_url={itemAsset.asset.ast_url}
            size={40}
          />
        </Box>

        <Typography>{itemAsset?.asset?.ast_original_filename ?? "Sem nome"}</Typography>
      </Stack>

      <IconButton aria-describedby={popoverId} onClick={handleClick}>
        <MoreHoriz
          style={{
            color: palette.text.secondary,
          }}
        />
      </IconButton>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <PopOverMenu />
      </Popover>
    </Stack>
  );
};
