import { Icon, Typography } from '@mui/material';
import { Stack } from '@mui/system';

export const GenericHint: React.FC<{
  text: string;
  IconAsset?: React.ElementType;
}> = ({ text, IconAsset }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      alignContent={'flex-start'}
      spacing={1}
    >
      {IconAsset && (
        <Icon fontSize="small">
          <IconAsset fontSize="small" />
        </Icon>
      )}
      <Typography variant="body2">{text}</Typography>
    </Stack>
  );
};
