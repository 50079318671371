import { Chip, Tooltip } from '@mui/material';
import React from 'react';
import { IApprovalItemRevisionAirStatusEnum } from 'src/services/thumbz-base-api';
import { green, grey, red } from '@mui/material/colors';

interface IStatusConfig {
  color: string;
  label: string;
  tooltip: string;
}

const STATUS_CONFIG: Record<IApprovalItemRevisionAirStatusEnum, IStatusConfig> = {
  [IApprovalItemRevisionAirStatusEnum.Approved]: {
    color: green[500],
    label: 'Aprovado',
    tooltip: 'Todos os aprovadores aprovaram esse item.',
  },
  [IApprovalItemRevisionAirStatusEnum.Requested]: {
    color: red[500],
    label: 'Mudanças Solicitadas',
    tooltip: 'Mudanças foram solicitadas para esse item.',
  },
  [IApprovalItemRevisionAirStatusEnum.Pending]: {
    color: grey[500],
    label: 'Aguardando Aprovação',
    tooltip: 'Ninguém aprovou esse item ainda.',
  },
};

// Valor padrão para status desconhecidos
const DEFAULT_STATUS: IStatusConfig = {
  color: grey[500],
  label: 'Status Desconhecido',
  tooltip: 'O status deste item não é reconhecido.',
};

// Tipagem do componente
interface IItemStatusProps {
  status: IApprovalItemRevisionAirStatusEnum;
}

export const ItemStatus: React.FC<IItemStatusProps> = ({ status }) => {
  const { color, label, tooltip } = STATUS_CONFIG[status] || DEFAULT_STATUS;

  return (
    <Tooltip
      title={tooltip}
      arrow
    >
      <Chip
        label={label}
        variant="outlined"
        sx={{ backgroundColor: color, color: 'white' }}
      />
    </Tooltip>
  );
};
