import React, { createContext, useReducer, useEffect } from "react";
import { IUpsertPersonalizationDto, IWorkspace } from "src/services/thumbz-base-api";

export interface IWorkspaceUpsertForm {
  photo: {
    base64?: string;
  };
  details: {
    wrk_id: string | undefined;
    wrk_name: string;
    wrk_description: string | undefined;
    wrk_slug: string;
  };
  personalization: IUpsertPersonalizationDto;
}

export const initialWorkspaceValues: IWorkspaceUpsertForm = {
  photo: {
    base64: undefined,
  },
  details: {
    wrk_id: undefined,
    wrk_name: "",
    wrk_slug: "",
    wrk_description: undefined,
  },
  personalization: {
    per_id: undefined,
    per_primary_hex_color: "",
    per_text_hex_color: "",
    per_url: "",
  },
};

// Define the State Type
type State = {
  formState: IWorkspaceUpsertForm;
  activeStep: number;
  isComplete: boolean;
  workspace?: IWorkspace;
};

const initialState: State = {
  formState: initialWorkspaceValues,
  activeStep: 0,
  isComplete: false,
};

type Action =
  | {
      type: "SET_WORKSPACE_PHOTO";
      payload: {
        base64: string;
      };
    }
  | {
      type: "SET_WORKSPACE_DETAILS";
      payload: {
        wrk_description: string;
        wrk_name: string;
        wrk_id: string | undefined;
        wrk_slug: string;
      };
    }
  | {
      type: "SET_WORKSPACE_PERSONALIZATION";
      payload: IUpsertPersonalizationDto;
    }
  | { type: "NEXT_STEP" }
  | { type: "PREV_STEP" }
  | { type: "COMPLETE_FORM"; payload: IWorkspace };

// Create the context
export const WorkspaceFormContext = createContext<
  { state: State; dispatch: React.Dispatch<Action> } | undefined
>(undefined);

const workspaceFormReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_WORKSPACE_PHOTO":
      return {
        ...state,
        formState: {
          ...state.formState,
          photo: {
            base64: action.payload.base64,
          },
        },
      };
    case "SET_WORKSPACE_DETAILS":
      return {
        ...state,
        formState: {
          ...state.formState,
          details: {
            wrk_id: action.payload.wrk_id,
            wrk_slug: action.payload.wrk_slug,
            wrk_description: action.payload.wrk_description,
            wrk_name: action.payload.wrk_name,
          },
        },
      };
    case "SET_WORKSPACE_PERSONALIZATION":
      return {
        ...state,
        formState: {
          ...state.formState,
          personalization: {
            ...action.payload,
          },
        },
      };
    case "NEXT_STEP":
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case "PREV_STEP":
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case "COMPLETE_FORM":
      return {
        ...state,
        workspace: action.payload,
        isComplete: true,
      };
    default:
      return state;
  }
};

interface WorkspaceFormProviderProps {
  children: React.ReactNode;
}

function loadStateFromLocalStorage(): State {
  const savedState = localStorage.getItem("workspaceFormState");
  return savedState ? JSON.parse(savedState) : initialState;
}

export const WorkspaceFormProvider: React.FC<WorkspaceFormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(workspaceFormReducer, loadStateFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("workspaceFormState", JSON.stringify(state));

    return () => {
      localStorage.removeItem("workspaceFormState");
    };
  }, [state]);

  return (
    <WorkspaceFormContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkspaceFormContext.Provider>
  );
};
