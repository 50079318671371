import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  FormHelperText,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IApproval, IApprovalUpsertDto } from "src/services/thumbz-base-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { Add } from "@mui/icons-material";
import { useRouter } from "src/hooks/use-router";
import { layoutSlice, TabValue } from "src/features/layout/layoutSlice";

interface ApprovalUpsertFormProps {
  open: boolean;
  approval: IApproval | undefined;
  wrk_id: string;
  onClose: () => void;
  onSave: (approval: Partial<IApproval>) => void;
}

interface IFormApprovalUpsertDto
  extends Pick<IApprovalUpsertDto, "apv_name" | "flow_id" | "apt_id"> {}

const validationSchema = Yup.object<IFormApprovalUpsertDto>({
  apv_name: Yup.string().required("Nome da Aprovação é obrigatório"),
  flow_id: Yup.string().required("Fluxo é obrigatório"),
  apt_id: Yup.string().required("Tipo de aprovação é obrigatório"),
});

export const ApprovalUpsertForm: React.FC<ApprovalUpsertFormProps> = ({
  open,
  approval,
  wrk_id,
  onClose,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const org_id = useAppSelector(organizationSlice.selectors.selectedOrganization)?.org_id;
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();
  const initialvalues = useMemo(
    () => ({
      ...approval,
    }),
    [approval],
  );

  const approvalFlowsQuery = useQuery({
    queryKey: queryKeys.approvalFlow.approvalFlowControllerList({ wrk_id }).queryKey,
    queryFn: () => api.approvalFlow.approvalFlowControllerList({ wrk_id }),
    enabled: !!wrk_id,
  });

  const approvalTypesQuery = useQuery({
    queryKey: queryKeys.approvalTypes.approvalTypeControllerList({ org_id }).queryKey,
    queryFn: () => api.approvalTypes.approvalTypeControllerList({ org_id }),
    enabled: !!org_id,
  });

  const initialValues = {
    apv_name: approval?.apv_name || "",
    flow_id: approval?.approvalFlow?.flow_id || approvalFlowsQuery.data?.[0]?.flow_id || "",
    apt_id: approval?.approvalType.apt_id || approvalTypesQuery.data?.[0]?.apt_id || "",
    apv_id: approval?.apv_id || "",
    wrk_id: wrk_id,
  };

  function handleClearCache() {
    queryClient.invalidateQueries({
      queryKey: queryKeys.approval.approvalControllerList({ wrk_id, org_id }).queryKey,
    });
  }

  const upsertMutation = useMutation({
    mutationFn: api.approval.approvalControllerUpsert,
    onSuccess: (data) => {
      handleClearCache();
      formik.resetForm();
      onSave(data);
      onClose();
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  function handleSubmission(values: IFormApprovalUpsertDto) {
    upsertMutation.mutate({
      ...values,
      apv_id: approval?.apv_id,
      wrk_id,
    });
  }

  function handleCreateNewFlow() {
    onClose();
    dispatch(layoutSlice.actions.selectTab(TabValue.Flow));
  }

  const formik = useFormik<IFormApprovalUpsertDto>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmission,
  });

  useEffect(() => {
    // Only update form values if they are different from the current approval values
    // This check prevents unnecessary updates and avoids the infinite loop
    if (JSON.stringify(formik.values) !== JSON.stringify(initialvalues)) {
      formik.setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approval]); // Only re-run this effect if `approval` changes

  const selectedFlowStages =
    approvalFlowsQuery.data?.find((flow) => flow.flow_id === formik.values.flow_id)?.stages || [];

  const selectedApprovalType = approvalTypesQuery.data?.find(
    (type) => type.apt_id === formik.values.apt_id,
  );

  function handleClose() {
    if (upsertMutation.isPending) return;
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* <DialogTitle>{approval?.apv_id ? 'Editar' : 'Nova'}</DialogTitle> */}
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            <TextField
              autoFocus
              margin="dense"
              label="Crie um nome para a sua aprovação"
              type="text"
              fullWidth
              {...formik.getFieldProps("apv_name")}
              error={formik.touched.apv_name && Boolean(formik.errors.apv_name)}
              helperText={
                formik.touched.apv_name && formik.errors.apv_name
                  ? formik.errors.apv_name
                  : "O nome da aprovação será utilizado para identificar a aprovação"
              }
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="flow-select-label">Modelo de fluxo</InputLabel>
              <Select
                labelId="flow-select-label"
                id="flow-select"
                {...formik.getFieldProps("flow_id")}
                error={formik.touched.flow_id && Boolean(formik.errors.flow_id)}
                label="Modelo de fluxo"
              >
                {approvalFlowsQuery.data?.map((flow) => (
                  <MenuItem key={flow.flow_id} value={flow.flow_id}>
                    {flow.flow_name}
                  </MenuItem>
                ))}

                <Divider />

                <MenuItem value="" onClick={handleCreateNewFlow}>
                  <Add color="primary" fontSize="small" style={{ marginRight: "8px" }} />
                  Criar novo modelo
                </MenuItem>
              </Select>
              {formik.touched.flow_id && formik.errors.flow_id && (
                <FormHelperText error>{formik.errors.flow_id}</FormHelperText>
              )}

              {selectedFlowStages.length > 0 && (
                <FormHelperText>
                  {selectedFlowStages.map((stage) => stage.afs_name).join(" -> ")}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel id="type-select-label">Tipo de aprovação</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                {...formik.getFieldProps("apt_id")}
                error={formik.touched.apt_id && Boolean(formik.errors.apt_id)}
                label="Tipo de aprovação"
              >
                {approvalTypesQuery.data?.map((type) => (
                  <MenuItem key={type.apt_id} value={type.apt_id}>
                    {type.apt_name}
                  </MenuItem>
                ))}

                <Divider />

                <MenuItem disabled value="">
                  <Add color="primary" fontSize="small" style={{ marginRight: "8px" }} />
                  Criar novo tipo (em breve)
                </MenuItem>
              </Select>

              {formik.touched.apt_id && formik.errors.apt_id && (
                <FormHelperText error>{formik.errors.apt_id}</FormHelperText>
              )}

              {selectedApprovalType && (
                <FormHelperText>{selectedApprovalType.apt_description}</FormHelperText>
              )}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={upsertMutation.isPending} onClick={onClose} color="primary">
            Cancelar
          </Button>
          <LoadingButton loading={upsertMutation.isPending} type="submit" color="primary">
            {approval?.apv_id ? "Salvar" : "Criar"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
