import React, { useState } from 'react';
import { Tooltip, Stack, Typography, Box } from '@mui/material';
import { useThumbzApi } from 'src/services/thumbz-api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query-key';
import { IApprovalStage, ICustomer } from 'src/services/thumbz-base-api';
import toast from 'react-hot-toast';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { useConfirm } from 'material-ui-confirm';
import { CustomerUpsertForm } from '../customers/customer-upsert-form';
import NewCustomerAvatar from 'src/components/avatar/NewCustomerAvatar';
import CustomerAvatarWithIcons from 'src/components/avatar/CustomerAvatarWithIcons';

interface ApprovalCustomersAvatarProps {
  stage: IApprovalStage;
  wrk_id: string;
  wrk_slug: string;
}

export const ApprovalCustomersAvatar: React.FC<ApprovalCustomersAvatarProps> = ({
  stage,
  wrk_id,
  wrk_slug,
}) => {
  const [openCustomerTable, setOpenCustomerTable] = useState<boolean>(false);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  // Query to get all customers
  const allCustomersQuery = useQuery({
    queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
    queryFn: () => api.customer.customerControllerList({ wrk_id }),
    enabled: !!wrk_id,
  });

  // Query to get selected customers
  const selectedCustomersQuery = useQuery({
    queryFn: () => api.approvalStage.approvalStageControllerFind(stage.stg_id),
    enabled: !!stage.stg_id,
    queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
  });

  const clearCache = () => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.includes('approvalStage') && query.queryKey.includes(stage.stg_id),
    });

    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'customer' && query.queryKey.includes(wrk_id),
    });
  };

  // Atualiza o cache para refletir a mudança de seleção (vinculação/desvinculação)
  const updateCacheAfterToggle = (customerId: string, isSelected: boolean) => {
    queryClient.setQueryData(
      queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
      (oldData: any) => {
        if (!oldData) return oldData;

        const updatedData = { ...oldData };
        if (isSelected) {
          // Remove customer from selected customers
          updatedData.approvalStageCustomers = updatedData.approvalStageCustomers.filter(
            (sc: any) => sc.customer.cust_id !== customerId
          );
        } else {
          // Add customer to selected customers
          const customerToAdd = (allCustomersQuery.data ?? []).find(
            (c: ICustomer) => c.cust_id === customerId
          );
          if (customerToAdd) {
            updatedData.approvalStageCustomers.push({ customer: customerToAdd });
          }
        }
        return updatedData;
      }
    );
  };

  const linkCustomerToStageMutation = useMutation({
    mutationFn: api?.approvalStage?.approvalStageControllerLink,
    onMutate: async ({ cust_id }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
      });

      const previousData = queryClient.getQueryData(
        queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey
      );

      updateCacheAfterToggle(cust_id, false); // Atualização otimista, adiciona o cliente imediatamente

      return { previousData }; // Retorna o estado anterior para rollback, caso precise
    },
    onSuccess: (_, { cust_id }) => {
      toast.success('Aprovador vinculado com sucesso');
      updateCacheAfterToggle(cust_id, false);
      clearCache();
      setOpenCustomerTable(false);
    },
    onError: (error, _, context) => {
      // Se der erro, faz rollback do cache usando o estado anterior
      queryClient.setQueryData(
        queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
        context?.previousData
      );
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
  });

  const unlinkCustomerMutation = useMutation({
    mutationFn: api?.approvalStage?.approvalStageControllerUnlink,
    onMutate: async ({ cust_id }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
      });

      const previousData = queryClient.getQueryData(
        queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey
      );

      updateCacheAfterToggle(cust_id, true); // Atualização otimista, remove o cliente imediatamente

      return { previousData }; // Retorna o estado anterior para rollback
    },
    onSuccess: (_, { cust_id }) => {
      toast.success('Aprovador removido com sucesso');
    },
    onError: (error, _, context) => {
      // Se der erro, faz rollback do cache
      queryClient.setQueryData(
        queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
        context?.previousData
      );
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
  });

  const handleToggleSelection = (customer: ICustomer, isSelected: boolean) => {
    if (isSelected) {
      unlinkCustomerMutation.mutate({
        stg_id: stage.stg_id,
        cust_id: customer.cust_id,
      });
    } else {
      linkCustomerToStageMutation.mutate({
        cust_id: customer.cust_id,
        stg_id: stage.stg_id,
      });
    }
  };

  const handleAdd = () => {
    setOpenCustomerTable(true);
  };

  const handleCustomerUpsertSuccess = (customer: ICustomer) => {
    linkCustomerToStageMutation.mutate({
      cust_id: customer.cust_id,
      stg_id: stage.stg_id,
    });
  };

  if (allCustomersQuery.isLoading || selectedCustomersQuery.isLoading) {
    return <div>Carregando...</div>;
  }

  if (allCustomersQuery.isError || selectedCustomersQuery.isError) {
    return <div>Erro ao carregar os dados</div>;
  }

  // Convert selected customers to a map for easy lookup
  const selectedCustomersMap = new Map(
    selectedCustomersQuery.data?.approvalStageCustomers.map((stageCustomer) => [
      stageCustomer.customer.cust_id,
      true,
    ])
  );

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap" // Adiciona o comportamento de quebra para a próxima linha
        gap={2} // Define o espaço entre os itens
      >
        {(allCustomersQuery.data ?? []).map((customer: ICustomer) => {
          const isSelected = selectedCustomersMap.has(customer.cust_id);

          return (
            <Tooltip
              key={`customer-${customer.cust_id}`}
              title={
                <Stack direction="column">
                  <Typography variant="caption">{customer.cust_email}</Typography>
                  <Typography variant="caption">{customer.cust_mobile_phone}</Typography>
                </Stack>
              }
            >
              <Box onClick={() => handleToggleSelection(customer, isSelected)}>
                <CustomerAvatarWithIcons
                  customer={customer}
                  showIcon={isSelected}
                />
              </Box>
            </Tooltip>
          );
        })}
        <NewCustomerAvatar onClick={handleAdd} />
      </Box>

      <CustomerUpsertForm
        open={openCustomerTable}
        customer={null}
        wrk_id={wrk_id}
        onClose={() => setOpenCustomerTable(false)}
        onSuccess={handleCustomerUpsertSuccess}
        showSelect={false}
      />
    </>
  );
};

export default ApprovalCustomersAvatar;
