import { createFileRoute } from "@tanstack/react-router";

import { usePageView } from "src/hooks/use-page-view";
import { WorkspaceDashboard } from "src/sections/dashboard/workspace/workspace-dashboard";
import { PageContainer } from "src/components/page-container";

export const Route = createFileRoute("/_authenticated/organizacao/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  return (
    <PageContainer
      title={"Dashboard da organização"}
      subtitle="Acompanhe o desempenho da organização e seus workspaces."
    >
      <WorkspaceDashboard wrk_id={undefined} />
    </PageContainer>
  );
};
