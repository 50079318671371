import { WhatsApp, EmailOutlined } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Switch } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { StatusTitle } from "src/components/status/StatusTitle";
import { IApproval } from "src/services/thumbz-base-api";
import { RevisionGenericWrapper } from "./revision-generic-wrapper";

interface IRevisionNotificationsProps {
  approval: IApproval;
}

export const RevisionNotifications: React.FC<IRevisionNotificationsProps> = () => {
  return (
    <>
      <Stack flex={1} flexGrow={1}>
        <RevisionGenericWrapper
          title={"Notificações"}
          description={`Defina como os aprovadores serão notificados`}
        >
          <List>
            <ListItem>
              <ListItemIcon>
                <WhatsApp />
              </ListItemIcon>
              <ListItemText
                primary="WhatsApp"
                id="switch-list-label-wifi"
                secondary="Todos os aprovadores receberão uma mensagem no WhatsApp"
              />
              <Switch edge="end" checked={true} readOnly disabled />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmailOutlined />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-bluetooth"
                primary="E-mail"
                secondary="Todos os aprovadores receberão um e-mail"
              />
              <Switch edge="end" checked={true} readOnly disabled />
            </ListItem>
          </List>
        </RevisionGenericWrapper>
      </Stack>
    </>
  );
};
