import React from 'react';
import { ApprovalUpload } from './approval-upload';
import { IApproval } from 'src/services/thumbz-base-api';
import { Stack, Typography } from '@mui/material';

interface IApprovalTabApprovalProps {
  wrk_id: string;
  approval: IApproval;
}

export const ApprovalTabApproval: React.FC<IApprovalTabApprovalProps> = ({ wrk_id, approval }) => {
  return (
    <Stack
      flex={1}
      flexGrow={1}
    >
      <ApprovalUpload approval={approval} />
    </Stack>
  );
};
