import { useState, type FC, type ReactNode } from "react";
import { useWorkspaces } from "src/hooks/use-workspaces";
import { SplashScreen } from "src/components/splash-screen";
import { WorkspaceFormProvider } from "src/contexts/workspace/workspace-form";
import { Stack, Box } from "@mui/system";
import { PageContainer } from "src/components/page-container";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { Button, Card, CardContent } from "@mui/material";
import { WorkspaceUpsertStep1Details } from "src/sections/dashboard/workspace/workspace-upsert-step-1-details";
import { useRouter } from "@tanstack/react-router";
import toast from "react-hot-toast";

interface WorkspaceGuardProps {
  children: ReactNode;
}

interface WrapperProps {
  children: ReactNode;
}

const Wrapper: FC<WrapperProps> = ({ children }) => {
  return (
    <PageContainer>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        mt={-20}
      >
        {children}
      </Stack>
    </PageContainer>
  );
};

export const WorkspaceGuard: FC<WorkspaceGuardProps> = (props) => {
  const { children } = props;
  const { data, isLoading } = useWorkspaces();
  const hasWorkspace = (data ?? []).length > 0;
  const [hasAcknowledge, sethasAcknowledge] = useState<boolean>(hasWorkspace ? true : false);
  const router = useRouter();

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!hasAcknowledge) {
    return (
      <Wrapper>
        <Box maxWidth={"sm"}>
          <EmptyPlaceholder
            overlineText="Boas-vindas ao Thumbz!"
            customSubtitle={
              "Parece que você ainda não tem um espaço de trabalho. Um workspace é onde você organizará suas aprovações e projetos. Vamos começar criando o seu primeiro workspace!"
            }
            showButton={false}
          >
            <Button
              variant="contained"
              onClick={() => {
                sethasAcknowledge(true);
              }}
            >
              Criar meu primeiro workspace
            </Button>
          </EmptyPlaceholder>
        </Box>
      </Wrapper>
    );
  }

  if (!hasWorkspace) {
    return (
      <Wrapper>
        <Box maxWidth={"md"}>
          <Card>
            <CardContent>
              <WorkspaceFormProvider>
                <WorkspaceUpsertStep1Details
                  onNext={({ wrk_id }) => {
                    const messages = [
                      "O seu workspace foi criado com sucesso 🎉",
                      `Dica: Você pode criar mais workspaces clicando no botão de  no menu lateral`,
                      "Comece criando sua primeira aprovação agora",
                    ];

                    messages.forEach((message, index) => {
                      setTimeout(() => {
                        toast.success(message, {
                          duration: 4000,
                          position: "bottom-center",
                        });
                      }, index * 1000);
                    });

                    router.navigate({
                      to: "/workspaces/$wrk_id",
                      params: { wrk_id },
                    });
                  }}
                />
              </WorkspaceFormProvider>
            </CardContent>
          </Card>
        </Box>
      </Wrapper>
    );
  }

  return <>{children}</>;
};
