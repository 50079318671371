import { useState, type FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  CircularProgress,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import {
  IOrganization,
  IOrganizationInviteDtoRoleConstantEnum,
} from 'src/services/thumbz-base-api';
import { useThumbzApi } from 'src/services/thumbz-api';
import { useMounted } from 'src/hooks/use-mounted';
import toast from 'react-hot-toast';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { LoadingButton } from '@mui/lab';
import { queryKeys } from 'src/config/query-key';

interface IOrganizationInviteByEmailProps {
  organization: IOrganization;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('Required'),
  role: Yup.string()
    .oneOf(Object.values(IOrganizationInviteDtoRoleConstantEnum), 'Papel inválido')
    .required('Required'),
});

export const OrganizationInviteByEmailModal: FC<IOrganizationInviteByEmailProps> = ({
  organization,
  open,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const isMounted = useMounted();
  const { api } = useThumbzApi();

  const mutation = useMutation({
    mutationFn: api.organization.organizationControllerInvite,
    onSuccess: () => {
      toast.success('Convite enviado para o email ' + formik.values.email);
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          queryKeys.organization
            .invitations({ org_id: organization.org_id })
            .queryKey.find((key) => queryKey.includes(key)) !== undefined,
      });
      formik.resetForm();
      setOpen(false);
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const rolesQuery = useQuery({
    queryKey: queryKeys.autocomplete.roles.queryKey,
    queryFn: api.autocomplete.autocompleteControllerRoles,
  });
  const rolesData = rolesQuery?.data?.options ?? [];

  const formik = useFormik({
    initialValues: {
      email: '',
      role: IOrganizationInviteDtoRoleConstantEnum.MEMBER, // Definir valor inicial para o papel
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutation.mutate({
        email: values.email,
        org_id: organization.org_id,
        role_constant: values.role,
      });
    },
  });

  function handleClose() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{'Convidar um Novo Membro da Equipe'}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Insira o endereço de email da pessoa que você deseja convidar para esta organização. Ela
          receberá um convite para se juntar à equipe.
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <FormControl
            fullWidth
            sx={{ mt: 2 }}
          >
            <InputLabel id="role-label">Papel</InputLabel>
            {!rolesQuery.isLoading ? (
              <Select
                labelId="role-label"
                id="role"
                name="role"
                label="Papel"
                disabled={rolesQuery.isLoading}
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                {rolesData.map(({ id, value }) => (
                  <MenuItem
                    key={id}
                    value={id}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={56}
              />
            )}
            {formik.touched.role && formik.errors.role && (
              <DialogContentText color="error">{formik.errors.role}</DialogContentText>
            )}
          </FormControl>
          <DialogActions sx={{ mt: 2 }}>
            <Button
              onClick={handleClose}
              color="primary"
            >
              Cancelar
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={mutation.isPending}
            >
              Enviar convite
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
