import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IApprovalFlowStage } from "src/services/thumbz-base-api";

interface ApprovalFlowUpsertFormProps {
  open: boolean;
  stage: Partial<IApprovalFlowStage>;
  onClose: () => void;
  onSave: (stage: Partial<IApprovalFlowStage>) => void;
}

const validationSchema = Yup.object({
  afs_name: Yup.string().required("Nome da etapa é obrigatório"),
  afs_description: Yup.string().optional().nullable(),
  afs_order: Yup.number().required("Ordem é obrigatória").min(1, "Ordem deve ser maior que 0"),
});

export const ApprovalFlowUpsertForm: React.FC<ApprovalFlowUpsertFormProps> = ({
  open,
  stage,
  onClose,
  onSave,
}) => {
  const initialvalues = useMemo(
    () => ({
      ...stage,
      afs_order: stage.afs_order ?? 999,
    }),
    [stage],
  );

  const formik = useFormik({
    initialValues: initialvalues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(values);
    },
  });

  useEffect(() => {
    // Only update form values if they are different from the current stage values
    // This check prevents unnecessary updates and avoids the infinite loop
    if (JSON.stringify(formik.values) !== JSON.stringify(initialvalues)) {
      formik.setValues(initialvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]); // Only re-run this effect if `stage` changes

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{stage.afs_id ? "Editar Etapa" : "Nova Etapa"}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome da Etapa"
            type="text"
            fullWidth
            {...formik.getFieldProps("afs_name")}
            error={formik.touched.afs_name && Boolean(formik.errors.afs_name)}
            helperText={formik.touched.afs_name && formik.errors.afs_name}
          />
          <TextField
            margin="dense"
            label="Descrição"
            type="text"
            rows={4}
            fullWidth
            {...formik.getFieldProps("afs_description")}
            error={formik.touched.afs_description && Boolean(formik.errors.afs_description)}
            helperText={formik.touched.afs_description && formik.errors.afs_description}
          />
          <TextField
            margin="dense"
            label="Ordem"
            type="number"
            fullWidth
            style={{ display: "none" }}
            {...formik.getFieldProps("afs_order")}
            error={formik.touched.afs_order && Boolean(formik.errors.afs_order)}
            helperText={formik.touched.afs_order && formik.errors.afs_order}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
