import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import { InfoCircle } from '@untitled-ui/icons-react';

interface IStatusTitle {
  title: string;
  hint?: string;
}

const DEFAULT_ICON_SIZE = 12;

export const StatusTitle: React.FC<IStatusTitle> = ({ title, hint }) => {
  const { palette } = useTheme();
  return (
    <Stack
      direction={'row'}
      alignContent={'center'}
      alignItems={'center'}
    >
      <Typography
        variant="caption"
        fontWeight={'bold'}
        color={'text.secondary'}
        pr={0.25}
      >
        {title}
      </Typography>

      {hint && (
        <Tooltip title={hint}>
          <Box>
            <InfoCircle
              style={{
                color: palette.text.secondary,
              }}
              width={DEFAULT_ICON_SIZE}
              height={DEFAULT_ICON_SIZE}
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
