import { Typography, Breadcrumbs, IconButton, Grid } from "@mui/material";
import { Stack, SxProps } from "@mui/system";
import Link from "@mui/material/Link";
import React from "react";
import { RouterLink } from "src/components/router-link";
import { useRouter } from "src/hooks/use-router";
import { ArrowBack } from "@mui/icons-material";
import { BreadcrumbsSeparator } from "../breadcrumbs-separator";
import { SeoProps, Seo } from "../seo";

export interface IPageContainerSideProps {
  children: React.ReactNode;
  seo?: SeoProps;
  title?: string;
  subtitle?: string | React.ReactNode;
  breadcrumbs?: { href: string; label: string }[];
  sx?: SxProps;
  leftNode?: React.ReactNode;
}

const DEFAULT_SX: SxProps = {
  flexGrow: 1,
  px: 4,
  pt: 2,
  pb: 8,
};

export const PageContainerSide: React.FC<IPageContainerSideProps> = ({
  breadcrumbs,
  children,
  seo,
  subtitle,
  title,
  sx = DEFAULT_SX,
  leftNode,
}) => {
  const router = useRouter();

  const handleBack = () => {
    if (breadcrumbs && breadcrumbs.length > 1) {
      router.history.back();
    }
  };

  return (
    <>
      <Seo title={title} {...seo} />
      <Grid container sx={sx} spacing={4}>
        <Grid item xs={12} md={3}>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {breadcrumbs && breadcrumbs.length > 1 && (
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              )}
              {title && <Typography variant="h5">{title}</Typography>}
            </Stack>
            {subtitle && typeof subtitle === "string" ? (
              <Typography variant="subtitle2" color="textSecondary">
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumbs separator={<BreadcrumbsSeparator />}>
                {(breadcrumbs ?? []).map((breadcrumb, index) => {
                  const isLast =
                    index === (breadcrumbs && breadcrumbs.length ? breadcrumbs.length : 0) - 1;
                  return isLast ? (
                    <Typography key={index} color="text.secondary" variant="subtitle2">
                      {breadcrumb.label}
                    </Typography>
                  ) : (
                    <Link
                      key={index}
                      color="text.primary"
                      component={RouterLink}
                      href={breadcrumb.href}
                      variant="subtitle2"
                    >
                      {breadcrumb.label}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            )}
            {leftNode && (
              <Stack alignItems="center" direction="row" spacing={3}>
                {leftNode}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
