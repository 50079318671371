import { createClient as supabaseCreateClient, SupabaseClient } from "@supabase/supabase-js";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Armazena a instância única do SupabaseClient
let supabase: SupabaseClient | null = null;

export function createClient(): SupabaseClient {
  if (!supabase) {
    supabase = supabaseCreateClient(supabaseUrl, supabaseAnonKey);
  }
  return supabase;
}
