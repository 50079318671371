import { createFileRoute } from "@tanstack/react-router";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import { Seo } from "src/components/seo";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "src/app/hooks";
import { forgotPassword } from "src/features/auth/authSlice";
import { se } from "date-fns/locale";

export const Route = createFileRoute("/auth/recuperar-senha")({
  component: () => <Page />,
});

interface Values {
  email: string;
  submit: null;
}

const initialValues: Values = {
  email: "",
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string().email("Deve ser um email válido").max(255).required("Email é obrigatório"),
});

const Page = () => {
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await dispatch(forgotPassword({ email: values.email }));

        toast.success(
          "Um email foi enviado para o seu endereço de email. Lembre-se de verificar sua pasta de spam.",
        );

        router.navigate({
          from: "/auth/recuperar-senha",
          to: "/auth/login",
          search: {
            username: values.email,
          },
        });
      } catch (err) {
        console.error(err);

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: (err as Error).message });
        helpers.setSubmitting(false);
      }
    },
  });

  usePageView();

  return (
    <>
      <Seo title="Esqueceu a senha" />
      <div>
        <Card elevation={16}>
          <CardHeader sx={{ pb: 0 }} title="Esqueceu a senha" />
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Endereço de Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit as string}
                </FormHelperText>
              )}
              <LoadingButton
                loading={formik.isSubmitting}
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Enviar link de redefinição
              </LoadingButton>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Page;
