import { useEffect, useRef } from "react";
import {
  MenuButtonOrderedList,
  RichTextEditor as MuiRichTextEditor,
  type RichTextEditorRef,
} from "mui-tiptap";
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuButtonBulletedList,
} from "mui-tiptap";
import CharacterCount from "@tiptap/extension-character-count";
import { LinearProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

interface RichTextEditorProps {
  value: string | undefined;
  onChange: (content: string) => void;
}

const LIMIT = 2000;

export function RichTextEditor({ value, onChange }: RichTextEditorProps) {
  const rteRef = useRef<RichTextEditorRef>(null);
  const count = rteRef.current?.editor?.storage.characterCount.characters();
  const percentage = Math.round((100 / LIMIT) * count);
  const color = count >= LIMIT ? "error" : "textSecondary";

  useEffect(() => {
    if (!rteRef.current) return;

    const { editor } = rteRef.current;
    if (!editor) return;

    editor.on("update", ({ editor }) => {
      onChange(editor.getHTML());
    });

    return () => {
      editor.off("update");
    };
  }, [onChange]);

  return (
    <div>
      <MuiRichTextEditor
        ref={rteRef}
        extensions={[
          StarterKit,
          CharacterCount.configure({
            limit: LIMIT,
          }),
        ]}
        content={value}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            color={count >= LIMIT ? "error" : "primary"}
            value={percentage}
          />
        </Box>
        <Box sx={{ minWidth: 100 }}>
          <Typography variant="caption" color={count && count >= LIMIT ? "error" : "textSecondary"}>
            {count} / {LIMIT}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
