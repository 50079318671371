import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { FC, ReactNode } from "react";
import { AuthGuard } from "src/guards/auth-guard";
import { Box, Container } from "@mui/system";
import { TopNav } from "src/layouts/dashboard/marketing/top-nav";

export const Route = createFileRoute("/convite")({
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
  beforeLoad: async ({ context, location, ...others }) => {
    const {
      data: { session },
    } = await context.auth.getSession();

    if (!session) {
      throw redirect({
        to: "/auth/login",
        search: { returnTo: location.href },
      });
    } else {
      const urlParams = new URLSearchParams(location.search);
      const returnTo = urlParams.get("returnTo");

      if (returnTo) {
        throw redirect({
          to: returnTo,
        });
      }
    }
  },
});

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <AuthGuard>
      <Box mb={"100px"}>
        <TopNav />
      </Box>
      <Container>{children}</Container>
    </AuthGuard>
  );
};
