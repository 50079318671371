import type { ReactNode } from "react";
import { useMemo } from "react";
import SvgIcon from "@mui/material/SvgIcon";

import { Users01 } from "@untitled-ui/icons-react";
import { useWorkspaces } from "../../hooks/use-workspaces";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { RenderAvatar } from "../../components/avatar/RenderAvatar";
import {
  Add,
  BusinessOutlined,
  CreditCard,
  Dashboard,
  ViewKanbanOutlined,
} from "@mui/icons-material";
import { RouterPaths } from "src/main";
import { paths } from "src/paths";
import { Link } from "@tanstack/react-router";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: RouterPaths;
  title: string;
  tab?: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
  action?: ReactNode;
  id: "workspaces" | "organization" | "start";
}

export const useSections = () => {
  const { data } = useWorkspaces();

  const workspacesList: {
    title: string;
    path: RouterPaths;
    params?: Record<string, string>;
    icon: JSX.Element;
  }[] = useMemo(
    () =>
      data?.map(({ wrk_name, wrk_photo, wrk_updated_at, wrk_id }) => ({
        title: wrk_name,
        path: "/workspaces/$wrk_id",
        params: { wrk_id },
        icon: (
          <RenderAvatar
            src={wrk_photo?.ast_url ?? undefined}
            sx={{ width: 24, height: 24 }}
            name={wrk_name}
            alt={wrk_name}
            updated_at={wrk_updated_at}
          />
        ),
      })) || [],
    [data],
  );

  return useMemo(() => {
    const arr: Section[] = [
      {
        subheader: "Início",
        id: "start",
        items: [
          {
            title: "Visão Geral",
            icon: (
              <SvgIcon fontSize="small">
                <ViewKanbanOutlined />
              </SvgIcon>
            ),
            path: "/",
          },
          {
            title: "Dashboard",
            icon: (
              <SvgIcon fontSize="small">
                <Dashboard />
              </SvgIcon>
            ),
            path: "/dashboard",
          },
          // {
          //   title: 'Lista',
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   ),
          //   path: '#',
          // },
        ],
      },
      {
        subheader: "Organização",
        id: "organization",
        items: [
          {
            title: "Detalhes",
            path: "/organizacao/detalhes",
            icon: (
              <SvgIcon fontSize="small">
                <BusinessOutlined />
              </SvgIcon>
            ),
          },
          {
            title: "Colaboradores",
            path: "/organizacao/colaboradores",
            icon: (
              <SvgIcon fontSize="small">
                <Users01 />
              </SvgIcon>
            ),
          },
          {
            title: "Faturamento",
            path: "/organizacao/faturamento",
            icon: (
              <SvgIcon fontSize="small">
                <CreditCard />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: "Workspaces",
        id: "workspaces",
        action: (
          <Box mt={-0.5}>
            <Link to={"/workspaces/criar"}>
              <IconButton size="small" color="primary">
                <SvgIcon fontSize={"small"}>
                  <Add />
                </SvgIcon>
              </IconButton>
            </Link>
          </Box>
        ),
        items: [...workspacesList],
      },
    ];

    return arr;
  }, [workspacesList]);
};
