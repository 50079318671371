import { Toaster } from "react-hot-toast";
import { ConfirmProvider } from "material-ui-confirm";
import { SettingsDrawer } from "../components/settings/settings-drawer";
import { SettingsContextType } from "./settings";

import "simplebar-react/dist/simplebar.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-device-frameset/styles/marvel-devices.min.css";

export function ComponentWrapper({
  settings,
  children,
}: {
  settings: SettingsContextType;
  children: React.ReactNode;
}) {
  return (
    <>
      <ConfirmProvider
        defaultOptions={{
          title: "Remover",
          description: "Deseja realmente remover?",
          confirmationText: "Sim",
          cancellationText: "Cancelar",
          confirmationButtonProps: {
            variant: "contained",
            color: "primary",
          },
          cancellationButtonProps: {
            variant: "text",
          },
        }}
      >
        {children}
      </ConfirmProvider>
      <SettingsDrawer
        canReset={settings.isCustom}
        onClose={settings.handleDrawerClose}
        onReset={settings.handleReset}
        onUpdate={settings.handleUpdate}
        open={settings.openDrawer}
        values={{
          colorPreset: settings.colorPreset,
          contrast: settings.contrast,
          direction: settings.direction,
          paletteMode: settings.paletteMode,
          responsiveFontSizes: settings.responsiveFontSizes,
          stretch: settings.stretch,
          layout: settings.layout,
          navColor: settings.navColor,
        }}
      />
      <Toaster />
    </>
  );
}
