import { useTheme } from "@mui/material";
import React from "react";
import { OrganizationGuard, useOrganization } from "src/contexts/organization/OrganizationContext";

type StripePricingTableProps = object;

export const StripePricingTable: React.FC<StripePricingTableProps> = () => {
  const { palette } = useTheme();
  const { selectedOrganization } = useOrganization();

  const pricingTableId =
    palette.mode === "dark"
      ? import.meta.env.VITE_PRICING_TABLE_DARK_ID
      : import.meta.env.VITE_PRICING_TABLE_ID;

  return (
    <OrganizationGuard>
      {/* @ts-expect-error: Unreachable code error */}
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={import.meta.env.VITE_PUBLISHABLE_KEY}
        client-reference-id={selectedOrganization?.org_id}
      />
    </OrganizationGuard>
  );
};
