import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { Avatar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { paths } from "src/paths";

interface EmptyPlaceholderProps {
  overlineText?: string;
  singular?: string;
  plural?: string;
  customSubtitle?: string;
  showElevation?: boolean;
  showButton?: boolean;
  buttonLink?: string;
  children?: React.ReactNode;
  showImg?: boolean;
}

export const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({
  overlineText,
  singular,
  customSubtitle,
  showElevation = true,
  showButton = true,
  buttonLink = paths.dashboard.workspaces.create,
  showImg = false,
  children,
}) => {
  const uppercaseSingle = (singular || "Item")?.charAt(0).toUpperCase() + singular?.slice(1);
  const defaultSubtitle = `Você ainda não adicionou nenhum ${singular || "item"}. Quer começar?`;
  const subtitleText = customSubtitle || defaultSubtitle;
  const buttonText = `Criar novo ${uppercaseSingle}`;

  return (
    <Card elevation={showElevation ? undefined : 0}>
      <CardContent sx={{ textAlign: "center" }}>
        <Stack spacing={3}>
          <Stack
            flex={1}
            flexGrow={1}
            spacing={1}
            alignContent={"center"}
            justifyContent={"center"}
          >
            {/* make avatar align on center */}
            {showImg && (
              <Stack spacing={1} alignItems="center">
                <Avatar
                  variant="square"
                  src="/assets/errors/error-500.png"
                  sx={{
                    mx: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 200,
                    height: 200,
                  }}
                ></Avatar>
              </Stack>
            )}
            <Typography color="text.secondary" variant="overline">
              {overlineText}
            </Typography>
            <Typography variant="subtitle1">{subtitleText}</Typography>
          </Stack>
          {showButton && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={3}
              sx={{ mx: "auto", mt: 3 }}
            >
              <Button
                // TODO:
                // component={RouterLink}
                href={buttonLink}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {buttonText}
              </Button>
            </Stack>
          )}

          {children && <Box mt={2}>{children}</Box>}
        </Stack>
      </CardContent>
    </Card>
  );
};
