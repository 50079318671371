import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Typography, Grid, TextField, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { useFormik } from "formik";
import { FC } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import PhotoForm from "src/components/upload/photo-form";
import { authSlice } from "src/features/auth/authSlice";
import { useThumbzApi } from "src/services/thumbz-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import * as Yup from "yup";

interface AccountGeneralSettingsProps {
  email: string | null;
  name: string | null;
}

export const AccountGeneralSettings: FC<AccountGeneralSettingsProps> = (props) => {
  const { name, email } = props;
  const router = useRouter();
  const selectedUser = useSelector(authSlice.selectors.selectUser);
  const selectUserPhoto = useSelector(authSlice.selectors.selectUserPhoto);
  const dispatch = useAppDispatch();
  const { api } = useThumbzApi();

  const formik = useFormik({
    initialValues: {
      id: selectedUser?.usu_id,
      name: name || undefined,
      email: email || undefined,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required("Nome é obrigatório"),
      email: Yup.string().email().required("Email é obrigatório"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const res = await api.users.userControllerUpdateUser({
          usu_name: values.name,
        });
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success("Perfil atualizado");
        dispatch(authSlice.actions.updateSelectedUser(res));
      } catch (err) {
        console.error(err);
        toast.error("Algo deu errado!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: (err as Error).message });
        helpers.setSubmitting(false);
      }
    },
  });

  const changeUserMutationPhoto = useMutation({
    mutationFn: api.users.userControllerUpdatePhoto,
    onSuccess(data, variables, context) {
      dispatch(authSlice.actions.updateSelectedUser(data));
      toast.success("Foto atualizada");
    },
    onError(error, variables, context) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  useQuery({
    queryKey: ["user", props.email],
    queryFn: async () => {
      const response = await api.users.userControllerGetUserById();
      formik.setFieldValue("name", response.usu_name);
    },
  });

  return (
    <Stack spacing={4} {...props}>
      {selectedUser?.usu_id && (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Foto</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  <PhotoForm
                    key={`${selectUserPhoto}`}
                    default_photo_url={selectUserPhoto}
                    loading={changeUserMutationPhoto.isPending}
                    onFormSuccessSubmit={function ({ base64 }): void {
                      changeUserMutationPhoto.mutate({
                        base64: base64 || null,
                        usu_id: selectedUser.usu_id,
                      });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Detalhes básicos</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <TextField
                      error={!!(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      label="Nome completo"
                      name="name"
                      disabled={formik.isSubmitting}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      sx={{ flexGrow: 1 }}
                    />
                    <LoadingButton
                      loading={formik.isSubmitting}
                      type="submit"
                      color="inherit"
                      size="small"
                    >
                      Salvar
                    </LoadingButton>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2}>
                    <TextField
                      defaultValue={email}
                      value={formik.values.email}
                      label="Endereço de email"
                      required
                      disabled
                      sx={{
                        flexGrow: 1,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderStyle: "dashed",
                        },
                      }}
                    />
                    <Button color="inherit" size="small" disabled>
                      Editar
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Trocar senha</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack alignItems="flex-start" spacing={3}>
                <Typography variant="subtitle1">
                  Atualize sua senha regularmente para manter sua conta segura.
                </Typography>
                <Button
                  onClick={() =>
                    router.navigate({
                      from: "/conta/geral",
                      to: "/conta/geral/trocar-senha",
                    })
                  }
                  variant="outlined"
                >
                  Trocar senha
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Excluir conta</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack alignItems="flex-start" spacing={3}>
                <Typography variant="subtitle1">
                  Exclua sua conta e todos os seus dados de origem. Isso é irreversível.
                </Typography>
                {email}
                <Button color="error" disabled variant="outlined">
                  Excluir conta
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  );
};
