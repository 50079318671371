import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import { IOrganizationUsageResponse } from "src/services/thumbz-base-api";
import { useAppSelector } from "src/app/hooks";
import { authSlice } from "src/features/auth/authSlice";

type IWorkspaceData = IOrganizationUsageResponse;

interface UseAccountUsageHook {
  data: IWorkspaceData | undefined;
  query: UseQueryResult<IOrganizationUsageResponse, Error>;
}

export const useAccountUsage = (): UseAccountUsageHook => {
  const { selectedOrganization } = useOrganization();
  const forceupdateApi = useThumbzApi();
  const user = useAppSelector(authSlice.selectors.selectUser);
  const queryKey = queryKeys.users.userControllerGetMyAccountUsage(String(user?.usu_id)).queryKey;

  const workspaceUsageQuery = useQuery({
    queryFn: () =>
      forceupdateApi.api.organization.organizationControllerGetOrganizationUsage(
        String(selectedOrganization?.org_id),
      ),
    queryKey,
    enabled: !!selectedOrganization?.org_id,
    refetchInterval: 60 * 1000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
  });

  return {
    data: workspaceUsageQuery.data,
    query: workspaceUsageQuery,
  };
};
