import type { PaletteColor } from '@mui/material/styles/createPalette';

import type { ColorPreset } from '.';
import { americanYellow, gunmetal, halloweenOrange, rustyRed, ThemeColor } from './colors';

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case ThemeColor.AmericanYellow:
      return americanYellow;
    case ThemeColor.Gunmetal:
      return gunmetal;
    case ThemeColor.HalloweenOrange:
      return halloweenOrange;
    case ThemeColor.RustyRed:
      return rustyRed;
    default:
      console.error(
        'Invalid color preset, accepted values: ThemeColor.AmericanYellow, ThemeColor.Gunmetal, ThemeColor.HalloweenOrange, ThemeColor.RustyRed'
      );
      return americanYellow;
  }
};
