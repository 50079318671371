import { useContext } from "react";
import { WorkspaceFormContext } from "src/contexts/workspace/workspace-form";

export const useWorkspaceForm = () => {
  const context = useContext(WorkspaceFormContext);
  if (!context) {
    throw new Error("useWorkspaceForm must be used within a WorkspaceFormProvider");
  }
  return context;
};
