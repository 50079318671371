import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  approvalEditSlice,
  approvalEditThunks,
} from "src/features/approval-edit/approvalEditSlice";
import { useStatus } from "src/hooks/use-status";

interface IApprovalEditChangeStatusProps {
  wrk_id: string;
}

export const ApprovalEditChangeStatus: React.FC<IApprovalEditChangeStatusProps> = ({ wrk_id }) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const status = useAppSelector(approvalEditSlice.selectors.status);
  const count = useAppSelector(approvalEditSlice.selectors.countStageStatus);
  const { IN_REVIEW, DONE, REQUEST_CHANGED } = useStatus({ wrk_id });
  const actualStatus = approval?.apv_status?.statusType.stp_type;

  const nextStatusData = {
    title: "Deseja reenviar para revisão?",
    description:
      "Após confirmar, os aprovadores que rejeitaram os itens serão notificados para revisar novamente.",
    disabled: false,
    buttonLabel: "Reenviar para revisão",
  };

  function handleChangeStatus() {
    if (!approval?.apv_id) {
      toast.error("Aprovação não encontrada");
      return;
    }

    if (!IN_REVIEW?.sts_id) {
      toast.error("Status de revisão não encontrado");
      return;
    }

    let { title, description } = nextStatusData;

    if ((count?.NOT_STARTED ?? 0) >= 1) {
      title = "Ainda existem itens não avaliados";
      description = `Ainda existem itens que não foram avaliados. Deseja continuar mesmo assim?`;
    }

    confirm({
      title,
      description,
    }).then(() => {
      dispatch(
        approvalEditThunks.changeStatus({
          apv_id: approval.apv_id,
          sts_id: IN_REVIEW?.sts_id,
        }),
      );
    });
  }

  if (actualStatus === DONE?.statusType.stp_type) {
    return null;
  }

  return actualStatus === REQUEST_CHANGED?.statusType.stp_type ? (
    <LoadingButton
      variant="contained"
      color="primary"
      loading={status === "loading"}
      onClick={handleChangeStatus}
      startIcon={<Save sx={{ mr: 1 }} />}
    >
      {nextStatusData.buttonLabel}
    </LoadingButton>
  ) : null;
};
