import type { FC, ReactNode } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useQuery } from "@tanstack/react-query";
import { thumbzApi } from "src/services/thumbz-api";
import { Avatar } from "@mui/material";
import { IAvailablePlansDto } from "src/services/thumbz-base-api";
import { useOrganization } from "src/contexts/organization/OrganizationContext";

interface Plan {
  id: string;
  icon: ReactNode;
  name: string;
  price: number;
  interval: string; // 'month' or 'year'
}

interface Invoice {
  id: string;
  amount: number;
  createdAt: number;
}

interface AccountBillingSettingsProps {
  plan?: string;
  invoices?: Invoice[];
}

export const AccountBillingSettings: FC<AccountBillingSettingsProps> = (props) => {
  const { selectedOrganization } = useOrganization();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const availablePlansQuery = useQuery({
    queryKey: ["billingControllerGetBillingPlans"],
    queryFn: () =>
      thumbzApi.billing.billingControllerGetAvailablePlans({
        org_id: String(selectedOrganization?.org_id),
      }),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    enabled: !!selectedOrganization?.org_id,
  });

  const portalLink = useQuery({
    queryKey: ["billingControllerGetCustomerPortal"],
    queryFn: () =>
      thumbzApi.billing.billingControllerGetCustomerPortal({
        org_id: String(selectedOrganization?.org_id),
      }),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    enabled: !!selectedOrganization?.org_id,
  });

  const monthlyPlans = (availablePlansQuery?.data || []).filter(
    ({ product }) => product.stripe_metadata.stripe_price_metadata.recurring.interval === "month",
  );
  const yearlyPlans = (availablePlansQuery?.data || []).filter(
    ({ product }) => product.stripe_metadata.stripe_price_metadata.recurring.interval === "year",
  );

  function renderPlanCard(plan: IAvailablePlansDto, availablePlansQuery: any, portalLink: any) {
    const isCurrent = plan.is_current_plan;
    const { currency, unit_amount } = plan.product.stripe_metadata.stripe_price_metadata;
    const price = formatPrice(unit_amount, currency, "en-US"); // Modify as needed based on your data

    return (
      <Grid item key={plan.product.stp_id} xs={12} sm={4}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            ...(isCurrent && {
              borderColor: "primary.main",
              borderWidth: 2,
              m: "-1px",
            }),
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Stack spacing={4} direction="row">
              <Stack flexGrow={1}>
                {plan.product.stripe_metadata.stripe_product_metadata.images.map((image) => (
                  <Avatar
                    variant="rounded"
                    sx={{
                      height: 64,
                      width: 64,
                      mb: 1,
                    }}
                    key={image}
                    src={image}
                    alt={plan.product.stripe_metadata.stripe_product_metadata.name}
                  />
                ))}
              </Stack>
              <Stack
                direction="column"
                alignItems="flex-end"
                justifyContent="start"
                spacing={1}
                flexGrow={1}
              >
                <Typography variant="h6">
                  {plan.product.stripe_metadata.stripe_product_metadata.name}
                </Typography>
                {isCurrent && (
                  <Typography color="primary.main" variant="caption">
                    Plano atual
                  </Typography>
                )}
              </Stack>
              {/* <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                  mt: 1,
                }}
              >
                <Typography variant="h5">{price}</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 'auto', ml: '4px' }}
                  variant="body2"
                >
                  /{plan.product.stripe_metadata.stripe_price_metadata.recurring.interval}
                </Typography>
              </Box> */}
            </Stack>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              my={2}
              justifyContent="flex-start"
              alignContent={"flex-start"}
              alignItems={"flex-start"}
            >
              {plan.product.stripe_metadata.stripe_product_metadata.features.map(({ name }) => (
                <Typography key={name} color="text.secondary" variant="body2">
                  - {name}
                </Typography>
              ))}
            </Box>
            <Box>
              <Button
                style={{
                  marginTop: 6,
                }}
                color="primary"
                variant={isCurrent ? "contained" : "outlined"}
                disabled={portalLink.isLoading}
                size="small"
                fullWidth
                href={portalLink.data}
              >
                {isCurrent ? "Ver Plano" : "Mudar Plano"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Stack spacing={0}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mb: 2 }}
      >
        <Tab label="Planos Mensais" />
        <Tab label="Planos Anuais" />
      </Tabs>
      <Grid container spacing={2}>
        {value === 0
          ? monthlyPlans.map((plan) => renderPlanCard(plan, availablePlansQuery, portalLink))
          : yearlyPlans.map((plan) => renderPlanCard(plan, availablePlansQuery, portalLink))}
      </Grid>
    </Stack>
  );
};

AccountBillingSettings.propTypes = {
  plan: PropTypes.string,
  invoices: PropTypes.array,
};

export function formatPrice(amountInCents: number, currency: string, locale: string) {
  const amount = amountInCents / 100;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
}
