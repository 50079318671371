import type { FC } from "react";
import { useMemo } from "react";

import { Alert, AlertColor, AlertTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useBillingStatus } from "src/hooks/use-billing";

interface AccountBillingStatusProps {
  force_show?: boolean;
  force_hide?: boolean;
  showRefresh?: boolean;
}

export const AccountBillingStatus: FC<AccountBillingStatusProps> = ({
  force_show = false,
  force_hide = false,
  showRefresh = false,
}) => {
  const { data, query } = useBillingStatus();

  const severity: AlertColor = useMemo(() => {
    switch (data?.color) {
      case "success":
        return "success";
      case "warning":
        return "warning";
      case "error":
        return "error";
      default:
        return "info";
    }
  }, [data?.color]);

  if (force_hide) {
    return null;
  }

  if (query.isLoading) {
    return null;
  }

  if (query.isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {query.error?.message}
      </Alert>
    );
  }

  if (!data) {
    return null;
  }

  if (data?.should_show_warning === false && !force_show) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      action={
        showRefresh ? (
          <LoadingButton
            loading={query.isFetching}
            loadingPosition="center"
            onClick={() => {
              query.refetch();
            }}
            color="inherit"
            size="small"
          >
            <RefreshIcon />
          </LoadingButton>
        ) : null
      }
    >
      <AlertTitle>{data?.title}</AlertTitle>
      {data?.message}
    </Alert>
  );
};
