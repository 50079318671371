import { subDays, subHours } from 'date-fns';

export interface Notification {
  id: string;
  author?: string;
  avatar?: string;
  company?: string;
  createdAt: number;
  description?: string;
  job?: string;
  title?: string;
  read?: boolean;
  type: string;
}

const now = new Date();

export const notifications: Notification[] = [];
