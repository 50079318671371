import React, { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { IApprovalListResponseDto } from "src/services/thumbz-base-api";
import { Delete, MoreVertOutlined } from "@mui/icons-material";
import { AgGridReactDefault } from "src/components/table/custom-table";
import { ApprovalViewProps } from "./approval-view-selector";
import { Eye } from "@untitled-ui/icons-react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { useDialog } from "src/hooks/use-dialog";
import { ApprovalModal } from "./approval-modal";

export const ApprovalTable: React.FC<ApprovalViewProps> = ({
  onDelete,
  approvals,
  onOpen,
  config,
}) => {
  const dialog = useDialog<{ apv_id: string }>();
  const { show_apv_created_at, show_apv_name, show_stages, show_workspace } = config;
  const [rowData, setRowData] = useState<IApprovalListResponseDto[]>([]);

  // Função para abrir o modal com o ID da aprovação selecionada
  const openDialog = ({ apv_id, wrk_id }: { apv_id: string; wrk_id: string }) => {
    onOpen({ apv_id, wrk_id });
  };

  const [colDefs] = useState<ColDef<IApprovalListResponseDto>[]>([
    { field: "apv_id", headerName: "ID", hide: true },
    {
      field: "apv_name",
      headerName: "Nome",
      pinned: "left",
      flex: 1,
      hide: !show_apv_name,
      cellStyle: { cursor: "pointer" },
      onCellClicked: ({ data }) => data && openDialog(data),
    },
    {
      field: "apv_status",
      headerName: "Status",
      cellStyle: { cursor: "pointer" },
      onCellClicked: ({ data }) => data && openDialog(data),
    },
    {
      field: "wrk_name",
      headerName: "Workspace",
      cellStyle: { cursor: "pointer" },
      hide: !show_workspace,
      onCellClicked: ({ data }) => data && openDialog(data),
      cellRenderer: (params: { data: IApprovalListResponseDto }) => (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="start"
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <RenderAvatar
            fallbackSize={25}
            sx={{ width: 25, height: 25 }}
            src={params.data.wrk_photo_url}
          />
          <Typography variant="body2">{params.data.wrk_name}</Typography>
        </Stack>
      ),
    },
    {
      field: "flow_name",
      flex: 1,
      headerName: "Fluxo padrão",
      cellStyle: { cursor: "pointer" },
      hide: !show_stages,
      onCellClicked: ({ data }) => data && openDialog(data),
    },
    {
      field: "apv_created_at",
      headerName: "Criado em",
      hide: !show_apv_created_at,
      valueFormatter: (params) => {
        return format(new Date(params.value), "P p", { locale: ptBR });
      },
      cellStyle: { cursor: "pointer" },
      onCellClicked: ({ data }) => data && openDialog(data),
    },
    // {
    //   field: "apv_created_at",
    //   headerName: "Atualizado em",
    //   valueFormatter: (params) => {
    //     return format(new Date(params.value), "P p", { locale: ptBR });
    //   },
    //   cellStyle: { cursor: "pointer" },
    //   onCellClicked: ({ data }) => data && openDialog(data),
    // },
    {
      headerName: "Ações",
      pinned: "right",
      width: 100,
      cellRenderer: (params: { data: IApprovalListResponseDto }) => (
        <PopupState variant="popover" popupId="approval-table-actions">
          {(popupState) => (
            <div>
              <IconButton
                {...bindTrigger(popupState)}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  bindTrigger(popupState).onClick(e);
                }}
              >
                <MoreVertOutlined />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Menu
                  anchorEl={popupState.anchorEl}
                  open={Boolean(popupState.anchorEl)}
                  onClose={() => popupState.close()}
                >
                  <MenuItem
                    onClick={() =>
                      openDialog({
                        apv_id: params.data.apv_id,
                        wrk_id: params.data.wrk_id,
                      })
                    }
                  >
                    <ListItemIcon>
                      <Eye fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Visualizar</ListItemText>
                  </MenuItem>

                  <MenuItem
                    color="error"
                    onClick={() =>
                      onDelete({
                        apv_id: params.data.apv_id,
                        wrk_id: params.data.wrk_id,
                      })
                    }
                  >
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Excluir</ListItemText>
                  </MenuItem>
                </Menu>
              </Popover>
            </div>
          )}
        </PopupState>
      ),
    },
  ]);

  useEffect(() => {
    if (approvals) {
      setRowData(approvals);
    }
  }, [approvals]);

  return (
    <>
      <AgGridReactDefault<IApprovalListResponseDto>
        rowData={rowData}
        columnDefs={colDefs}
        rowSelection="single"
      />

      {dialog.data?.apv_id && <ApprovalModal wrk_id={dialog.data.apv_id} dialog={dialog} />}
    </>
  );
};
