import React from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  approvalCreateSlice,
  approvalThunks,
  ISliceItemAssetUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { InstagramRenderImgPreview } from "./instagram-render-img-preview";
import { Mimetype } from "src/utils/get-mimetype";
import { useAppDispatch } from "src/app/hooks";
import { useConfirm } from "material-ui-confirm";
import {
  Delete,
  DeleteForever,
  DeleteOutline,
  DragIndicatorOutlined,
  MoreHoriz,
} from "@mui/icons-material";

interface IInstagramCarouselReorderProps {
  apv_id: string;
  assets: ISliceItemAssetUpsertDto[];
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
  onClose: () => void;
}

export const InstagramCarouselReorder: React.FC<IInstagramCarouselReorderProps> = ({
  apv_id,
  assets,
  onReorder,
  onClose,
}) => {
  const { palette } = useTheme();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    onReorder(source.index, destination.index);
  };

  const isAbleToDelete = assets.length > 1;

  function handleDelete(index: number, asset: ISliceItemAssetUpsertDto): void {
    if (!isAbleToDelete) return;
    dispatch(
      approvalThunks.removeAssetFromApprovalItem({
        apv_id,
        ast_index: index,
        isSynced: asset.already_uploaded_url ? true : false,
      }),
    );
  }

  return (
    <Box flex={1}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="assets" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {assets.map((asset, index) => {
                return (
                  <Draggable key={asset.ast_id} draggableId={asset.ast_id} index={index}>
                    {(provided, snapshot) => (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          opacity: snapshot.isDragging || snapshot.combineTargetFor ? 0.5 : 1,
                        }}
                        mb={1}
                      >
                        <Stack
                          borderRadius={1}
                          border={1}
                          style={{
                            borderColor: palette.divider,
                          }}
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                          flex={1}
                        >
                          <Box mr={2} />
                          <DragIndicatorOutlined
                            style={{
                              color: palette.neutral[300],
                            }}
                          />

                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                            flex={1}
                            flexGrow={1}
                            height={40}
                            width={40}
                          >
                            <Box height={40} width={40}>
                              <InstagramRenderImgPreview
                                file={asset.file}
                                mimetype={asset.props?.mimetype ?? Mimetype.Image}
                                already_uploaded_url={asset.already_uploaded_url}
                                size={40}
                              />
                            </Box>

                            <Typography variant="caption">
                              {asset.file?.name ||
                                asset.uploaded_asset?.ast_original_filename ||
                                "Sem nome"}
                            </Typography>
                          </Stack>

                          {isAbleToDelete && (
                            <IconButton onClick={() => handleDelete(index, asset)}>
                              <DeleteOutline
                                style={{
                                  color: palette.text.secondary,
                                }}
                              />
                            </IconButton>
                          )}
                        </Stack>
                      </Stack>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
