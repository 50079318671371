import type { FC } from "react";
import { Avatar, Box, Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { IDashboardResponseDto } from "src/services/thumbz-base-api";
import { UseQueryResult } from "@tanstack/react-query";

interface IProps {
  query: UseQueryResult<IDashboardResponseDto, Error>;
}

export const DashboardApprovalOverview: FC<IProps> = ({ query }) => {
  if (query.isLoading) {
    return (
      <Grid container spacing={4}>
        {[0, 1, 2, 3].map((index) => (
          <Grid item lg={3} md={6} xs={12} key={index}>
            <Card sx={{ p: 3, height: "100%" }}>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  mb: 1,
                  mt: 1,
                }}
              >
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" width={40} height={40} />
              </Stack>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 3, height: "100%" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1,
              mt: 1,
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
                color: "success.main",
                mr: 1,
              }}
              variant="rounded"
            >
              <CheckCircleIcon fontSize="small" />
            </Avatar>
            <Typography variant="h5">{query?.data?.approvals.approved.value}</Typography>
          </Box>
          <Typography color="caption" variant="body2">
            {query?.data?.approvals.approved.title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {query?.data?.approvals.approved.tooltip}
          </Typography>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 3, height: "100%" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1,
              mt: 1,
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
                color: "warning.main",
                mr: 1,
              }}
            >
              <HourglassEmptyIcon fontSize="small" />
            </Avatar>
            <Typography variant="h5">{query?.data?.revisions.total.value}</Typography>
          </Box>
          <Typography color="caption" variant="body2">
            {query?.data?.revisions.title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {query?.data?.revisions.description}
          </Typography>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 3, height: "100%" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1,
              mt: 1,
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1),
                color: "info.main",
                mr: 1,
              }}
            >
              <GroupIcon fontSize="small" />
            </Avatar>
            <Typography variant="h5">{query?.data?.customers.total.value}</Typography>
          </Box>
          <Typography color="caption" variant="body2">
            {query?.data?.customers.title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {query?.data?.customers.description}
          </Typography>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 3, height: "100%" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1,
              mt: 1,
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
                color: "secondary.main",
                mr: 1,
              }}
            >
              <GroupIcon fontSize="small" />
            </Avatar>
            <Typography variant="h5">{query?.data?.users.total.value}</Typography>
          </Box>
          <Typography color="caption" variant="body2">
            {query?.data?.users.title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {query?.data?.users.description}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};
