import { DragIndicatorOutlined, Folder, MoreHoriz, Reorder } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { Drop, Pencil01 } from "@untitled-ui/icons-react";
import React from "react";
import {
  IApprovalStageCustomer,
  ICustomer,
  IStatusTypeStpTypeEnum,
} from "src/services/thumbz-base-api";
import { StatusIcon } from "../status/StatusIcon";
import { RenderAvatar } from "../avatar/RenderAvatar";
import { CustomerPopupMenu } from "src/sections/approval-edit/customer-popup-menu";
import CustomerAvatarWithIcons from "../avatar/CustomerAvatarWithIcons";
import { useIcon } from "src/hooks/use-icon";
import { RenderStatusIcon } from "../avatar/StatusIcon";
import { useAppSelector } from "src/app/hooks";
import { approvalEditSelectors } from "src/features/approval-edit/approval-edit-selectors";
import { approvalEditSlice } from "src/features/approval-edit/approvalEditSlice";

interface CustomerRowProps {
  approvalStageCustomer: IApprovalStageCustomer;
  wrk_slug: string;
}

export const CustomerRow: React.FC<CustomerRowProps> = ({ approvalStageCustomer, wrk_slug }) => {
  const currentStage = useAppSelector(approvalEditSlice.selectors.currentStage);
  const { customer } = approvalStageCustomer;
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popver_id = open ? `status-row-popover-${customer.cust_id}` : undefined;

  const PopOverMenu = () => (
    <List dense>
      <ListItemButton>
        <ListItemIcon>
          <Pencil01 />
        </ListItemIcon>
        <ListItemText primary="Rename" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <Drop />
        </ListItemIcon>
        <ListItemText primary="Change Color" />
      </ListItemButton>
    </List>
  );

  const RenderRightElement = () => {
    if (approvalStageCustomer.asc_approved) return <RenderStatusIcon icon={"success"} size={45} />;

    if (currentStage?.stg_id !== approvalStageCustomer.approvalStage.stg_id) {
      return null;
    }

    return <CustomerPopupMenu approvalStageCustomer={approvalStageCustomer} wrk_slug={wrk_slug} />;
  };

  return (
    <Stack
      borderRadius={1}
      border={1}
      style={{
        borderColor: palette.divider,
      }}
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      p={1}
      flex={1}
      flexGrow={1}
    >
      <Box mr={2} />

      <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1}>
        <RenderAvatar
          src={customer.cust_photo?.ast_url}
          fallbackSize={13}
          sx={{
            width: 20,
            height: 20,
          }}
        />
        {/* <CustomerAvatarWithIcons
                customer={customer}
                showIcon
                icon={icon}
                key={`avatar-qty-${customer.cust_id}-${index}`}
                showLegend={false}
                size={MEDIA_DEFAULT_SIZE}
              /> */}
        <Typography color={palette.text.secondary}>{customer.cust_name}</Typography>
      </Stack>

      {/* <StatusIcon
        statusType={IStatusTypeStpTypeEnum.DONE}
        color={'#FF22AA'}
      /> */}
      <RenderRightElement />
    </Stack>
  );
};
