import { Typography, Button, Grid, FormLabel, Divider } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React from "react";
import Slider from "react-slick";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { InstagramRenderImgPreview } from "src/components/instagram-render-img-preview";
import { RichTextRenderer } from "src/components/richtext/RichTextRender";
import {
  approvalCreateSlice,
  ISliceItemUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { Mimetype } from "src/utils/get-mimetype";
import { RevisionGenericWrapper } from "./revision-generic-wrapper";
import { ApprovalTypeFields } from "src/components/approval/approval-type-fields";
import { IApprovalTypeFieldAtfContextEnum } from "src/services/thumbz-base-api";

interface IRevisonsItemsProps {
  approvalItems: ISliceItemUpsertDto[];
}

export const RevisonsItems: React.FC<IRevisonsItemsProps> = ({ approvalItems }) => {
  const approval = useAppSelector(approvalCreateSlice.selectors.approval);
  const dispatch = useAppDispatch();

  function _goToStep1() {
    dispatch(approvalCreateSlice.actions.changeStep(0));
  }

  return (
    <RevisionGenericWrapper
      title={"Itens para Aprovar"}
      description="Revise os itens adicionados"
      action={
        <Button variant="text" color="primary" onClick={_goToStep1}>
          Editar
        </Button>
      }
    >
      {approvalItems.length > 0 ? (
        <Grid container spacing={1}>
          {approvalItems?.map((item, index) => {
            const Description = () => (
              <Stack>
                <FormLabel component="legend">{"Legenda"}</FormLabel>
                <RichTextRenderer content={item.item_description || "Nenhuma legenda informada"} />
              </Stack>
            );

            const Responses = () => (
              <ApprovalTypeFields
                approvalTypeFields={approval?.approvalType?.approvalTypeFields || []}
                responses={approvalItems.find((i) => i.item_id === item.item_id)?.responses || []}
                onFieldChange={() => {}}
                readOnly
              />
            );

            const Wrapper = () => (
              <Stack spacing={2}>
                <Description />
                <Responses />
              </Stack>
            );

            const RenderNonSlider = () => (
              <Grid container spacing={2} mb={2}>
                <Grid item key={item.item_id} xs={4}>
                  <InstagramRenderImgPreview
                    file={item.assets[0].file}
                    mimetype={item.assets[0].props?.mimetype ?? Mimetype.Image}
                    already_uploaded_url={item.assets[0].already_uploaded_url}
                  />
                  <Stack>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      align="center"
                      sx={{ fontSize: "0.70rem" }}
                    >
                      Arquivo:{" "}
                      {item.assets[0].file?.name ??
                        item.assets[0].uploaded_asset?.ast_original_filename}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Wrapper />
                </Grid>
              </Grid>
            );

            const RenderSlider = () => (
              <Grid container spacing={2}>
                <Grid item key={item.item_id} xs={4} mb={4}>
                  <Slider
                    {...{
                      dots: true,
                      centerMode: false,
                      arrows: true,
                      infinite: false,
                      nextArrow: <Box sx={{ zIndex: 10, width: 40, height: 40, right: "-20px" }} />,
                      prevArrow: <Box sx={{ zIndex: 10, width: 40, height: 40, left: "0px" }} />,
                      dotsClass: "slick-dots slick-thumb",
                    }}
                  >
                    {item.assets.map((_item, _index) => (
                      <div key={`media-${_index}`}>
                        <InstagramRenderImgPreview
                          file={_item.file}
                          mimetype={_item.props?.mimetype ?? Mimetype.Image}
                          already_uploaded_url={_item.already_uploaded_url}
                          showThumbnail={true}
                        />
                        <Stack>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                            sx={{ fontSize: "0.70rem" }}
                          >
                            Arquivo {_index + 1}:{" "}
                            {_item.file?.name ?? _item.uploaded_asset?.ast_original_filename}
                          </Typography>
                        </Stack>
                      </div>
                    ))}
                  </Slider>
                </Grid>
                <Grid item xs={8}>
                  <Wrapper />
                </Grid>
              </Grid>
            );

            return (
              item.assets.length > 0 &&
              (item.assets.length > 1 ? <RenderSlider /> : <RenderNonSlider />)
            );
          })}
        </Grid>
      ) : (
        <Typography variant="body2">Nenhum item adicionado.</Typography>
      )}
    </RevisionGenericWrapper>
  );
};
