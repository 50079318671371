import { Send } from '@mui/icons-material';
import { Avatar, OutlinedInput, Tooltip, IconButton, Box } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { RenderAvatar } from 'src/components/avatar/RenderAvatar';
import {
  approvalEditSlice,
  approvalEditThunks,
} from 'src/features/approval-edit/approvalEditSlice';
import { authSlice } from 'src/features/auth/authSlice';
import { IApprovalItemCommentAicByEnum } from 'src/services/thumbz-base-api';

interface IChatMessageProps {}

export const ChatMessage: React.FC<IChatMessageProps> = () => {
  const selectedChatAirId = useAppSelector(approvalEditSlice.selectors.selectedChatAirId);
  const loggedUser = useAppSelector(authSlice.selectors.selectUser);
  const loggedUserPhoto = useAppSelector(authSlice.selectors.selectUserPhoto);
  const [localText, setlocalText] = useState<string>('');
  // const actualRevision = useAppSelector(
  //   approvalEditSlice.selectors.selectedRevision
  // );
  // const customer = useAppSelector(approvalEditSlice.selectors.selectCustomer);
  const dispatch = useAppDispatch();

  function handleOnChange(comment: string) {
    setlocalText(comment);
  }

  function submitComment() {
    if (!selectedChatAirId) {
      toast.error('Selecione um item para comentar.');
      return;
    }

    if (!localText || localText === '') {
      toast.error('Digite um comentário.');
      return;
    }

    if (!loggedUser) {
      toast.error('Usuário não encontrado.');
      return;
    }

    dispatch(
      approvalEditThunks.addComment({
        air_id: selectedChatAirId.air_id,
        comment: localText,
        loggedUser: loggedUser,
      })
    );
    setlocalText('');
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={'space-around'}
    >
      {/* <RenderAvatar
        name={loggedUser?.usu_name}
        src={loggedUserPhoto}
      /> */}
      <Stack flexGrow={1}>
        <OutlinedInput
          onChange={(e) => handleOnChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              submitComment();
            }
          }}
          size="small"
          value={localText}
          minRows={1}
          maxRows={10}
          multiline
          placeholder="Digite uma mensagem"
        />
      </Stack>
      <IconButton
        color="primary"
        disabled={!localText}
        onClick={submitComment}
      >
        <Send />
      </IconButton>
    </Stack>
  );
};
