import type { FC, ReactNode } from "react";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";

import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { useWindowScroll } from "src/hooks/use-window-scroll";
import { paths } from "src/paths";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { authSlice, signOut } from "src/features/auth/authSlice";
import { TopNavItem } from "./top-nav-item";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { SelectOrganizationModal } from "src/contexts/organization/OrganizationContext";
import { organizationSlice } from "src/features/organization/organizationSlice";

interface Item {
  disabled?: boolean;
  external?: boolean;
  popover?: ReactNode;
  path?: string;
  title: string;
}

const items: Item[] = [
  // {
  //   title: 'Pricing',
  //   path: paths.pricing,
  // },
  // {
  //   title: 'Docs',
  //   path: paths.docs,
  //   external: true,
  // },
  // {
  //   title: 'Contact Us',
  //   path: paths.contact,
  // },
];

const TOP_NAV_HEIGHT = 64;

interface TopNavProps {
  onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const canChangeOrganization = useAppSelector(organizationSlice.selectors.canChangeOrganization);
  const [openChangeOrg, setOpenChangeOrg] = useState<boolean>(false);
  const loggedUser = useAppSelector(authSlice.selectors.selectUser);
  const dispatch = useAppDispatch();
  const { onMobileNavOpen } = props;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [elevate, setElevate] = useState<boolean>(false);
  const offset = 64;
  const delay = 100;

  function _signOut() {
    dispatch(signOut());
  }

  const handleWindowScroll = useCallback((): void => {
    if (window.scrollY > offset) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  }, []);

  useWindowScroll({
    handler: handleWindowScroll,
    delay,
  });

  return (
    <Box
      component="header"
      sx={{
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        pt: 2,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "transparent",
          borderRadius: 2.5,
          boxShadow: "none",
          transition: (theme) =>
            theme.transitions.create("box-shadow, background-color", {
              easing: theme.transitions.easing.easeInOut,
              duration: 200,
            }),
          ...(elevate && {
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
            boxShadow: 8,
          }),
        }}
      >
        <Stack direction="row" spacing={2} sx={{ height: TOP_NAV_HEIGHT }}>
          <Stack alignItems="center" direction="row" spacing={1} sx={{ flexGrow: 1 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 48,
                  width: 48,
                }}
              >
                <Logo variant="name_and_icon" />
              </Box>
              {/* {mdUp && (
                <Box
                  sx={{
                    color: 'text.primary',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: '0.3px',
                    lineHeight: 2.5,
                    '& span': {
                      color: 'primary.main',
                    },
                  }}
                >
                  Thumbz <span> APP </span>
                </Box>
              )} */}
            </Stack>
            {/* <Chip
              label={`v${approval}`}
              size="small"
            /> */}
          </Stack>
          {mdUp && (
            <Stack alignItems="center" direction="row" spacing={2}>
              <Box component="nav" sx={{ height: "100%" }}>
                <Stack
                  component="ul"
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                  sx={{
                    height: "100%",
                    listStyle: "none",
                    m: 0,
                    p: 0,
                  }}
                >
                  <>
                    {items.map((item) => {
                      const active = false;

                      return (
                        <TopNavItem
                          active={active}
                          external={item.external}
                          key={item.title}
                          path={item.path}
                          popover={item.popover}
                          title={item.title}
                        />
                      );
                    })}
                  </>
                </Stack>
              </Box>
            </Stack>
          )}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ flexGrow: 1 }}
          >
            {canChangeOrganization && (
              <Button
                onClick={() => setOpenChangeOrg(true)}
                size={mdUp ? "medium" : "small"}
                variant="outlined"
              >
                Trocar Organização
              </Button>
            )}
            <Button onClick={_signOut} size={mdUp ? "medium" : "small"} variant="outlined">
              Sair
            </Button>
            <RenderAvatar
              src={loggedUser?.usu_photo.ast_url}
              name={loggedUser?.usu_name}
              variant="circular"
            />
            {!mdUp && (
              <IconButton onClick={onMobileNavOpen}>
                <SvgIcon fontSize="small">
                  <Menu01Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Container>
      <SelectOrganizationModal open={openChangeOrg} setOpen={setOpenChangeOrg} allowClose />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
