import { PictureAsPdf } from "@mui/icons-material";
import { CardMedia, Avatar, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import VideoThumbnail from "react-video-thumbnail";
import { ISliceItemAssetUpsertDto } from "src/features/approvalsCreate/approvalCreateSlice";
import { getMimetype, Mimetype } from "src/utils/get-mimetype";

interface IInstagramRenderImgPreviewProps {
  showThumbnail?: boolean;
  file: File | null;
  mimetype: Mimetype | undefined | string;
  already_uploaded_url: string | null;
  size?: number;
}

export const InstagramRenderImgPreview: React.FC<IInstagramRenderImgPreviewProps> = ({
  already_uploaded_url,
  file,
  mimetype,
  showThumbnail = true,
  size = 250,
}) => {
  const theme = useTheme();
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const _mimetype = getMimetype(mimetype);

  useEffect(() => {
    if (file && file instanceof Blob && _mimetype === Mimetype.Video) {
      const fileUrl = URL.createObjectURL(file);
      setThumbnail(fileUrl);
      return () => URL.revokeObjectURL(fileUrl); // Limpa a URL ao desmontar
    }
  }, [file, _mimetype]);

  const renderMedia = useCallback(() => {
    let imageUrl = already_uploaded_url || "";

    if (file instanceof Blob && file) {
      imageUrl = URL.createObjectURL(file);
    }

    const height = size;

    switch (_mimetype) {
      case Mimetype.Image:
        return (
          <CardMedia
            component="img"
            image={imageUrl}
            sx={{
              bgcolor: theme.palette.divider,
            }}
            style={{ height, objectFit: "contain", width: "100%" }}
          />
        );
      case Mimetype.Video:
        return (
          <div
            style={{
              width: "100%",
              height,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.divider,
            }}
          >
            {showThumbnail ? (
              <VideoThumbnail
                videoUrl={thumbnail || imageUrl}
                thumbnailHandler={(thumbnail) => setThumbnail(thumbnail)}
                snapshotAtTime={10}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <video
                controls
                src={imageUrl}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            )}
          </div>
        );
      case Mimetype.Pdf:
        return (
          <>
            {showThumbnail ? (
              <Avatar variant="square" style={{ width: "100%", height }}>
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                  <PictureAsPdf fontSize="large" color="primary" style={{ fontSize: 75 }} />
                </Stack>
              </Avatar>
            ) : (
              <iframe src={imageUrl} height={height} width="100%" />
            )}
          </>
        );
      default:
        return <p>Erro no upload</p>;
    }
  }, [
    already_uploaded_url,
    file,
    size,
    _mimetype,
    theme.palette.divider,
    showThumbnail,
    thumbnail,
  ]);

  return renderMedia();
};
